import { useEffect, useState } from 'react';
import { ReportCallback, onCLS, onFCP, onFID, onLCP, onTTFB } from 'web-vitals/attribution';

import { useAuth } from '@infinitus/auth';
import { logWebVitals } from '@infinitus/hooks/useLogBuffer';

const reportWebVitals = (onPerfEntry: ReportCallback) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    onCLS(onPerfEntry);
    onFCP(onPerfEntry);
    onFID(onPerfEntry);
    onLCP(onPerfEntry);
    onTTFB(onPerfEntry);
  }
};

function PerformanceLogger() {
  const { isAuthenticated } = useAuth();
  const [isReportingVitals, setReportingVitals] = useState(false);

  useEffect(() => {
    if (isAuthenticated && !isReportingVitals) {
      setReportingVitals(true);
      // We can't `logToBigQuery` via `logWebVitals` until
      // we're authenticated
      reportWebVitals(logWebVitals);
    }
  }, [isAuthenticated, isReportingVitals, setReportingVitals]);
  return null;
}

export default PerformanceLogger;
