import { useTheme } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useNavigate } from 'react-router-dom';

import { IconButton } from '@infinitus/components/Button';
import { getAccountPageUrl } from 'utils';

interface Props {
  fullName: string;
  imageUrl: string;
}

function AccountButton({ imageUrl, fullName }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <IconButton onClick={() => navigate(getAccountPageUrl())} title="Infinitus account">
      <Avatar
        alt={fullName || 'Profile Image'}
        src={imageUrl}
        sx={{
          height: theme.spacing(4),
          width: theme.spacing(4),
        }}
      />
    </IconButton>
  );
}

export default AccountButton;
