import { AppName } from '@infinitusai/api';
import useLocalStorage from '@rehooks/local-storage';
import { OptionsObject } from 'notistack';
import { PropsWithChildren, useCallback, useEffect } from 'react';
import { usePageVisibility } from 'react-page-visibility';
import { useNavigate } from 'react-router';
import { $enum } from 'ts-enum-util';

import { useAuth } from '@infinitus/auth';
import useApi from '@infinitus/hooks/useApi';
import useSnackbar from '@infinitus/hooks/useCustomSnackbar';
import useGetIdsFromUrl from '@infinitus/hooks/useGetIdsFromUrl';
import { logEventToBigQuery } from '@infinitus/hooks/useLogBuffer';
import { OperatorPresenceProvider } from '@infinitus/hooks/useOperatorPresence';
import usePresenceHeartbeat from '@infinitus/hooks/useOperatorPresence/usePresenceMessage';
import { useNexmoClient } from '@infinitus/nexmo';
import { NexmoAttachRequestSource } from '@infinitus/nexmo/NexmoContext';
import { infinitusai } from '@infinitus/proto/pbjs';
import { lowercase } from '@infinitus/utils';
import {
  OPERATOR_PORTAL_URL,
  PerformanceMarks,
  getOperatorPortalUrl,
} from '@infinitus/utils/constants';
import { startCorrelatingLogs } from '@infinitus/utils/logCorrelator';
import { wrapPromiseWithTimeout } from '@infinitus/utils/promiseHelpers';
import BegForOperatorNotification from 'components/Notifications/BegForOperatorNotification';
import GenericNotification from 'components/Notifications/GenericNotification';
import RequeueNotification from 'components/Notifications/RequeueNotification';
import {
  getNotificationType,
  notificationsVar,
  useNotificationBell,
} from 'components/Notifications/helpers';
import { ClientEventType } from 'generated/gql/graphql';
import { getCallPageUrl } from 'utils';
import { LOCAL_STORAGE_MUTE_NOTIFICATIONS_KEY } from 'utils/localStorage';

import { handleOnOperatorShouldLeaveCall } from './operatorPresenceUtil';

/*
  This is a higher order component that wraps the shared OperatorPresenceProvider and extends it
  to support event reactions for the internal-portal
*/

// Max time in ms to wait for the audio connection to be established before navigating to the call page
const MAX_TIME_TO_CONNECT_AUDIO_BEFORE_NAVIGATION = 3000;

interface Props {}

const PortalOperatorPresenceContextProvider = ({ children }: PropsWithChildren<Props>) => {
  const { api } = useApi();
  const { orgUuid } = useGetIdsFromUrl();
  const nexmoClient = useNexmoClient();
  const { user, getOrgNameFromUuid } = useAuth();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();

  // The heartbeatMessageType state var tracks the type of page we're currently on, which
  // changes the type of heartbeat we send to the server.
  const { getHeartbeatMessage, getHeartbeatMessageType } = usePresenceHeartbeat();
  const isVisible = usePageVisibility();

  // TODO: re-enable when we can query the backend for currentUserActivity
  // const [playAlert] = useSound(alertSound);
  const { getNotificationFromBell, addNotificationsToBell, removeNotificationsFromBell } =
    useNotificationBell();

  const [muteNotifications] = useLocalStorage<boolean>(LOCAL_STORAGE_MUTE_NOTIFICATIONS_KEY, false);

  const getPendingNotifications = useCallback(async () => {
    const { requestId, correlatedLog, correlatedError } = startCorrelatingLogs();
    try {
      const response = await api.getPendingNotifications(requestId);
      const notifications: infinitusai.be.Notification[] = response?.data?.notifications || [];
      notificationsVar(notifications);
      correlatedLog(`Received ${notifications.length} pending notifications`);
    } catch (e: any) {
      const errorMsg = `Failed to get pending notification: ${e?.response?.data || e.message}`;
      correlatedError(errorMsg);
      enqueueSnackbar(errorMsg, {
        variant: 'error',
      });
    }
  }, [api, enqueueSnackbar]);

  useEffect(() => {
    if (user) {
      getPendingNotifications();
    }
  }, [getPendingNotifications, user]);

  const onDismissNotification = useCallback(
    (data: infinitusai.be.DismissNotification) => {
      closeSnackbar(data.notificationUuid);
      removeNotificationsFromBell([data.notificationUuid]);
      if (data.followUpMessage && data.actingUserEmail !== user?.email) {
        enqueueSnackbar(data.followUpMessage, {
          variant: 'info',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
        });
      }
    },
    [closeSnackbar, enqueueSnackbar, removeNotificationsFromBell, user?.email]
  );

  const onNotificationReceived = useCallback(
    (data: infinitusai.be.Notification) => {
      const payload = data.payload ?? {};
      const sharedOptions: OptionsObject = {
        variant: 'info',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        key: data.uuid,
      };

      const isDuplicateNotification = !!getNotificationFromBell(data.uuid);
      if (!isDuplicateNotification) addNotificationsToBell([data]);

      // Uses human readable enum strings
      const metadata = {
        ...data,
        notificationType: infinitusai.be.NotificationType[getNotificationType(data)],
        status: infinitusai.be.NotificationStatus[data.status],
        duplicate: isDuplicateNotification,
      };

      // If duplicate notification received, notifications are muted (and not bypassed), the
      // operator is controlling an active call, or the tab is hidden, then don't alert with
      // notification popup and sound
      const controllingActiveCall =
        getHeartbeatMessage().callPageData?.isCallInProgress &&
        getHeartbeatMessage().callPageData?.activity ===
          infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity.CONTROL;
      const observingActiveCall =
        getHeartbeatMessage().callPageData?.isCallInProgress &&
        getHeartbeatMessage().callPageData?.activity ===
          infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity.OBSERVE;
      const bypassMuteNotificationSetting =
        !controllingActiveCall &&
        getNotificationType(data) ===
          infinitusai.be.NotificationType.NOTIFICATION_TYPE_BEG_FOR_OPERATOR;
      let silentReason;
      if (isDuplicateNotification) silentReason = 'duplicate-notification';
      else if (controllingActiveCall) silentReason = 'controlling-a-call';
      else if (observingActiveCall) silentReason = 'observing-a-call';
      else if (!isVisible) silentReason = 'page-in-background';
      else if (muteNotifications && !bypassMuteNotificationSetting) silentReason = 'muting-on';
      if (silentReason) {
        logEventToBigQuery({
          message: `${
            infinitusai.be.NotificationType[getNotificationType(data)]
          } notification delivered silently because of ${silentReason}`,
          clientEventType: ClientEventType.NOTIFICATION,
          meta: {
            ...metadata,
            silentReason,
          },
        });
        return;
      }

      // Removed as a quick P0 during blizzard - to be re-enabled when we implement an efficient backend call to
      // determine whether the user is controlling a call (so we can suppress it)
      // playAlert();
      if (payload.begForOperatorNotification) {
        enqueueSnackbar('', {
          ...sharedOptions,
          content: <BegForOperatorNotification notification={data} />,
        });
      } else if (payload.requeueNotification) {
        enqueueSnackbar('', {
          ...sharedOptions,
          content: <RequeueNotification notification={data} />,
        });
      } else {
        enqueueSnackbar('', {
          ...sharedOptions,
          content: <GenericNotification notification={data} />,
        });
      }
      logEventToBigQuery({
        message: `${
          infinitusai.be.NotificationType[getNotificationType(data)]
        } notification delivered visibly`,
        clientEventType: ClientEventType.NOTIFICATION,
        meta: metadata,
      });
    },
    [
      getNotificationFromBell,
      addNotificationsToBell,
      getHeartbeatMessage,
      isVisible,
      muteNotifications,
      enqueueSnackbar,
    ]
  );

  const onOperatorShouldJoinCall = useCallback(
    async (data: infinitusai.be.OperatorShouldJoinCallMessage) => {
      console.log('Processing the OperatorShouldJoinCallMessage since we are on the ready-page');
      const userEmail = user?.email;

      // Convert to lowercase key to match REACT_APP_BUILD_ENV format
      const targetEnvironmentKey = $enum(infinitusai.be.OperatorPortalEnvironment).getKeyOrDefault(
        data.targetEnvironment,
        'ANY'
      );
      const currentPortalUrl = OPERATOR_PORTAL_URL;
      const targetPortalUrl =
        targetEnvironmentKey === 'ANY'
          ? currentPortalUrl
          : getOperatorPortalUrl(lowercase(targetEnvironmentKey));

      const willChangeEnvironment = targetPortalUrl !== currentPortalUrl;

      if (orgUuid && orgUuid !== data.orgUuid) {
        // We are on the ready page for a different org, so we should not join the call
        console.error(
          `Received a server websocket message to join call '${data.callUuid}' for org '${orgUuid}', but we're on the org '${data.orgUuid}' ready page`
        );
      } else if (data.operatorEmail !== userEmail) {
        console.error(
          `Received a server websocket message to join call '${data.callUuid}' for operator '${data.operatorEmail}', but we are signed in as '${userEmail}'`
        );
      } else {
        window.performance.mark(PerformanceMarks.OPERATOR_ROUTED_BY_JOIN_CALL_MESSAGE);
        const orgName = getOrgNameFromUuid(data.orgUuid);
        if (orgName && data.nexmoConvUuid && !willChangeEnvironment) {
          console.log(
            `Received nexmoConvUuid '${data.nexmoConvUuid}' via the OperatorShouldJoinCallMessage, connecting to Nexmo immediately.`
          );
          enqueueSnackbar(
            `Connecting your call audio ${data.useHumanVoice ? ' to a human voice call' : ''}...`,
            {
              variant: data.useHumanVoice ? 'warning' : 'info',
            }
          );
          try {
            await wrapPromiseWithTimeout(
              nexmoClient.attach({
                orgUuid: data.orgUuid,
                conversationUuid: data.nexmoConvUuid,
                muteMicByDefault: true,
                source: NexmoAttachRequestSource.READY_PAGE,
              }),
              MAX_TIME_TO_CONNECT_AUDIO_BEFORE_NAVIGATION
            );
          } catch (e: any) {
            if (e.message === 'promise timeout') {
              console.log(
                `Timed out while connecting Nexmo audio from the ready-page after ${MAX_TIME_TO_CONNECT_AUDIO_BEFORE_NAVIGATION}ms`
              );
            } else {
              console.error(
                `Failed to connect Nexmo audio from the ready-page: ${JSON.stringify(e.message)}`
              );
            }
          }
        } else if (willChangeEnvironment) {
          console.log(
            `Join call message is targeting ${targetPortalUrl}, currently on ${currentPortalUrl}`
          );
          enqueueSnackbar(
            `Redirecting to ${targetEnvironmentKey.toLowerCase()} before joining call...`,
            {
              variant: 'info',
            }
          );
        } else {
          const errorMsg = `Skipping early audio connection. Missing org name (${orgName}) or conversationUuid (${data.nexmoConvUuid}).`;
          console.error(errorMsg);
        }

        if (orgName) {
          console.log(
            `Redirecting operator to call ${data.callUuid} in response to a server websocket message`
          );
          const callPageUrl = getCallPageUrl(orgName, data.taskUuid, data.callUuid, {
            routedByJoinCallMessage: true,
          });
          if (willChangeEnvironment) {
            window.location.href = `${targetPortalUrl}${callPageUrl}`;
          } else {
            navigate(callPageUrl);
          }
        } else {
          const errorMsg = `Attempted to redirect operator ${user?.email} to call ${data.callUuid} in the org ${data.orgUuid}, which they are not authorized in.`;
          console.error(errorMsg);
          enqueueSnackbar(errorMsg, {
            variant: 'error',
          });
        }
      }
    },
    [enqueueSnackbar, getOrgNameFromUuid, navigate, nexmoClient, orgUuid, user?.email]
  );

  const onOperatorShouldLeaveCall = useCallback(
    (data: infinitusai.be.OperatorShouldLeaveCallMessage) => {
      handleOnOperatorShouldLeaveCall({
        data,
        enqueueSnackbar,
        getHeartbeatMessageType,
        getOrgNameFromUuid,
        navigate,
        userEmail: user?.email || '',
      });
    },
    [enqueueSnackbar, getHeartbeatMessageType, getOrgNameFromUuid, navigate, user?.email]
  );

  return (
    <OperatorPresenceProvider
      appName={AppName.OPERATOR}
      onDismissNotification={onDismissNotification}
      onNotificationReceived={onNotificationReceived}
      onOperatorShouldJoinCall={onOperatorShouldJoinCall}
      onOperatorShouldLeaveCall={onOperatorShouldLeaveCall}
      orgUuid={orgUuid}
      user={user}
    >
      {children}
    </OperatorPresenceProvider>
  );
};

export default PortalOperatorPresenceContextProvider;
