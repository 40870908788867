/**
 * A singleton class to manage the websocket connection to our backend for updating operator
 * presence in calls.
 */
import { AppName } from '@infinitusai/api';
import EventEmitter from 'events';
import { io, Socket } from 'socket.io-client-v4';

import {
  lastKnownActivityMillis,
  presenceLastUpdatedAtMillis,
} from '@infinitus/hooks/useOperatorPresence/usePresenceMessage';
import { infinitusai } from '@infinitus/proto/pbjs';
import { UUID } from '@infinitus/types';
import { browserSessionUuid } from '@infinitus/utils';
import { getFirebaseAuth, getOperatorEmail } from '@infinitus/utils/api';
import {
  FRONTEND_VERSION,
  PerformanceMarks,
  PerformanceMeasures,
  PRESENCE_WEBSOCKET_URL,
} from '@infinitus/utils/constants';

export enum OperatorPresenceServiceEvents {
  CONNECTED = 'CONNECTED',
  DISCONNECTED = 'DISCONNECTED',
  OPERATOR_SHOULD_JOIN_CALL = 'OPERATOR_SHOULD_JOIN_CALL',
  OPERATOR_QUEUE = 'OPERATOR_QUEUE',
  HEARTBEAT_SENT = 'HEARTBEAT_SENT',
  REQUEST_UPDATE_SUGGESTIONS = 'REQUEST_UPDATE_SUGGESTIONS',
  NOTIFICATION_RECEIVED = 'NOTIFICATION_RECEIVED',
  DISMISS_NOTIFICATION = 'DISMISS_NOTIFICATION',
  HEARTBEAT_LATENCY = 'HEARTBEAT_LATENCY',
  OPERATOR_SHOULD_LEAVE_CALL = 'OPERATOR_SHOULD_LEAVE_CALL',
}

export enum WebsocketEvents {
  CONNECT = 'connection',
  DISCONNECT = 'disconnect',
  ERROR = 'error',
  AUTHENTICATION_ATTEMPT = 'authentication-attempt',
  AUTHENTICATION_SUCCESS = 'authentication-success',
  AUTHENTICATION_FAILURE = 'authenticate-failure',
  MESSAGE = 'message',
  NOTIFICATION = 'notification',
  DISMISS_NOTIFICATION = 'dismissNotification',
  HEARTBEAT = 'heartbeat',
  JOIN_CALL = 'joinCall',
  QUEUE = 'queue',
  PLAY_AUDIO = 'playAudio',
  LEAVE_CALL = 'leaveCall',
}

enum HeartbeatStatus {
  SUCCESS = 'success',
}

// When authentication fails, delay between retries (in ms)
const AUTH_RETRY_INTERVAL = 3000;
// When the connection drops, delay this amount before retrying (in ms)
const RECONNECT_RETRY_INTERVAL = 500;
// Max amount to wait before reattempting to connect (is ms)
const MAX_RECONNECT_INTERVAL = 10000;
// Log an error once we reach this number of attempts to connect unsuccessfully (and keep retrying)
const LOG_ERROR_ON_CONNECT_ATTEMPT_NUM = 5;

class OperatorPresenceService extends EventEmitter {
  private _socket?: Socket;
  // Tracks when the socket is connected and authed
  private _isAuthorized: boolean = false;

  // Tracks whether we wish to have the socket connected
  private _isOpenSocketRequested = false;
  // Tracks whether the socket is in the open state
  private _isSocketOpen = false;
  private _isAttemptingToConnect = false;
  // Store the most recent interval (we keep increasing it to backoff)
  private _reconnectAttemptDelay = RECONNECT_RETRY_INTERVAL;
  // After each attempt, how much to increase the interval by
  private _reconnectBackoffMulitplier = 2;
  // Tracks the number of attempts to connect
  private _connectionAttemptNumber = 0;
  // Stores the JS timer ID when we are retrying to connect
  private _retryTimeoutId = 0;
  private _orgUuid: UUID = '';
  private _appName: AppName = AppName.OPERATOR;

  // Audio vars.
  private _audioContext?: AudioContext;
  private _playing = false;
  private _audioBuffers: AudioBufferSourceNode[] = [];

  constructor() {
    super();
    this.initialize = this.initialize.bind(this);
    this.disconnect = this.disconnect.bind(this);
    this.sendHeartbeat = this.sendHeartbeat.bind(this);
    this.attemptToConnect = this.attemptToConnect.bind(this);
    this.buildNewWebsocket = this.buildNewWebsocket.bind(this);
    this.handleDisconnect = this.handleDisconnect.bind(this);
    this.handleAuthenticationSuccess = this.handleAuthenticationSuccess.bind(this);
    this.handleAuthenticationFailure = this.handleAuthenticationFailure.bind(this);
    this.handleError = this.handleError.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.handleDismissNotification = this.handleDismissNotification.bind(this);
    this.handleHeartbeat = this.handleHeartbeat.bind(this);
    this.handleQueue = this.handleQueue.bind(this);
    this.handleJoinCall = this.handleJoinCall.bind(this);
    this.handleLeaveCall = this.handleLeaveCall.bind(this);
    this.handlePlayAudioMessage = this.handlePlayAudioMessage.bind(this);
  }

  public async initialize(orgUuid: UUID, appName: AppName) {
    this._orgUuid = orgUuid;
    this._appName = appName;
    if (
      this._isAttemptingToConnect ||
      (this._isOpenSocketRequested && this._isAuthorized && this._socket)
    ) {
      return;
    }
    console.log(`${this.debugName}: Initializing socketio connection for org '${orgUuid}'...`);
    this._isOpenSocketRequested = true;
    await this.attemptToConnect();

    // Initialize AudioContext here so tests pass
    this._audioContext = new window.AudioContext();
  }

  // This will be mocked for testing
  protected buildNewWebsocket() {
    return io(PRESENCE_WEBSOCKET_URL, {
      withCredentials: true,
      // don't autoconnect so we can set up listeners before opening socket
      autoConnect: false,
      transports: ['polling', 'websocket'],
    });
  }

  private async attemptToConnect() {
    if (this._isAuthorized) {
      console.warn(
        `${this.debugName}:`,
        `OperatorPresenceService: socket is already open. Skipping additional call to attemptToConnect.`
      );
      return;
    }
    this._isAttemptingToConnect = true;
    try {
      this._socket = this.buildNewWebsocket();
    } catch (e) {
      console.error(`${this.debugName}:`, `Failed to create new websocket: ${e}`);
      return;
    }
    if (++this._connectionAttemptNumber === LOG_ERROR_ON_CONNECT_ATTEMPT_NUM) {
      console.error(
        `${this.debugName}:`,
        `OperatorPresenceService: Making successive attempt #${this._connectionAttemptNumber} at connecting the websocket.`
      );
    }
    this.setupListeners();
    this._socket.connect();
    this._isSocketOpen = true;
    this.sendAuthHandshake(this._orgUuid);
  }

  // Websocket handlers
  private handleAuthenticationSuccess() {
    if (this._isAttemptingToConnect) {
      console.log(`${this.debugName}:`, 'Authenticated via websocket connection.');
      this._isAuthorized = true;
    }
    console.log(`${this.debugName}:`, `Websocket authenticated successfully.`);
    // Reset the retry interval (in case the connection drops)
    this._reconnectAttemptDelay = RECONNECT_RETRY_INTERVAL;
    this._connectionAttemptNumber = 0;
    this._isAttemptingToConnect = false;
    this.emit(OperatorPresenceServiceEvents.CONNECTED);
  }

  private handleAuthenticationFailure(error: any) {
    console.error(
      `${this.debugName}:`,
      `Websocket: connection failed auth: ${error}. Will retry in ${AUTH_RETRY_INTERVAL}ms.`
    );
    this._socket?.disconnect();
    setTimeout(this.attemptToConnect, AUTH_RETRY_INTERVAL);
  }

  private handleHeartbeat() {
    window.performance.measure(
      PerformanceMeasures.TIME_TO_HEARTBEAT_ACKNOWLEDGED,
      PerformanceMarks.OPERATOR_HEARTBEAT_SENT_VIA_WEBSOCKET
    );
    const timeToRespond = window.performance.getEntriesByName(
      PerformanceMeasures.TIME_TO_HEARTBEAT_ACKNOWLEDGED
    )[0].duration;
    window.performance.clearMeasures(PerformanceMeasures.TIME_TO_HEARTBEAT_ACKNOWLEDGED);
    this.emit(OperatorPresenceServiceEvents.HEARTBEAT_LATENCY, timeToRespond);
  }

  private handleQueue(queue: string[]) {
    this.emit(OperatorPresenceServiceEvents.OPERATOR_QUEUE, queue);
  }

  private handleJoinCall(
    operatorShouldJoinCallMessage: infinitusai.be.IOperatorShouldJoinCallMessage
  ) {
    window.performance.mark(PerformanceMarks.OPERATOR_SHOULD_JOIN_CALL_RECEIVED_VIA_WEBSOCKET);
    this.emit(
      OperatorPresenceServiceEvents.OPERATOR_SHOULD_JOIN_CALL,
      infinitusai.be.OperatorShouldJoinCallMessage.fromObject(operatorShouldJoinCallMessage)
    );
  }

  private handleLeaveCall(
    operatorShouldLeaveCallMessage: infinitusai.be.IOperatorShouldLeaveCallMessage
  ) {
    window.performance.mark(PerformanceMarks.OPERATOR_SHOULD_JOIN_CALL_RECEIVED_VIA_WEBSOCKET);
    this.emit(
      OperatorPresenceServiceEvents.OPERATOR_SHOULD_LEAVE_CALL,
      infinitusai.be.OperatorShouldLeaveCallMessage.fromObject(operatorShouldLeaveCallMessage)
    );
  }

  private handleNotification(notification: infinitusai.be.INotification) {
    this.emit(OperatorPresenceServiceEvents.NOTIFICATION_RECEIVED, notification);
  }

  private handleDismissNotification(dismissNotification: infinitusai.be.IDismissNotification) {
    this.emit(OperatorPresenceServiceEvents.DISMISS_NOTIFICATION, dismissNotification);
  }

  private async handleDisconnect() {
    this.emit(OperatorPresenceServiceEvents.DISCONNECTED);
    this._isSocketOpen = false;
    this._socket?.disconnect();
    console.log(`${this.debugName}:`, 'Websocket closed');
    if (this._isOpenSocketRequested && !this._isAttemptingToConnect) {
      this._isAuthorized = false;
      console.warn(`${this.debugName}:`, `Websocket closed unexpectedly.`);
      console.log(`Websocket will attempt to reconnect in ${this._reconnectAttemptDelay} ms`);
      this._retryTimeoutId = window.setTimeout(
        () => this.attemptToConnect(),
        this._reconnectAttemptDelay
      );
      // Increase the delay for the next retry
      this._reconnectAttemptDelay = Math.min(
        MAX_RECONNECT_INTERVAL,
        this._reconnectAttemptDelay * this._reconnectBackoffMulitplier
      );
    }
  }
  private handleError(event: Event) {
    console.error(`${this.debugName}:`, `Websocket error occurred: ${JSON.stringify(event)}`);
    this._isAttemptingToConnect = false;
  }

  private playAudio() {
    if (!this._playing && this._audioBuffers.length > 0) {
      this._playing = true;
      this._audioBuffers.shift()?.start(0);
    }
  }

  // Add to _audioBuffers, and queue the audio to be played.
  private handlePlayAudioMessage(arrayBuffer: ArrayBuffer) {
    this._audioContext?.decodeAudioData(
      arrayBuffer,
      (buffer) => {
        var source = this._audioContext!.createBufferSource();
        source.buffer = buffer;
        source.onended = () => {
          this._playing = false;
          if (this._audioBuffers.length > 0) {
            this.playAudio();
          }
        };
        source.connect(this._audioContext!.destination);
        this._audioBuffers.push(source);
        this.playAudio();
      },
      (error) => {
        console.error(`${this.debugName}:`, 'Failed to decode audio message:', error);
      }
    );
  }

  private setupListeners() {
    if (!this._socket) {
      return;
    }
    this._socket.on(WebsocketEvents.AUTHENTICATION_SUCCESS, this.handleAuthenticationSuccess);
    this._socket.on(WebsocketEvents.AUTHENTICATION_FAILURE, this.handleAuthenticationFailure);
    this._socket.on(WebsocketEvents.HEARTBEAT, this.handleHeartbeat);
    this._socket.on(WebsocketEvents.DISCONNECT, this.handleDisconnect);
    this._socket.on(WebsocketEvents.ERROR, this.handleError);
    this._socket.on(WebsocketEvents.JOIN_CALL, this.handleJoinCall);
    this._socket.on(WebsocketEvents.LEAVE_CALL, this.handleLeaveCall);
    this._socket.on(WebsocketEvents.NOTIFICATION, this.handleNotification);
    this._socket.on(WebsocketEvents.QUEUE, this.handleQueue);
    this._socket.on(WebsocketEvents.DISMISS_NOTIFICATION, this.handleDismissNotification);
    this._socket.on(WebsocketEvents.PLAY_AUDIO, this.handlePlayAudioMessage);
  }

  public sendHeartbeat(payload?: infinitusai.be.IHeartbeatMessageFromClient) {
    if (!this._socket || !this._isAuthorized || !this._isSocketOpen) {
      console.warn(
        `${this.debugName}:`,
        'Attempted to send heartbeat on a non-established socket.'
      );
      return;
    }
    if (!payload) {
      console.error(
        `${this.debugName}:`,
        'Attempted to call OperatorPresenceService.sendHeartbeat with no payload'
      );
      return;
    }
    payload.orgUuid = this._orgUuid;
    payload.frontendVersion = FRONTEND_VERSION;
    payload.browserSessionUuid = browserSessionUuid;
    payload.timestamp = Date.now();
    payload.presenceLastUpdatedTimestamp = presenceLastUpdatedAtMillis();
    payload.lastKnownActivityTimestampMillis = lastKnownActivityMillis();
    const websocketClientMessage = infinitusai.be.WebsocketClientMessage.create({
      heartbeatMessage: payload,
    });
    const websocketClientMessageBuffer = Buffer.from(
      infinitusai.be.WebsocketClientMessage.encode(websocketClientMessage).finish()
    );

    // Emit the heartbeat event with acknowledgment callback
    this._socket.emit(WebsocketEvents.HEARTBEAT, websocketClientMessageBuffer, (status: string) => {
      window.performance.mark(PerformanceMarks.OPERATOR_HEARTBEAT_SENT_VIA_WEBSOCKET);
      this.emit(OperatorPresenceServiceEvents.HEARTBEAT_SENT, payload);
      if (status !== HeartbeatStatus.SUCCESS) {
        // Failed to send the heartbeat message
        console.error(`${this.debugName}:`, 'Failed to send the heartbeat message');
      }
    });
  }

  // Tear down the websocket and disconnect (e.g. once the user has logged out)
  public disconnect() {
    this._isOpenSocketRequested = false;
    // Clear any pending reconnect attempts
    window.clearTimeout(this._retryTimeoutId);
    this._retryTimeoutId = 0;
    if (!this._socket) return;
    this._isAuthorized = false;
    this._socket.close();
    this._socket = undefined;
    this._orgUuid = '';
    console.log(`${this.debugName}:`, 'Websocket: connection terminated.');
  }

  // We send the auth handshake, and if it succeeds we will receive
  // heartbeat messages back from the server
  private async sendAuthHandshake(orgUuid: UUID) {
    console.log(`${this.debugName}:`, 'Websocket: attempting to authenticate.');
    if (!this._socket)
      throw new Error('Attempted to send Auth handshake with no socketio established');
    const jwt = await getFirebaseAuth();
    const email = await getOperatorEmail();
    this._socket.emit(
      WebsocketEvents.AUTHENTICATION_ATTEMPT,
      email,
      jwt,
      orgUuid,
      FRONTEND_VERSION,
      this._appName
    );
  }

  // From outside of this class, we consider a websocket connected if it has
  // passed authentication and is open.
  public get isConnected() {
    return this._isAuthorized;
  }

  public get debugName() {
    return 'Presence Service';
  }
}

// We need to extend the class for testing with a mocked up websocket.
export { OperatorPresenceService as OnlyForTestingOperatorPresenceService };

const OperatorPresenceServiceInstance = new OperatorPresenceService();
export default OperatorPresenceServiceInstance;
