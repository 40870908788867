import { useCallback, useMemo } from 'react';

import { criticalReqState } from './CriticalReqLogger';

export type useCriticalReqLoggerResults = {
  /**
   * Saves the end time of a network request
   */
  endNetworkRequest: () => void;
  /**
   * Saves the start time of a network request
   */
  startNetworkRequest: () => void;
  /**
   * Wraps an async function to automatically call startNetworkRequest and endNetworkRequest
   * WARNING: If using this on a method of a class, either wrap the invocation in a closure
   * or bind the method to the class instance before passing it to this function.
   */
  wrapAsyncRequest: <T extends any[], U extends Promise<any>>(
    callback: (...args: T) => U
  ) => (...args: T) => Promise<U>;
};

export const useCriticalReqLogger = (componentName: string): useCriticalReqLoggerResults => {
  const startNetworkRequest = useCallback(() => {
    const existingTiming = criticalReqState.networkRequestTimings.get(componentName);
    if (existingTiming) {
      // Network request already started, so skip
      return;
    }
    criticalReqState.networkRequestTimings.set(componentName, {
      startTime: Date.now(),
    });
  }, [componentName]);

  const endNetworkRequest = useCallback(() => {
    const existingTiming = criticalReqState.networkRequestTimings.get(componentName);
    if (!existingTiming) {
      // Network request not started, so no need to end it
      return;
    }
    if (existingTiming.endTime !== undefined) {
      // Network request already ended so skip
      return;
    }
    existingTiming.endTime = Date.now();
  }, [componentName]);

  const wrapAsyncRequest = useCallback(
    <T extends Array<any>, U extends Promise<any>>(callback: (...args: T) => U) => {
      return async (...args: T) => {
        startNetworkRequest();
        try {
          return await callback(...args);
        } finally {
          endNetworkRequest();
        }
      };
    },
    [endNetworkRequest, startNetworkRequest]
  );

  return useMemo(() => {
    return {
      startNetworkRequest,
      endNetworkRequest,
      wrapAsyncRequest,
    };
  }, [startNetworkRequest, endNetworkRequest, wrapAsyncRequest]);
};
