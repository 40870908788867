import { keyframes } from '@mui/material';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

import Logo from 'components/Logo';

const scale = keyframes`
  0% {
    transform: scale3d(1,1,1);
  }
  50% {
    transform: scale3d(1.5,1.5,1);
  }
  100% {
    transform: scale3d(1,1,1);
  }
`;

const AnimatedLogo = styled(Logo)({
  animation: `${scale} 3s infinite cubic-bezier(.4,0,.2,1)`,
  height: 100,
  width: 100,
});

interface Props {
  message?: string;
}

function Loader({ message = 'Loading...' }: Props) {
  return (
    <div
      style={{
        alignItems: 'center',
        backgroundColor: '#424242',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        justifyContent: 'center',
        width: '100vw',
      }}
    >
      <AnimatedLogo />
      <Typography
        style={{
          color: '#ffffff',
          marginBlock: 40,
        }}
        variant="h4"
      >
        {message}
      </Typography>
    </div>
  );
}

export default Loader;
