import Long from 'long';

import { infinitusai } from '@infinitus/proto/pbjs';
import { CallState } from '@infinitus/utils/constants';
import { GetOrgs } from 'types/gqlMapping';

import { SuggestionsViewMode } from './localStorage';

export const castPossibleLongToNumber = (possibleLong: Long.Long | number): number => {
  if (Long.isLong(possibleLong)) return (possibleLong as Long).toNumber();
  return possibleLong as number;
};

const STRIP_SSML_REGEX = /<\/?[^>]+(>|$)/g;

export const stripSSML = (text: string) => text.replace(STRIP_SSML_REGEX, '').trim();

export * from './url';

export const getCallCompletionState = (callProto?: {
  audioEndMillis: number;
  audioStartMillis: number;
}): CallState => {
  if (!callProto) return CallState.UNKNOWN;
  const startMillis = Long.fromValue(callProto.audioStartMillis);
  const endMillis = Long.fromValue(callProto.audioEndMillis);
  if (startMillis.lessThanOrEqual(0) || endMillis.lessThan(0)) return CallState.UNKNOWN;
  return endMillis.isZero() ? CallState.IN_PROGRESS : CallState.COMPLETED;
};

export const isRecordingReady = (callProto?: infinitusai.be.CallDoc): boolean => {
  // If callProto is defined but callProto.recordingReady is undefined, we treat it as recordingReady as
  // new calls should either have recordingReady === false || recordingReady === true.
  return callProto
    ? callProto.recordingReady === true || callProto.recordingReady === undefined
    : false;
};

// Make JSON stringify deterministic by sorting keys - even the nested ones.
export const JSONStringifyOrder = (obj: any, space: number = 0) => {
  var allKeys: string[] = [];
  var seen: { [key: string]: boolean } = {};
  JSON.stringify(obj, function (key, value) {
    if (!(key in seen)) {
      allKeys.push(key);
      seen[key] = true;
    }
    return value;
  });
  allKeys.sort();
  return JSON.stringify(obj, allKeys, space);
};

// Takes in an array of org objects as input and creates a key value pair association with the key being the org Uuid
// and the value being the org name
export const getOrgUuidToDisplayNameMap = (orgsData: GetOrgs | undefined) => {
  const orgUuidToName = (orgsData?.orgs || []).reduce(
    (acc: { [key: string]: string }, cur: any) => {
      if (cur.id) {
        acc[cur.id] = cur.displayName ? cur.displayName : cur.id;
      }
      return acc;
    },
    {}
  );

  return orgUuidToName;
};

// getSuggestionViewMode takes a value and returns if the suggestion view mode is Reviewer or Operator
export const getSuggestionViewMode = (value: string) => {
  return value === SuggestionsViewMode.REVIEW ? 'Reviewer' : 'Operator';
};

// Returns the org name, which is used in the URL slug (not the display name)
export const getOrgUuidToOrgNameMap = (orgsData: GetOrgs | undefined) => {
  const orgUuidToName = (orgsData?.orgs || []).reduce(
    (acc: { [key: string]: string }, cur: any) => {
      if (cur.id) {
        acc[cur.id] = cur.name ? cur.name : cur.id;
      }
      return acc;
    },
    {}
  );

  return orgUuidToName;
};

type EnvironmentName = 'development' | 'unstable' | 'staging' | 'production';

export const getEnvironmentName: () => EnvironmentName = () => {
  if (window.location.href.includes('portal-dev')) {
    return 'unstable';
  }

  if (window.location.href.includes('staging')) {
    return 'staging';
  }

  if (window.location.href.includes('portal.')) {
    return 'production';
  }

  // Could include localhost, codespaces domains, etc.
  return 'development';
};

export function randInt(pMin: number, pMax: number) {
  pMin = Math.round(pMin);
  pMax = Math.round(pMax);
  const hi = Math.max(pMin, pMax);
  const lo = Math.min(pMin, pMax);
  return Math.floor(Math.random() * (hi + 1 - lo) + lo);
}

export function pickRandom(list: any[]) {
  return list[randInt(0, list.length - 1)];
}
