import { gql } from '@apollo/client';

import { OutputValueFragment } from './outputs';

export const EqualValueFragment = gql`
  fragment EqualValueFragment on EqualValue {
    ... on MoneyType {
      amountInCents
    }
    ... on BooleanType {
      bool
    }
    ... on StringType {
      string
    }
    ... on EnumType {
      enum
    }
    ... on EnumsType {
      enums
    }
    ... on IntType {
      int
    }
    ... on DateType {
      year
      month
      day
    }
    ... on OutputReferenceType {
      outputReference
    }
  }
`;

export const InequalityValueFragment = gql`
  fragment InequalityValueFragment on InequalityValue {
    ... on MoneyType {
      amountInCents
    }
    ... on IntType {
      int
    }
    ... on DateType {
      year
      month
      day
    }
    ... on StringType {
      string
    }
    ... on OutputReferenceType {
      outputReference
    }
  }
`;

const ConditionFragment = gql`
  fragment Condition on Condition {
    outputName
    description
    sourceType
    conditionIsMet
    predicate {
      ... on EqualPredicate {
        value {
          ...EqualValueFragment
        }
      }
      ... on NotEqualPredicate {
        value {
          ...EqualValueFragment
        }
      }
      ... on InPredicate {
        values {
          ...EqualValueFragment
        }
      }
      ... on NotInPredicate {
        values {
          ...EqualValueFragment
        }
      }
      ... on HasSubstringPredicate {
        value {
          string
        }
      }
      ... on HasPrefixPredicate {
        value {
          string
        }
      }
      ... on GTEPredicate {
        value {
          ...InequalityValueFragment
        }
      }
      ... on GTPredicate {
        value {
          ...InequalityValueFragment
        }
      }
      ... on LTEPredicate {
        value {
          ...InequalityValueFragment
        }
      }
      ... on LTPredicate {
        value {
          ...InequalityValueFragment
        }
      }
    }
    pluralPredicate {
      ... on CountGTPredicate {
        num
      }
    }
  }
  ${EqualValueFragment}
  ${InequalityValueFragment}
`;

export const RulesFragment = gql`
  fragment RulesFragment on Rule {
    payerIntelID
    category
    weight
    conditions {
      ...Condition
    }
    effects {
      ... on OutputFieldVisibilityEffect {
        visibility
        outputName
      }
      ... on OutputFieldValueEffect {
        effectType
        outputName
        description
        expectedValue {
          ...OutputValueFragment
        }
        fieldPath
        fieldValue
      }
      ... on FollowupSuggestionVisibilityEffect {
        visibility
        suggestionName
      }
    }
  }
  ${ConditionFragment}
  ${OutputValueFragment}
`;
