import { useReactiveVar } from '@apollo/client';
import Badge from '@mui/material/Badge';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Typography from '@mui/material/Typography';
import { useLocalStorage } from '@rehooks/local-storage';
import { useCallback, useEffect, useState } from 'react';

import { IconButton } from '@infinitus/components/Button';
import { Icon, IconNames } from '@infinitus/components/Icon';
import { Modal } from '@infinitus/components/Modal';
import { Switch } from '@infinitus/components/Switch';
import useApi from '@infinitus/hooks/useApi';
import { logEventToBigQuery } from '@infinitus/hooks/useLogBuffer';
import { infinitusai } from '@infinitus/proto/pbjs';
import BegForOperatorNotification from 'components/Notifications/BegForOperatorNotification';
import GenericNotification from 'components/Notifications/GenericNotification';
import RequeueNotification from 'components/Notifications/RequeueNotification';
import {
  dismissAllNotifications,
  getNotificationType,
  notificationsVar,
} from 'components/Notifications/helpers';
import { ClientEventType } from 'generated/gql/graphql';
import { castPossibleLongToNumber as longToNum } from 'utils';
import { LOCAL_STORAGE_MUTE_NOTIFICATIONS_KEY } from 'utils/localStorage';

enum NotificationLogType {
  NOTIFICATIONS_MUTED_TOGGLED = 'NOTIFICATIONS_MUTED_TOGGLED',
  DISMISS_ALL_NOTIFICATIONS = 'DISMISS_ALL_NOTIFICATIONS',
}

const Notifications = () => {
  const { api } = useApi();
  const notifications = useReactiveVar(notificationsVar);
  const [isMuted, setMuted] = useLocalStorage(LOCAL_STORAGE_MUTE_NOTIFICATIONS_KEY, true);
  const [isModalOpen, setModalOpen] = useState(false);
  const hasNotifications = notifications.length > 0;

  useEffect(() => {
    // Sort notifications in reverse chron order
    notifications.sort((a, b) => longToNum(b.enqueuedAtMillis) - longToNum(a.enqueuedAtMillis));
  }, [notifications]);

  const getComponentForNotification = useCallback(
    (notification: infinitusai.be.Notification): JSX.Element => {
      const type = getNotificationType(notification);
      const baseProps = {
        notification,
        compact: true,
      };
      switch (type) {
        case infinitusai.be.NotificationType.NOTIFICATION_TYPE_BEG_FOR_OPERATOR:
          return <BegForOperatorNotification {...baseProps} />;
        case infinitusai.be.NotificationType.NOTIFICATION_TYPE_REQUEUE_TASK:
          return <RequeueNotification {...baseProps} />;
        case infinitusai.be.NotificationType.NOTIFICATION_TYPE_GENERIC:
          return <GenericNotification {...baseProps} />;
        default:
          return <div>Unknown notification type</div>;
      }
    },
    []
  );

  const handleDismissAll = () => {
    if (hasNotifications) {
      logEventToBigQuery({
        message: 'Dismiss all notifications clicked',
        clientEventType: ClientEventType.NOTIFICATION,
        meta: {
          numNotifications: notifications.length,
          notifications: NotificationLogType.DISMISS_ALL_NOTIFICATIONS,
        },
      });
      dismissAllNotifications(api, notifications);
    }
    setModalOpen(false);
  };

  const handleAlertSwitchChange = (alerting: boolean) => {
    logEventToBigQuery({
      message: `Notifications muted toggled to ${!alerting}`,
      clientEventType: ClientEventType.NOTIFICATION,
      meta: {
        notifications: NotificationLogType.NOTIFICATIONS_MUTED_TOGGLED,
        alerting,
      },
    });
    setMuted(!alerting);
  };

  function renderButtonContent() {
    const icon = <Icon name={getActiveIconName()} />;
    if (!hasNotifications) return icon;
    return (
      <Badge badgeContent={notifications.length} color="error">
        {icon}
      </Badge>
    );
  }

  function getActiveIconName() {
    let iconName = isMuted ? IconNames.NOTIFICATIONS_OFF : IconNames.NOTIFICATIONS;
    if (!isMuted && hasNotifications) {
      iconName = IconNames.NOTIFICATIONS_ACTIVE;
    }
    return iconName;
  }

  function renderNotifications() {
    if (!hasNotifications)
      return (
        <div style={{ textAlign: 'center' }}>
          <Typography variant="h6">All caught up!</Typography>
        </div>
      );
    return (
      <MenuList>
        {notifications.map((notification) => (
          <MenuItem dense key={notification.uuid} sx={{ padding: 0, marginBottom: 1 }}>
            {getComponentForNotification(notification)}
          </MenuItem>
        ))}
      </MenuList>
    );
  }

  return (
    <>
      <IconButton onClick={() => setModalOpen(true)} title="Notifications">
        {renderButtonContent()}
      </IconButton>
      <Modal
        cancelable={hasNotifications}
        confirmButtonLabel={hasNotifications ? 'Dismiss All' : 'OK'}
        isOpen={isModalOpen}
        onCancel={() => setModalOpen(false)}
        onConfirm={() => handleDismissAll()}
        title={
          <div className="flex flexCrossCenter flexMainBetween">
            <Typography variant="h5">Notifications</Typography>
            <Switch
              iconName={getActiveIconName()}
              isOn={isMuted === false}
              label={isMuted === false ? 'On' : 'Muted'}
              onChange={(alerting: boolean) => handleAlertSwitchChange(alerting)}
            />
          </div>
        }
      >
        {renderNotifications()}
      </Modal>
    </>
  );
};

export default Notifications;
