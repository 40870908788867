import { Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { usePageVisibility } from 'react-page-visibility';

import { Modal } from '@infinitus/components/Modal';
import { ClientEventType } from '@infinitus/generated/frontend-common';

import useOperatorPresence from '.';
import { logEventToBigQuery } from '../useLogBuffer';
import { HeartbeatMessageType } from './types';
import usePresenceHeartbeat from './usePresenceMessage';

export function PageLeftConfirmationModal() {
  const isVisible = usePageVisibility();
  const { operatorReadyToJoinCalls, setOperatorReadyToJoinCalls } = useOperatorPresence();
  const { getHeartbeatMessageType } = usePresenceHeartbeat();

  const [
    shouldShowPageVisibilityConfirmationDialog,
    setShouldShowPageVisibilityConfirmationDialog,
  ] = useState(false);

  const hideDialog = () => {
    setShouldShowPageVisibilityConfirmationDialog(false);
  };

  useEffect(() => {
    if (
      !isVisible &&
      operatorReadyToJoinCalls &&
      getHeartbeatMessageType() === HeartbeatMessageType.READY_PAGE
    ) {
      setOperatorReadyToJoinCalls(false);
      setShouldShowPageVisibilityConfirmationDialog(true);
      logEventToBigQuery({
        clientEventType: ClientEventType.AUTO_JOIN_CALL,
        message:
          'PageLeftConfirmationModal: user left page and was automatically marked unavailable',
      });
    }
  }, [getHeartbeatMessageType, isVisible, operatorReadyToJoinCalls, setOperatorReadyToJoinCalls]);

  return (
    <Modal
      confirmButtonLabel="Yes"
      isOpen={shouldShowPageVisibilityConfirmationDialog}
      onCancel={hideDialog}
      onConfirm={() => {
        setOperatorReadyToJoinCalls(true);
        hideDialog();
      }}
      title="Keep this tab in the foreground"
    >
      <Typography paragraph>While you were gone, you were marked as "Unavailable".</Typography>
      <Typography paragraph>Are you "Available" again?</Typography>
    </Modal>
  );
}
