import { User } from 'firebase/auth';
import { useParams } from 'react-router-dom';

import { useAuth } from '@infinitus/auth';
import { BACKEND_SERVER_URL } from '@infinitus/utils/constants';
import { ErrorType } from 'utils/errors';

interface FetchParams {
  app: string;
  body: any;
  orgUuid?: string;
  url: string;
  user: User | null;
}

export function useApiParams() {
  const { orgName } = useParams<{
    orgName: string;
  }>();
  const { user, getOrgInfo } = useAuth();
  const orgUuid = orgName ? getOrgInfo(orgName)?.id || '' : '';
  return { user: user ?? null, orgUuid };
}

export async function fetchApi({ app, user, orgUuid, url, body }: FetchParams) {
  const token = (await user?.getIdToken()) || '';
  if (!token) {
    console.warn('No current firebase user! Requests will not have authorization.');
  }

  // Send request to backend
  const response = await fetch(`${BACKEND_SERVER_URL}${url}`, {
    method: 'POST',
    headers: {
      Authorization: token,
      'X-INF-APP-NAME': app,
      'X-INF-ORG-UUID': orgUuid || '',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  });

  // Handle success
  if (response.ok) {
    const result = await response.json();
    return result;
  }

  // Handle error
  const errorText = await response.text();
  const error = new Error();
  switch (response.status) {
    case 400:
      error.name = ErrorType.BAD_REQUEST;
      error.message = errorText.trim();
      break;
    case 401:
      error.name = ErrorType.UNAUTHORIZED;
      error.message = 'unauthorized';
      break;
    case 403:
      error.name = ErrorType.FORBIDDEN;
      error.message = 'unauthorized';
      break;
    case 404:
      error.name = ErrorType.NOT_FOUND;
      error.message = 'endpoint not found';
      break;
    default:
      error.name = ErrorType.INTERNAL_SERVER;
      error.message = 'internal server error';
      break;
  }
  throw error;
}
