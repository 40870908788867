import { makeVar, useReactiveVar } from '@apollo/client';
import timezone from 'dayjs/plugin/timezone';
import _ from 'lodash';
import { useEffect } from 'react';
import { Location } from 'react-router-dom';
import { Metric } from 'web-vitals';

import { CriticalReqMetrics } from '@infinitus/components/CriticalReqLogger/CriticalReqLogger';
import { ClientEvent, ClientEventType } from '@infinitus/generated/frontend-common';
import EventLoggingService from '@infinitus/services/EventLoggingService';
import { UUID } from '@infinitus/types';
import { browserSessionUuid } from '@infinitus/utils';
import { FRONTEND_VERSION } from '@infinitus/utils/constants';
import dayjs from '@infinitus/utils/dayjs';
import { isLocalhost } from '@infinitus/utils/isLocalhost';
import { startCorrelatingLogs } from '@infinitus/utils/logCorrelator';

import useGetIdsFromUrl from './useGetIdsFromUrl';

dayjs.extend(timezone);

export interface LogItem {
  message: string;
  meta: {
    [key: string]: any;
    timestampMillis?: number;
  };
  type: ClientEventType;
}

export interface ClientEventScope {
  callUuid: UUID;
  orgUuid: UUID;
  taskUuid: UUID;
}

const LOG_BUFFER_SIZE = 25;

export const logBufferVar = makeVar<LogItem[]>([]);

export const clientEventScopeVar = makeVar<ClientEventScope>({
  callUuid: '',
  orgUuid: '',
  taskUuid: '',
});

interface ConsoleLog {
  metadata?: any;
  requestId?: string;
  type: ClientEventType;
}

const logInConsole = ({ metadata, type, requestId }: ConsoleLog) => {
  let consoleLog: Function;
  let consoleMessage: any;
  switch (type) {
    case ClientEventType.ERROR:
      consoleLog = console.error;
      if (requestId) {
        const { correlatedError } = startCorrelatingLogs({ requestId });
        consoleLog = correlatedError;
      }
      consoleMessage = `metadata: ${JSON.stringify(metadata)}`;
      break;
    default:
      consoleLog = console.info;
      consoleMessage = metadata;
      break;
  }
  // Actually log in console if error or doing local dev
  if (isLocalhost || type === ClientEventType.ERROR) {
    consoleLog(consoleMessage);
  }
};

export interface BigQueryEvent {
  callUuid?: string;
  clientEventType: ClientEventType;
  message: string;
  meta?: any;
  orgUuid?: string;
  requestId?: string;
  taskUuid?: string;
}

export const logEventToBigQuery = async ({
  clientEventType,
  message,
  meta,
  requestId,
  orgUuid, // Optional parameter for FT
  callUuid, // Optional parameter for FT
  taskUuid, // Optional parameter for FT
}: BigQueryEvent) => {
  // Get UUIDs from clientEventScopeVar if they are not provided as parameters
  const scopeVar = clientEventScopeVar();
  const { orgUuid: scopeOrgUuid, callUuid: scopeCallUuid, taskUuid: scopeTaskUuid } = scopeVar;

  const extendedMetadata = {
    browserSessionUuid,
    requestId,
    frontendVersion: FRONTEND_VERSION,
    ...meta,
  };

  const clientEvent: ClientEvent = {
    callUUID: callUuid ?? scopeCallUuid, // Use callUuid from parameters or default if undefined
    clientEventType: clientEventType,
    eventURL: window.location.href,
    eventTimestampMillis: Date.now(),
    logMessage: message,
    metadata: extendedMetadata,
    orgUUID: orgUuid ?? scopeOrgUuid, // Use orgUuid from parameters or default if undefined
    taskUUID: taskUuid ?? scopeTaskUuid, // Use taskUuid from parameters or default if undefined
  };

  EventLoggingService.log(clientEvent);
  logInConsole({
    metadata: clientEvent,
    type: clientEventType,
  });
};

export interface APIRequestEvent {
  endpoint: string;
  requestId?: string;
  success: boolean;
}

export const logAPIRequest = (event: APIRequestEvent) => {
  const { endpoint, success } = event;
  logEventToBigQuery({
    clientEventType: ClientEventType.API_REQUEST,
    message: `API Request: ${endpoint} - ${success ? 'Successful' : 'Unsuccessful'}`,
    meta: {
      ...event,
    },
    requestId: event?.requestId,
  });
};

export interface NavigationEvent {
  fromLocation: Location | null;
  toLocation: Location;
}

const getUrlStringFromLocation = (loc: Location | null) => {
  if (loc === null) return null;
  return `${loc.pathname}${loc.search}${loc.hash}`;
};

export const logNavLoggerReady = (location: Location) => {
  const msgLocStr = getUrlStringFromLocation(location);
  const connection = window.navigator?.connection;
  logEventToBigQuery({
    clientEventType: ClientEventType.NAVIGATION,
    message: `Navigate: ready at ${msgLocStr}`,
    meta: {
      downlink: connection?.downlink,
      effectiveType: connection?.effectiveType,
      navigationType: 'pageReady',
      rtt: connection?.rtt,
      saveData: connection?.saveData,
    },
  });
};

// TODO: Add flag to show change vs interval logging
export const logConnection = (
  networkInformation: NetworkInformation | undefined,
  logReason: 'change' | 'ready' | 'timed' | 'slowConnection' | 'goodConnection'
) => {
  if (!networkInformation) return;
  logEventToBigQuery({
    clientEventType: ClientEventType.INFO,
    message: `Network monitor: ${logReason}`,
    meta: {
      downlink: networkInformation.downlink,
      effectiveType: networkInformation.effectiveType,
      logReason: logReason,
      roundTripTime: networkInformation.rtt,
      usingDataSaverMode: networkInformation?.saveData,
    },
  });
};

interface GcsFileUploadEvent {
  file: File;
  presignedUploadUrl: string;
  statusCode: number;
}

export const logGcsFileUpload = ({ file, presignedUploadUrl, statusCode }: GcsFileUploadEvent) => {
  const [destinationUrl] = presignedUploadUrl.split('?');

  logEventToBigQuery({
    clientEventType: ClientEventType.GCS_FILE_UPLOAD,
    message: `Upload file: ${file.name} to ${presignedUploadUrl} with status code ${statusCode}`,
    meta: {
      destinationUrl,
      fileName: file.name,
      fileSize: file.size,
      fileType: file.type,
      statusCode,
    },
  });
};

export const logNavigation = ({ fromLocation, toLocation }: NavigationEvent) => {
  // Initially when there's no location
  if (fromLocation === null) return;
  const fromStr = getUrlStringFromLocation(fromLocation);
  const toStr = getUrlStringFromLocation(toLocation);
  logEventToBigQuery({
    clientEventType: ClientEventType.NAVIGATION,
    message: `Navigate: from ${fromStr} to ${toStr}`,
    meta: {
      fromLocation: fromLocation,
      navigationType: 'navigate',
      toLocation: toLocation,
    },
  });
};

export const logTabVisibilityEvent = ({
  isVisible,
  location,
}: {
  isVisible: boolean;
  location: Location;
}) => {
  const eventStr = isVisible ? 'focus' : 'blur';
  logEventToBigQuery({
    clientEventType: ClientEventType.NAVIGATION,
    message: `Tab: ${eventStr} at ${getUrlStringFromLocation(location)}`,
    meta: {
      navigationType: eventStr,
      location: location,
    },
  });
};

interface UiEvent {
  callUuid?: string;
  componentLabel: string;
  componentName: string;
  eventMeta?: object;
  eventName: string;
  eventValue?: string | number;
  orgUuid?: string;
  taskUuid?: string;
}

export const logUiEvent = ({
  componentLabel,
  componentName,
  eventMeta,
  eventName,
  eventValue,
  orgUuid, // Optional parameter for FT
  callUuid, // Optional parameter for FT
  taskUuid, // Optional parameter for FT
}: UiEvent) => {
  logEventToBigQuery({
    clientEventType: ClientEventType.UI_EVENT,
    message: `UI Event: ${eventName} on ${componentName}`,
    meta: {
      componentLabel: componentLabel,
      componentName: componentName,
      eventMeta: eventMeta,
      eventName: eventName,
      eventValue: eventValue,
    },
    // Pass additional optional UUIDs to logEventToBigQuery function for FT
    orgUuid,
    callUuid,
    taskUuid,
  });
};

export const logInfo = (message: string, meta?: any) => {
  addToLogBuffer({ type: ClientEventType.INFO, message, meta });
};

export const logError = (message: string, meta?: any) => {
  addToLogBuffer({ type: ClientEventType.ERROR, message, meta });
};

export const logGqlError = (message: string, meta?: any) => {
  addToLogBuffer({ type: ClientEventType.GQL_ERROR, message, meta });
};

const addToLogBuffer = ({ type, message, meta }: LogItem) => {
  const timestamp = dayjs.tzo().format('YYYY/MM/DD HH:mm:ss z');
  const items = logBufferVar();
  const extendedMeta = {
    ...meta,
    timestampMillis: Date.now(),
    url: window.location.href,
  };
  items.push({
    message: `[${timestamp}] ${message}`,
    meta: extendedMeta,
    type,
  });
  if (items.length > LOG_BUFFER_SIZE) {
    items.shift();
  }
  logInConsole({
    metadata: extendedMeta,
    type: type,
  });
  logBufferVar(items);
};

const useLogBuffer = () => {
  return {
    logBuffer: useReactiveVar(logBufferVar),
  };
};

// This is required to log things in all user modes.
// If it's not included, orgUUID will be missing for operators.
export const useClientEventScope = () => {
  const { callUuid, orgUuid, taskUuid } = useGetIdsFromUrl();
  useEffect(() => {
    clientEventScopeVar({ callUuid, orgUuid, taskUuid });
  }, [callUuid, orgUuid, taskUuid]);
};

// This is required to log things in all user modes.
// If it's not included, orgUUID will be missing for operators.
export const useClientEventScopeFT = (orgUuid: string, callUuid: string, taskUuid: string) => {
  useEffect(() => {
    clientEventScopeVar({ callUuid, orgUuid, taskUuid });
  }, [callUuid, orgUuid, taskUuid]);
};

// CLS, FID, and LCP are the most important
// See docs: https://web.dev/learn-core-web-vitals/
enum WebVitalNames {
  'CLS' = 'Cumulative Layout Shift',
  'FCP' = 'First Contentful Paint',
  'FID' = 'First Input Delay',
  'INP' = 'Interaction to Next Paint',
  'LCP' = 'Largest Contentful Paint',
  'TTFB' = 'Time To First Byte',
}

export const logWebVitals = (metric: Metric) => {
  logEventToBigQuery({
    clientEventType: ClientEventType.INFO,
    message: `Web vitals: ${WebVitalNames[metric.name]} (${metric.rating})`,
    meta: { ...JSON.parse(JSON.stringify(metric)) },
  });
};

export const logErrorBoundarySeenEvent = () => {
  const logBuffer = logBufferVar();
  logEventToBigQuery({
    clientEventType: ClientEventType.ERROR,
    message: 'Error Boundary',
    meta: {
      logBuffer,
    },
  });
};

export const logCriticalReqMetrics = (metrics: CriticalReqMetrics) => {
  // Clone metrics so we can modify it w/o affecting the original
  // Otherwise in unit tests, the original metrics will be modified
  const meta: any = {
    metrics: _.cloneDeep(metrics),
  };
  // GQLGen Map type doesn't support arrays so convert them to objects
  metrics.componentMetrics.forEach((componentMetric) => {
    meta.metrics[componentMetric.componentName] = {
      ...componentMetric,
    };
    delete meta.metrics[componentMetric.componentName].componentName;
  });
  delete meta.metrics.componentMetrics;

  logEventToBigQuery({
    clientEventType: ClientEventType.CRITICAL_REQUEST_METRIC,
    message: `Critical Request Metrics: Loaded in ${metrics.durationMs}ms`,
    meta,
  });
};

export type CustomerPieTriggeredEvent = {
  conflicts: string;
  details: string;
  // @TODO Fields to be added after blizzard
  // conditionalOutputFields?: string;
  // conflictType?: 'pushback' | 'override';
  // effectOutputFieldValue?: string;
  ruleId: string;
};

export const logCustomerPieTriggeredEvent = (pieEvent: CustomerPieTriggeredEvent) => {
  const bqEvent: BigQueryEvent = {
    clientEventType: ClientEventType.CUSTOMER_PIE_TRIGGERED,
    message: `Customer PIE Triggered: ${pieEvent.ruleId}`,
    meta: {
      ...pieEvent,
      source: 'CUSTOMER_PIE',
    },
  };
  logEventToBigQuery(bqEvent);
};

export default useLogBuffer;
