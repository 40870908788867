import { useAuth } from '@infinitus/auth';

import OrgLivenessFlag from '../OrgLivenessFlag';

interface Props {
  orgName?: string;
}

export default function OrgLivenessFlags({ orgName }: Props) {
  const { getOrgInfo } = useAuth();

  if (!orgName) return null;

  const orgMembership = getOrgInfo(orgName);
  const { id, isInternal, isLive } = orgMembership;

  // User is not a member of org
  if (!id) return null;

  return (
    <>
      {!isInternal && (
        <OrgLivenessFlag
          label="Customer can view this org via the Customer Portal"
          title="Customer-facing"
        />
      )}
      {isLive && <OrgLivenessFlag label="This org services production tasks" title="Production" />}
    </>
  );
}
