import { gql, useMutation, useSubscription } from '@apollo/client';
import { ReactNode, useCallback, useEffect, useState } from 'react';

import { useAuth } from '@infinitus/auth';
import useCallParams from '@infinitus/hooks/useCallParams';
import { useSnackbar } from '@infinitus/hooks/useCustomSnackbar';
import { logEventToBigQuery } from '@infinitus/hooks/useLogBuffer';
import { ClientEventType } from 'generated/gql/graphql';
import { OperatorOfflineReason, OperatorOnlineStatus } from 'generated/gql/graphql';
import usePrevious from 'hooks/usePrevious';
import {
  SubscribeToUser,
  SubscribeToUserVariables,
  UpdateOperatorOnlineStatus,
  UpdateOperatorOnlineStatusVariables,
} from 'types/gqlMapping';

import { InactivityDetector } from './InactivityDetector';
import OperatorOnlineStatusContext from './OperatorOnlineStatusContext';

export const UPDATE_OPERATOR_ONLINE_STATUS = gql`
  mutation UpdateOperatorOnlineStatus($orgUUID: ID!, $operatorStatusEvent: OperatorStatusEvent!) {
    updateOperatorOnlineStatus(orgUUID: $orgUUID, operatorStatusEvent: $operatorStatusEvent) {
      operatorOfflineReason
      operatorOnlineStatus
      lastStatusUpdateTimestampMillis
      statusLastUpdatedFromUrl
    }
  }
`;

export const SUBSCRIBE_OPERATOR_ONLINE_STATUS = gql`
  subscription SubscribeToUser($orgUUID: ID!) {
    subscribeToUser(orgUUID: $orgUUID) {
      operatorOnlineStatus
      operatorOfflineReason
    }
  }
`;

interface ProviderProps {
  children: ReactNode;
}

function OperatorOnlineStatusProvider({ children }: ProviderProps) {
  const { orgName } = useCallParams();
  const { isLoading: isAuthLoading, getOrgInfo } = useAuth();
  const orgInfo = getOrgInfo(orgName);
  const orgUuid = orgInfo.id;

  // Used for error message
  const { enqueueSnackbar } = useSnackbar();

  const [selectedReason, setSelectedReason] = useState<OperatorOfflineReason>(
    OperatorOfflineReason.UNKNOWN
  );
  const [hasHandledPageLoad, setHandledPageLoad] = useState(false);

  const [updateOperatorOnlineStatus] = useMutation<
    UpdateOperatorOnlineStatus,
    UpdateOperatorOnlineStatusVariables
  >(UPDATE_OPERATOR_ONLINE_STATUS);

  const { data: operatorOnlineStatusResponse, loading: operatorOnlineStatusLoading } =
    useSubscription<SubscribeToUser, SubscribeToUserVariables>(SUBSCRIBE_OPERATOR_ONLINE_STATUS, {
      variables: {
        orgUUID: orgUuid,
      },
      skip: isAuthLoading, // Wait for auth to load before subscribing
      onData: ({ data: subscriptionData }) => {
        const operatorStatus = subscriptionData?.data?.subscribeToUser;
        const isOperatorOffline =
          operatorStatus?.operatorOnlineStatus === OperatorOnlineStatus.OFFLINE;
        const operatorOfflineReason = operatorStatus?.operatorOfflineReason;
        if (isOperatorOffline && operatorOfflineReason) {
          setSelectedReason(operatorOfflineReason);
        }
      },
    });

  // undefined when the subscription is loading
  const operatorOnlineStatus = operatorOnlineStatusResponse?.subscribeToUser?.operatorOnlineStatus;
  const prevOperatorOnlineStatus = usePrevious(operatorOnlineStatus);

  const showError = useCallback(
    (evt: any) => {
      enqueueSnackbar(`Error setting online status: ${evt?.response?.data || evt.message}`, {
        variant: 'error',
      });
      console.error('Online status error:', evt);
    },
    [enqueueSnackbar]
  );

  const markOperatorOffline = useCallback(
    async (reason: OperatorOfflineReason) => {
      try {
        await updateOperatorOnlineStatus({
          variables: {
            orgUUID: orgUuid,
            operatorStatusEvent: {
              reason: reason || undefined,
              status: OperatorOnlineStatus.OFFLINE,
              url: window.location.href,
            },
          },
        });
        logEventToBigQuery({
          message: `Operator offline. Reason: ${reason}`,
          clientEventType: ClientEventType.OPERATOR_STATUS,
          meta: {
            operatorOfflineReason: reason,
            operatorStatus: OperatorOnlineStatus.OFFLINE,
          },
        });
      } catch (evt: any) {
        showError(evt);
      }
    },
    [orgUuid, showError, updateOperatorOnlineStatus]
  );

  const markOperatorOnline = useCallback(async () => {
    if (operatorOnlineStatus !== OperatorOnlineStatus.ONLINE && !operatorOnlineStatusLoading) {
      try {
        await updateOperatorOnlineStatus({
          variables: {
            orgUUID: orgUuid,
            operatorStatusEvent: {
              reason: undefined,
              status: OperatorOnlineStatus.ONLINE,
              url: window.location.href,
            },
          },
        });
      } catch (evt: any) {
        showError(evt);
      }
    }
  }, [
    operatorOnlineStatus,
    operatorOnlineStatusLoading,
    orgUuid,
    showError,
    updateOperatorOnlineStatus,
  ]);

  // Automatically mark the user as online upon page refresh
  useEffect(() => {
    // operatorOnlineStatus start as undefined.
    // operatorOnlineStatus updates to ONLINE, OFFLINE, or UNKNOWN when subscription data loads.
    if (
      hasHandledPageLoad === false &&
      prevOperatorOnlineStatus === undefined &&
      operatorOnlineStatus !== undefined
    ) {
      if (operatorOnlineStatus === OperatorOnlineStatus.OFFLINE) {
        markOperatorOnline();
      }
      setHandledPageLoad(true);
    }
  }, [
    hasHandledPageLoad,
    operatorOnlineStatus,
    prevOperatorOnlineStatus,
    markOperatorOnline,
    setHandledPageLoad,
  ]);

  return (
    <OperatorOnlineStatusContext.Provider
      value={{
        operatorOfflineReason: selectedReason,
        operatorOnlineStatus,
        markOperatorOffline,
        markOperatorOnline,
      }}
    >
      <InactivityDetector />
      {children}
    </OperatorOnlineStatusContext.Provider>
  );
}

export default OperatorOnlineStatusProvider;
