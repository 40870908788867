import WebRTCIssueDetector, {
  AvailableOutgoingBitrateIssueDetector,
  InboundNetworkIssueDetector,
  NetworkMediaSyncIssueDetector,
  OutboundNetworkIssueDetector,
  QualityLimitationsIssueDetector,
} from 'webrtc-issue-detector';

import { ClientEventType } from '@infinitus/generated/frontend-common';
import { logEventToBigQuery } from '@infinitus/hooks/useLogBuffer';

const GET_PEER_CONNECTION_STATS_INTERVAL = 10_000;

/**
 * WebRTC issue detector is a singleton instance used to detect WebRTC issues and
 * calculate network scores. This object hijacks the RTCPeerConnection constructor
 * to snoop on new peer connections to collect stats and detect issues.
 *
 * Note: this object needs to be created before the first RTCPeerConnection is created.
 *
 * You must call start and stop methods to start and stop watching new peer connections.
 *
 *   ex. start collecting getStats() and detecting issues
 *   webRtcIssueDetector.watchNewPeerConnections();
 *
 *   ex. stop collecting WebRTC stats and issues detection
 *   webRtcIssueDetector.stopWatchingNewPeerConnections();
 */
export const webRtcIssueDetector = new WebRTCIssueDetector({
  getStatsInterval: GET_PEER_CONNECTION_STATS_INTERVAL,
  onIssues: (issues) => {
    console.log('WebRTC issues detected', JSON.stringify(issues));
    logEventToBigQuery({
      clientEventType: ClientEventType.NEXMO,
      message: 'WebRTC issues detected',
      meta: {
        issues,
      },
    });
  },
  onNetworkScoresUpdated: (scores) => {
    console.log('WebRTC scores', JSON.stringify(scores));
    logEventToBigQuery({
      clientEventType: ClientEventType.NEXMO,
      message: 'WebRTC scores',
      meta: {
        scores,
      },
    });
  },
  detectors: [
    new QualityLimitationsIssueDetector(),
    new InboundNetworkIssueDetector({
      highRttThresholdMs: 350,
      highJitterThreshold: 350,
      highPacketLossThresholdPct: 10,
    }),
    new OutboundNetworkIssueDetector(),
    new NetworkMediaSyncIssueDetector(),
    new AvailableOutgoingBitrateIssueDetector(),
  ],
});
