import Long from 'long';

import { infinitusai } from '@infinitus/proto/pbjs';
import dayjs from '@infinitus/utils/dayjs';
import { Date as GqlDate } from 'generated/gql/graphql';
import { TaskState } from 'generated/gql/graphql';

export const protoDateToString = (
  nullableDate: infinitusai.be.IDate | null | undefined
): string => {
  if (!nullableDate) {
    return '';
  }
  if (nullableDate.year && nullableDate.month && nullableDate.day) {
    const { year, month, day } = nullableDate;
    // Month is 0-indexed
    // Use default timezone to ensure consistency between input and output
    // eslint-disable-next-line no-restricted-syntax
    return dayjs(new Date(year, month - 1, day)).format('YYYY-MM-DD');
  }
  return '';
};

export const protoDateToGraphqlDate = (
  nullableDate: infinitusai.be.IDate | null | undefined
): Required<GqlDate> | null => {
  if (!nullableDate) {
    return null;
  }
  if (nullableDate.year && nullableDate.month && nullableDate.day) {
    const { year, month, day } = nullableDate;
    // Month is 0-indexed
    // Use default timezone to ensure consistency between input and output
    // eslint-disable-next-line no-restricted-syntax
    return {
      __typename: 'Date',
      day: day,
      month: month - 1,
      year: year,
    };
  }
  return null;
};

export const stringToProtoDate = (string: string): infinitusai.be.Date | null => {
  const dateRegexp = new RegExp('(\\d{4})-(\\d{2})-(\\d{2})');
  const dateParser = dateRegexp.exec(string);
  if (dateParser && dateParser.length === 4) {
    return infinitusai.be.Date.create({
      year: parseInt(dateParser[1]),
      month: parseInt(dateParser[2]),
      day: parseInt(dateParser[3]),
    });
  } else {
    return null;
  }
};

export const castPossibleLongToNumber = (
  possibleLong: Long.Long | number | null | undefined
): number => {
  if (possibleLong === 0) return 0;
  if (!possibleLong) return NaN;
  if (Long.isLong(possibleLong)) return (possibleLong as Long).toNumber();
  return possibleLong as number;
};

export function protoTaskStateToTaskState(taskState: infinitusai.be.TaskState): TaskState {
  switch (taskState) {
    case infinitusai.be.TaskState.TASK_STATE_UNKNOWN:
      return TaskState.TASK_STATE_UNKNOWN;
    case infinitusai.be.TaskState.TASK_STATE_CREATED:
      return TaskState.TASK_STATE_CREATED;
    case infinitusai.be.TaskState.TASK_STATE_CALL_CONNECTED:
      return TaskState.TASK_STATE_CALL_CONNECTED;
    case infinitusai.be.TaskState.TASK_STATE_CALL_DISCONNECTED:
      return TaskState.TASK_STATE_CALL_DISCONNECTED;
    case infinitusai.be.TaskState.TASK_STATE_FAILED:
      return TaskState.TASK_STATE_FAILED;
    case infinitusai.be.TaskState.TASK_STATE_REVIEWED:
      return TaskState.TASK_STATE_REVIEWED;
    case infinitusai.be.TaskState.TASK_STATE_IN_REVIEW:
      return TaskState.TASK_STATE_IN_REVIEW;
    case infinitusai.be.TaskState.TASK_STATE_READY_FOR_REVIEW:
      return TaskState.TASK_STATE_READY_FOR_REVIEW;
    case infinitusai.be.TaskState.TASK_STATE_READY_FOR_SELF_REVIEW:
      return TaskState.TASK_STATE_READY_FOR_SELF_REVIEW;
    case infinitusai.be.TaskState.TASK_STATE_IN_PROGRESS:
      return TaskState.TASK_STATE_IN_PROGRESS;
    case infinitusai.be.TaskState.TASK_STATE_PARTIAL_SUCCESS:
      return TaskState.TASK_STATE_PARTIAL_SUCCESS;
    case infinitusai.be.TaskState.TASK_STATE_PENDING_AUTO_REQUEUE:
      return TaskState.TASK_STATE_PENDING_AUTO_REQUEUE;
    case infinitusai.be.TaskState.TASK_STATE_PENDING_EXTERNAL_VENDOR_API_CALL:
      return TaskState.TASK_STATE_PENDING_EXTERNAL_VENDOR_API_CALL;
    case infinitusai.be.TaskState.TASK_STATE_AUTO_CLOSED:
      return TaskState.TASK_STATE_AUTO_CLOSED;
    default:
      return TaskState.TASK_STATE_UNKNOWN;
  }
}
