import List from '@mui/material/List';
import useLocalStorage from '@rehooks/local-storage';
import { useMemo } from 'react';
import { useMatch } from 'react-router-dom';

import { useAuth } from '@infinitus/auth';
import { IconNames } from '@infinitus/components/Icon';
import useConditionalSx from '@infinitus/hooks/useConditionalSx';
import useGetIdsFromUrl from '@infinitus/hooks/useGetIdsFromUrl';
import { UUID } from '@infinitus/types';
import { OperatorPortalFeature } from 'generated/gql/graphql';
import useEnabledFeatures from 'hooks/useEnabledFeatures';
import { DEFAULT_ORG_NAME, DEFAULT_ORG_ID } from 'utils/localStorage';

import { expandedNavSx, baseNavSx } from './AppNavStyles';
import { useNavState } from './navState';
import NavItem from './subcomponents/NavItem';
import { NavItemList } from './types';

const makeOpsRoute = (orgName: string, slug?: string) => {
  return `operator/${orgName}${slug ? `/${slug}` : ''}`;
};
const makeAdminRoute = (slug: string) => {
  return `admin/${slug}`;
};

interface RouteProps {
  isAdmin: boolean;
  isPilotOrg: boolean;
  orgName: string;
  showTasksForNonAdmins: boolean;
  showUnifiedQueue: boolean;
}

const getRoutes = ({
  isAdmin,
  orgName,
  showUnifiedQueue,
  isPilotOrg,
  showTasksForNonAdmins,
}: RouteProps): NavItemList => {
  var navArr: any[] = [];
  if (showUnifiedQueue) {
    navArr = [
      {
        href: '/queue',
        iconName: IconNames.JOIN_INNER,
        id: 'queue',
        title: 'Unified queue',
      },
    ];
  } else {
    // Show org queue
    navArr = [
      {
        href: makeOpsRoute(orgName, 'ready'),
        id: 'ready',
        iconName: IconNames.RING_VOLUME,
        title: 'Ready page',
      },
    ];
  }
  const operatorRouteTaskChildren = [
    {
      href: makeOpsRoute(
        orgName,
        'task-list/all?state=CALL+CONNECTED&state=CALL+DISCONNECTED&state=READY+FOR+CALL&state=READY+FOR+REVIEW&state=IN+REVIEW&state=READY+FOR+SELF+REVIEW'
      ),
      iconName: IconNames.ASSIGNMENT,
      id: 'all',
      title: 'All tasks',
    },
    {
      href: makeOpsRoute(orgName, 'task-list/pending'),
      id: 'pending',
      iconName: IconNames.PENDING_ACTIONS,
      title: 'Tasks pending',
    },
    {
      href: makeOpsRoute(orgName, 'task-list/in-progress'),
      iconName: IconNames.CONTENT_PASTE_GO,
      id: 'in-progress',
      title: 'Tasks in progress',
    },
    {
      href: makeOpsRoute(orgName, 'task-list/awaiting-review'),
      id: 'awaiting-review',
      iconName: IconNames.CONTENT_PASTE_SEARCH,
      title: 'Tasks awaiting review',
    },
    {
      href: makeOpsRoute(orgName, 'task-list/in-review'),
      iconName: IconNames.INVENTORY,
      iconOutlined: true,
      id: 'in-review',
      title: 'Tasks in review',
    },
  ];

  const taskChildren =
    isAdmin && orgName === 'infinitus_test_pool'
      ? [
          ...operatorRouteTaskChildren,
          {
            href: makeOpsRoute(orgName, 'task-list/tagged-tasks'),
            iconName: IconNames.MISCELLANEOUS_SERVICES,
            iconOutlined: true,
            id: 'tagged-tasks',
            title: 'Tagged tasks',
          },
        ]
      : operatorRouteTaskChildren;

  if (isAdmin || isPilotOrg || showTasksForNonAdmins) {
    navArr.push({
      id: 'task-list',
      iconName: IconNames.ASSIGNMENT,
      iconOutlined: true,
      title: 'Tasks',
      children: taskChildren,
    });
  }

  if (isAdmin) {
    navArr.push(
      {
        id: 'training-parent',
        iconName: IconNames.SCHOOL,
        iconOutlined: true,
        title: 'Training',
        children: [
          {
            href: makeOpsRoute(orgName, 'training'),
            iconName: IconNames.SCHOOL,
            id: 'training',
            title: 'Training',
          },
          {
            id: 'training-scripts',
            iconName: IconNames.ACCOUNT_TREE,
            href: makeAdminRoute('training-scripts?taskType=TASK_TYPE_FULL_BI'),
            title: 'Training scripts',
          },
        ],
      },
      {
        id: 'queue-management-parent',
        iconName: IconNames.DASHBOARD,
        iconOutlined: true,
        title: 'Queue management',
        children: [
          {
            id: 'queueManager',
            href: makeAdminRoute('queueManager'),
            iconName: IconNames.DASHBOARD,
            title: 'Queue management',
          },
          {
            id: 'active-calls',
            href: makeAdminRoute('active-calls'),
            iconName: IconNames.WIFI_CALLING,
            title: 'Active calls',
          },
          {
            id: 'queues',
            href: makeAdminRoute('queues'),
            iconName: IconNames.LIST,
            title: 'Queue editor',
          },
          {
            id: 'unifiedQueueManager',
            href: makeAdminRoute('unifiedQueueManager'),
            iconName: IconNames.DASHBOARD,
            title: 'Unified queue management',
          },
          {
            id: 'skillDefinitions',
            href: makeAdminRoute('skillDefinitions'),
            iconName: IconNames.PSYCHOLOGY,
            title: 'Skill editor',
          },
          {
            id: 'userSkills',
            href: makeAdminRoute('userSkills'),
            iconName: IconNames.PERSON_SEARCH,
            title: 'User skills',
          },
        ],
      },
      {
        id: 'customer-parent',
        iconName: IconNames.WORK_OUTLINE,
        iconOutlined: true,
        title: 'Customers',
        children: [
          {
            id: 'customers',
            href: makeAdminRoute('customers'),
            iconName: IconNames.WORK,
            title: 'Customers',
          },
          {
            id: 'orgs',
            href: makeAdminRoute('orgs'),
            iconName: IconNames.PEOPLE,
            title: 'Orgs',
          },
        ],
      },
      {
        id: 'payers-parent',
        iconName: IconNames.SAVINGS,
        iconOutlined: true,
        title: 'Payers',
        children: [
          {
            id: 'payers',
            href: makeAdminRoute('payers'),
            iconName: IconNames.SAVINGS,
            title: 'Payers',
          },
          {
            id: 'phoneNumbers',
            href: makeAdminRoute('phoneNumbers'),
            iconName: IconNames.DIALPAD,
            title: 'Phone numbers',
          },
        ],
      },
      {
        id: 'user-management-parent',
        iconName: IconNames.LOCK_PERSON,
        iconOutlined: true,
        title: 'User management',
        children: [
          {
            id: 'auth',
            href: makeAdminRoute('auth'),
            iconName: IconNames.LOCK_PERSON,
            title: 'Auth domains',
          },
          {
            id: 'users',
            href: makeAdminRoute('users'),
            iconName: IconNames.PERSON,
            title: 'Users',
          },
        ],
      },
      {
        id: 'convo',
        iconName: IconNames.FORUM,
        href: makeAdminRoute(orgName ? `convo/${orgName}` : `convo`),
        title: 'Convo builder',
      },
      {
        id: 'library',
        iconName: IconNames.COLLECTIONS_BOOKMARK,
        iconOutlined: true,
        title: 'Library',
        children: [
          {
            id: 'outputs',
            iconName: IconNames.OUTPUT,
            href: makeAdminRoute('library/outputs'),
            title: 'Outputs',
          },
          {
            id: 'autocomplete',
            href: makeAdminRoute('library/autocomplete'),
            iconName: IconNames.LOCATION_SEARCHING,
            title: 'Autocomplete options',
          },
          {
            id: 'conditions',
            iconName: IconNames.FORK_RIGHT,
            href: makeAdminRoute(orgName ? `library/conditions/${orgName}` : 'library/conditions'),
            title: 'Conditions',
          },
          {
            id: 'products',
            href: makeAdminRoute('library/products'),
            iconName: IconNames.VACCINES,
            title: 'Products',
          },
          {
            id: 'integration-testing',
            href: makeAdminRoute('library/integration-testing'),
            iconName: IconNames.MISCELLANEOUS_SERVICES,
            title: 'Self Service Integration Testing Tags',
          },
          {
            id: 'cpt-library',
            href: makeAdminRoute('library/cpt'),
            iconName: IconNames.MEDICATION_LIQUID,
            title: 'CPT Information Library',
          },
          {
            id: 'ebv',
            href: makeAdminRoute('library/ebv'),
            iconName: IconNames.API,
            title: 'EBV Sources',
          },
        ],
      },
      {
        id: 'incidents-parent',
        iconName: IconNames.REPORT,
        iconOutlined: true,
        title: 'Incidents',
        children: [
          {
            id: 'incidents',
            href: makeAdminRoute('incidents'),
            iconName: IconNames.REPORT,
            title: 'Incident Reports',
          },
          {
            id: 'incidentConfig',
            href: makeAdminRoute('incidentConfig?sort_dir=asc&sort=category'),
            iconName: IconNames.SETTINGS,
            title: 'Incident types',
          },
          {
            id: 'issues',
            href: makeAdminRoute('issues'),
            iconName: IconNames.FLAG,
            title: 'Issues',
          },
        ],
      },
      {
        id: 'notifications',
        href: makeAdminRoute('notifications'),
        iconName: IconNames.EDIT_NOTIFICATIONS,
        title: 'Notification builder',
      },
      {
        id: 'experiments',
        href: makeAdminRoute('experiments'),
        iconName: IconNames.SCIENCE,
        title: 'Experiments',
      }
    );
  } else {
    // Not admin
    navArr.push({
      href: makeOpsRoute(orgName, 'training'),
      iconName: IconNames.SCHOOL,
      id: 'training',
      title: 'Training',
    });
  }

  return navArr;
};

interface Props {
  isExpanded: boolean;
}

function AppNav({ isExpanded }: Props) {
  const adminRouteMatch = useMatch('/admin/:splat/*');
  const libraryRouteMatch = useMatch('/admin/library/:splat/*');
  const opsRouteMatch = useMatch('/operator/:orgName/:splat/*');
  const queuePageRouteMatch = useMatch('/queue');
  const taskListMatch = useMatch('/operator/:orgName/:task-list/:splat/*');
  const { hasAdminAccess, getOrgInfo } = useAuth();
  const isAdmin = hasAdminAccess();
  const { orgName: orgNameFromParam, orgUuid: orgUuidFromParam } = useGetIdsFromUrl();

  const [lastOrgName] = useLocalStorage<string>('lastOrgName', DEFAULT_ORG_NAME);
  const [lastOrgId] = useLocalStorage<UUID>('lastOrgId', DEFAULT_ORG_ID);

  // Prefer to use orgInfo from params. Then look up orgInfo from localStorage.
  const orgName = orgNameFromParam || lastOrgName;
  const orgUuid = orgUuidFromParam || lastOrgId;

  const orgInfo = getOrgInfo(orgName);

  // Technically orgs badged as "Test" should be subset of pilot orgs but are labelled
  // as their own category in the customer portal UI.
  // https://infinitusai.atlassian.net/browse/IST-651?focusedCommentId=17709
  const isPilotOrg = !orgInfo.isInternal && !orgInfo.isLive;

  const { isFeatureEnabled } = useEnabledFeatures(orgUuid);

  const showTasksForNonAdmins = isFeatureEnabled(OperatorPortalFeature.SHOW_TASKS_FOR_NON_ADMINS);

  const showUnifiedQueue = useMemo(() => {
    if (queuePageRouteMatch) return true;
    if (!isFeatureEnabled(OperatorPortalFeature.UNIFIED_QUEUE)) return false;
    if (adminRouteMatch && hasAdminAccess()) return true;
    if (opsRouteMatch && !!orgName) return true;
    return false;
  }, [
    queuePageRouteMatch,
    adminRouteMatch,
    opsRouteMatch,
    hasAdminAccess,
    orgName,
    isFeatureEnabled,
  ]);

  const navSx = useConditionalSx(baseNavSx, [isExpanded, expandedNavSx]);

  const { navState, setNavItemState } = useNavState();

  const navList = useMemo(
    () =>
      getRoutes({
        isAdmin,
        orgName,
        showUnifiedQueue,
        isPilotOrg,
        showTasksForNonAdmins,
      }),
    [orgName, showUnifiedQueue, isAdmin, isPilotOrg, showTasksForNonAdmins]
  );

  let match;
  if (libraryRouteMatch) {
    match = libraryRouteMatch;
  } else if (adminRouteMatch) {
    // This will break if/when we add more roles
    match = adminRouteMatch;
  } else if (taskListMatch) {
    match = taskListMatch;
  } else if (opsRouteMatch) {
    match = opsRouteMatch;
    const orgName = match?.params?.orgName;
    if (!orgName) return null;
  } else if (isAdmin) {
    match = adminRouteMatch;
  }
  const splat = match?.params?.splat;

  return (
    <>
      <List sx={navSx}>
        {navList.map((item) => {
          return (
            <NavItem
              isExpanded={isExpanded}
              isOpen={navState.has(item.id)}
              item={item}
              key={item.id}
              onOpenClick={setNavItemState}
              splat={splat}
            />
          );
        })}
      </List>
    </>
  );
}

export default AppNav;
