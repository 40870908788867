import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SxProps,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { MouseEvent, useState } from 'react';

import { Button, LoaderButton } from '@infinitus/components/Button';
import ThemeColorTypes from '@infinitus/types/theme-color-types';
import VisualVariant from '@infinitus/types/visual-variant-types';

import { logModalEvent, ModalEvent } from './ModalLogs';

export enum ModalCloseReason {
  BACKDROP_CLICK = 'backdropClick',
  CANCEL_CLICK = 'cancelClick',
  ESCAPE_KEY_DOWN = 'escapeKeyDown',
}

export interface ModalProps {
  additionalActions?: React.ReactNode;
  autoFocusConfirm?: boolean;
  boldTitle?: boolean;
  cancelable?: boolean; // TODO: delete this unused prop
  cancelButtonLabel?: string;
  children?: React.ReactNode;
  confirmButtonLabel?: string;
  confirmButtonSx?: SxProps;
  contentSx?: SxProps;
  disableBackdropClose?: boolean;
  disableClose?: boolean;
  disableOkButton?: boolean;
  // Created with @mui/utils/useId
  formId?: string;
  fullScreen?: boolean;
  fullWidth?: boolean;
  hideBackdrop?: boolean;
  hideCancelButton?: boolean;
  hideConfirmButton?: boolean;
  hideTitle?: boolean;
  isCancelDisabled?: boolean;
  isOpen: boolean;
  isSubmitDangerous?: boolean;
  isSubmitDisabled?: boolean;
  isSubmitting?: boolean;
  logFunction?: (logData: ModalEvent) => void;
  modalName?: string;
  onBackdropClose?: Function; // If different behavior is desired for closing via the backdrop or escape key
  onCancel: Function;
  onConfirm: Function;
  scrollContent?: boolean;
  size?: 'sm' | 'md' | 'lg';
  sx?: SxProps;
  // Title must be either a string or the <Typography> component
  title: Omit<string, '' | ' '> | React.ReactElement<TypographyProps>;
  titleIcon?: React.ReactNode;
  zIndex?: number;
}

export function Modal({
  additionalActions,
  autoFocusConfirm,
  boldTitle = false,
  children,
  cancelButtonLabel = 'Cancel',
  confirmButtonLabel = 'OK',
  confirmButtonSx,
  contentSx,
  disableBackdropClose = false,
  disableClose,
  disableOkButton = false,
  formId,
  fullScreen = false,
  fullWidth = true,
  hideBackdrop = false,
  hideCancelButton = false,
  hideConfirmButton = false,
  hideTitle = false,
  isCancelDisabled = false,
  isOpen,
  isSubmitDangerous = false,
  isSubmitDisabled = false,
  isSubmitting,
  logFunction = logModalEvent,
  modalName = 'Modal',
  onCancel,
  onBackdropClose,
  onConfirm,
  scrollContent = true,
  size = 'sm',
  sx,
  title,
  titleIcon,
  zIndex,
}: ModalProps) {
  const theme = useTheme();
  const [isConfirming, setIsConfirming] = useState(false);
  if (!!formId && !formId.startsWith('mui-')) {
    console.error('A `formId` must be created with `@mui/utils/useId`');
  }

  const buttonDisabled =
    isSubmitDisabled || isSubmitting || isConfirming || disableOkButton || false;

  function handleConfirm() {
    const value = onConfirm();
    logFunction({
      eventName: 'confirm',
      label: title,
      componentName: modalName,
    });
    if (value instanceof Promise) {
      setIsConfirming(true);
      value.finally(() => {
        setIsConfirming(false);
      });
      logFunction({
        eventName: 'confirmed',
        label: title,
        componentName: modalName,
      });
    }
  }

  function handleCancel(_evt: object, reason?: ModalCloseReason) {
    if (disableClose) return;
    if (reason === ModalCloseReason.BACKDROP_CLICK && disableBackdropClose) return;

    if (onBackdropClose) {
      onBackdropClose();
    } else {
      onCancel(reason);
    }

    // Disable logging when a controlled component changes the state via the `isOpen` prop
    if (reason) {
      logFunction({
        eventName: 'cancel',
        label: title,
        reason: reason,
        componentName: modalName,
      });
    }
  }

  function handleButtonCancel(_evt: MouseEvent) {
    if (disableClose) return;
    const reason = ModalCloseReason.CANCEL_CLICK;
    onCancel(reason);
    logFunction({
      eventName: 'cancel',
      label: title,
      reason: reason,
      componentName: modalName,
    });
  }

  return (
    <Dialog
      aria-labelledby={`modal-${title}`}
      disableEscapeKeyDown={disableClose}
      fullScreen={fullScreen}
      fullWidth={fullWidth}
      hideBackdrop={hideBackdrop}
      maxWidth={fullScreen ? false : size}
      // Closing with the button isn't included in
      // @mui's Dialog close reasons, but
      // we need 'cancelClick' and these for consistent logs
      onClose={(e, reason: 'backdropClick' | 'escapeKeyDown') => {
        handleCancel(e, reason as ModalCloseReason);
      }}
      open={isOpen}
      PaperProps={{
        elevation: 3,
        sx: {
          maxHeight: fullScreen ? undefined : 'calc(100vh - 64px)',
          overflowY: 'hidden',
          ...sx,
        },
      }}
      scroll={scrollContent ? 'paper' : 'body'}
      sx={zIndex ? { zIndex } : {}}
    >
      <DialogTitle
        id={`modal-${title}`}
        sx={{
          display: hideTitle ? 'none' : 'block',
          fontWeight: boldTitle ? 'bold' : 500,
          paddingBlock: theme.spacing(1.5),
          paddingInline: theme.spacing(2),
        }}
      >
        {titleIcon}
        {title}
      </DialogTitle>
      <DialogContent
        sx={{
          paddingBlock: theme.spacing(1),
          paddingInline: theme.spacing(2),
          ...contentSx,
        }}
      >
        {children}
      </DialogContent>
      <DialogActions
        sx={{
          paddingBlock: theme.spacing(1.5),
          paddingInline: theme.spacing(2),
        }}
      >
        {additionalActions}
        {!hideCancelButton && (
          <Button
            disabled={isCancelDisabled || isSubmitting || isConfirming}
            disableLogs={true}
            onClick={handleButtonCancel}
            text={cancelButtonLabel}
            variant={VisualVariant.OUTLINED}
          />
        )}
        {!hideConfirmButton && (
          <LoaderButton
            color={isSubmitDangerous ? ThemeColorTypes.ERROR : ThemeColorTypes.PRIMARY}
            disableLogs={true}
            formId={formId}
            isAutofocused={autoFocusConfirm}
            isDisabled={buttonDisabled}
            isLoading={isSubmitting || isConfirming}
            labelText={confirmButtonLabel}
            onClick={handleConfirm}
            {...(confirmButtonSx ? { sx: confirmButtonSx } : {})}
          />
        )}
      </DialogActions>
    </Dialog>
  );
}
