import { gql } from '@apollo/client';

export const StringValueFragment = gql`
  fragment StringValueFragment on StringValue {
    string
  }
`;

export const IntValueFragment = gql`
  fragment IntValueFragment on IntValue {
    int
  }
`;

export const BoolValueFragment = gql`
  fragment BoolValueFragment on BoolValue {
    bool
  }
`;

export const DateValueFragment = gql`
  fragment DateValueFragment on DateValue {
    date
  }
`;

export const MoneyValueFragment = gql`
  fragment MoneyValueFragment on MoneyValue {
    money
  }
`;

export const FloatValueFragment = gql`
  fragment FloatValueFragment on FloatValue {
    float
  }
`;

export const ObjectValueFragment = gql`
  ${StringValueFragment}
  ${IntValueFragment}
  ${BoolValueFragment}
  ${DateValueFragment}
  ${MoneyValueFragment}
  ${FloatValueFragment}
  fragment ObjectValueFragment on ObjectValue {
    object {
      name
      displayName
      value {
        ...StringValueFragment
        ...IntValueFragment
        ...BoolValueFragment
        ...DateValueFragment
        ...MoneyValueFragment
        ...FloatValueFragment
        ... on ArrayValue {
          values {
            name
            displayName
            value {
              ...StringValueFragment
              ...IntValueFragment
              ...BoolValueFragment
              ...DateValueFragment
              ...MoneyValueFragment
              ...FloatValueFragment
            }
          }
        }
      }
    }
  }
`;

export const ArrayValueFragment = gql`
  ${StringValueFragment}
  ${IntValueFragment}
  ${BoolValueFragment}
  ${DateValueFragment}
  ${ObjectValueFragment}
  ${MoneyValueFragment}
  ${FloatValueFragment}
  fragment ArrayValueFragment on ArrayValue {
    values {
      name
      displayName
      value {
        ...StringValueFragment
        ...IntValueFragment
        ...BoolValueFragment
        ...DateValueFragment
        ...ObjectValueFragment
        ...MoneyValueFragment
        ...FloatValueFragment
      }
    }
  }
`;

export const FlexibleInputFragment = gql`
  ${StringValueFragment}
  ${IntValueFragment}
  ${BoolValueFragment}
  ${DateValueFragment}
  ${MoneyValueFragment}
  ${FloatValueFragment}
  ${ObjectValueFragment}
  ${ArrayValueFragment}
  fragment FlexibleInputFragment on FlexibleTaskInput {
    name
    displayName
    value {
      ...StringValueFragment
      ...IntValueFragment
      ...BoolValueFragment
      ...DateValueFragment
      ...MoneyValueFragment
      ...FloatValueFragment
      ...ObjectValueFragment
      ...ArrayValueFragment
    }
  }
`;

export const InputConfigFragment = gql`
  fragment InputConfigFragment on TaskInputConfig {
    inputType
    path
    displayName
    required
    allowedValues
  }
`;
