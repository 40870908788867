import { GetIncidentTypes_incidentTypes as IncidentType } from 'types/gqlMapping';

export enum ReportIncidentModalMode {
  INCIDENT = 'INCIDENT',
  OLAF = 'OLAF',
  TECHNICAL_ISSUE = 'TECHNICAL_ISSUE',
}

export type OnChangeIncidentCallbackType =
  | {
      name: 'incidentItem';
      value?: IncidentType;
    }
  | {
      name: 'incidentDescription';
      value: string;
    };
