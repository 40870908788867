import { DefaultContext } from '@apollo/client';

import { REQUEST_ID_HEADER_FIELD } from '@infinitus/apollo/headers';
import { logEventToBigQuery } from '@infinitus/hooks/useLogBuffer';
import { getRequestId } from '@infinitus/utils/api';

// CorrelationContext will be intercepted and extracted by the LogForwarder. Therefore,
// the trace context should be remain the first message in the log for quick extraction.
export type CorrelationContext = {
  // requestId should be 20 Characters
  correlationContextRequestId: string;
};

/**
 * maybeExtractCorrelatedRequestId helps extract a request id from the windows console
 * log methods. A CorrelationContext, a context holding the request id, will be the first
 * message in a console log method. Therefore, you should only extract the first
 * argument passed to the console log methods.
 *
 * @param {any} context first message in a console log/error/etc method. The method
 * will only return a request id if context matches the CorrelationContext interface
 * @returns {(string|undefined)} string if context is a CorrelationContext with a
 * correlationContextRequestId field, undefined otherwise
 */
export function maybeExtractCorrelatedRequestId(context: any) {
  if (typeof context !== 'object' || context === null) {
    return undefined;
  }

  if (
    typeof context.correlationContextRequestId === 'string' &&
    context.correlationContextRequestId
  ) {
    return context.correlationContextRequestId as string;
  }

  return undefined;
}

export interface CorrelateLogOptions {
  // Optionally provide a requestId, otherwise one will be generated
  requestId?: string;
}

export function startCorrelatingLogs(options?: CorrelateLogOptions) {
  const requestId = options?.requestId ?? getRequestId();
  const correlationContext: CorrelationContext = {
    correlationContextRequestId: requestId,
  };

  const correlatedLog = (...messagesArray: any) => {
    console.log(correlationContext, ...messagesArray);
  };

  const correlatedWarn = (...messagesArray: any) => {
    console.warn(correlationContext, ...messagesArray);
  };

  const correlatedError = (...messagesArray: any) => {
    console.error(correlationContext, ...messagesArray);
  };

  const correlatedLogEventToBigQuery: typeof logEventToBigQuery = (bigQueryEvent) => {
    return logEventToBigQuery({ requestId, ...bigQueryEvent });
  };

  return {
    requestId,
    correlatedLog,
    correlatedWarn,
    correlatedError,
    correlatedLogEventToBigQuery,
  };
}

// addRequestIdToContext mutates the given context with the provided request id
// and returns the mutated context. This utility function is useful for correlating
// logs with graphql operations. The requestId will then be accessed in the apollo links
export function addRequestIdToContext(context: DefaultContext, requestId: string) {
  if (!context.headers) {
    context.headers = {};
  }
  context.headers[REQUEST_ID_HEADER_FIELD] = requestId;

  return context;
}
