import { gql, useQuery } from '@apollo/client';
import { ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { useTheme, alpha } from '@mui/material/styles';
import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';

import { useAuth } from '@infinitus/auth';
import { LoaderButton } from '@infinitus/components/Button';
import { GreetingItem } from '@infinitus/components/GreetingItem';
import { useSnackbar } from '@infinitus/hooks/useCustomSnackbar';
import { useMyRecordedGreetingsEditor } from '@infinitus/hooks/useRecordedGreetingHooks';
import { Choice } from '@infinitus/types/ui-input-types';
import VisualVariant from '@infinitus/types/visual-variant-types';
import Autocomplete from 'components/AutocompleteV5';
import MuiV5Theme from 'styles/MuiV5Theme';
import { ListUserOrgs } from 'types/gqlMapping';

interface Props {}

const LIST_USER_ORGS = gql`
  query ListUserOrgs {
    listUserOrgs {
      org {
        id
        name
      }
    }
  }
`;

const useClasses = () => {
  const theme = useTheme();
  return {
    root: {
      padding: theme.spacing(2),
      background: theme.palette.background.v4Paper,
    },
    greetingContainer: {
      padding: theme.spacing(1),
      background: alpha(theme.palette.background.accented || 'grey', 0.3),
      borderRadius: 6,
    },
    orgDropDown: {
      minWidth: 140,
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(3),
    },
    spinner: {
      color: 'white',
      verticalAlign: 'text-bottom',
    },
  };
};

const RecordedGreetings: React.FC<Props> = () => {
  const classes = useClasses();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedOrgId, setSelectedOrgId] = useState<string>();
  const { user } = useAuth();
  const {
    greetingRecordings,
    updatedGreetings,
    isUploadingGreeting,
    handleBlobAvailable,
    handleUploadRecordings,
    recordedGreetings: greetings,
  } = useMyRecordedGreetingsEditor({ user, orgUuid: selectedOrgId || '' });

  const { data } = useQuery<ListUserOrgs>(LIST_USER_ORGS);
  const availableOrgs = data?.listUserOrgs
    ? sortBy(data.listUserOrgs, function (o) {
        return o.org?.name || '';
      })
    : undefined;
  const selectedOrgName = React.useMemo(() => {
    if (availableOrgs && selectedOrgId) {
      return availableOrgs.filter((org) => org.org.id === selectedOrgId)[0].org.name;
    }
    return '';
  }, [selectedOrgId, availableOrgs]);
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    setSelectedOrgId(urlSearchParams.get('org') as string);
  }, [setSelectedOrgId]);

  const handleFormPosted = async () => {
    const res = await handleUploadRecordings();
    if (res.errorMsg) {
      console.error(res.errorMsg);
      enqueueSnackbar(res.errorMsg, { variant: 'error' });
    }
    if (res.failedUploads.length > 0) {
      const msg = `Failed to upload recording(s) for '${res.failedUploads.join(', ')}'`;
      console.error(msg);
      enqueueSnackbar(msg, { variant: 'error' });
    }
    if (res.successfulUploads.length > 0) {
      enqueueSnackbar(`Uploaded recording(s) for '${res.successfulUploads.join(', ')}'`, {
        variant: 'success',
      });
    }
  };

  const handleOrgChanged = (optVal: any) => {
    if (optVal as string) {
      const urlSearchParams = new URLSearchParams();
      urlSearchParams.set('org', optVal as string);
      window.location.search = urlSearchParams.toString();
    }
  };

  const choiceFromOrg = (org: {
    __typename: 'OrgMembership';
    org: {
      __typename: 'Org';
      id: string;
      name: string;
    };
  }): Choice<string, string> => {
    return { label: org.org.name, value: org.org.id };
  };

  return (
    <Container maxWidth="xl">
      <Paper sx={classes.root}>
        <Typography variant="h5">Record your greetings</Typography>
        <Typography gutterBottom>
          Your personal greetings will be played automatically when you are routed into a live call.
        </Typography>
        <FormControl sx={classes.orgDropDown}>
          {availableOrgs && (
            <Autocomplete
              choices={availableOrgs.map((v) => choiceFromOrg(v))}
              InputLabelProps={{
                shrink: availableOrgs ? true : false,
              }}
              label={availableOrgs ? 'Organization' : 'Loading orgs...'}
              onChange={handleOrgChanged}
              value={selectedOrgName}
              variant="standard"
            />
          )}
        </FormControl>
        <Grid direction="column" spacing={2}>
          {selectedOrgId ? (
            greetings.map((greeting, i) => (
              <Grid key={greeting.greetingName} sx={{ margin: 1 }} xs={12}>
                <div style={classes.greetingContainer}>
                  <ThemeProvider theme={MuiV5Theme}>
                    <GreetingItem
                      existingAudioDataBlob={greetingRecordings.get(greeting.greetingName)}
                      greeting={greeting}
                      hasUnsavedChanges={updatedGreetings.has(greeting.greetingName)}
                      index={i}
                      onWaveRecordingAvailable={(name: string, blob: Blob | null) => {
                        handleBlobAvailable(name, blob);
                      }}
                    />
                  </ThemeProvider>
                </div>
              </Grid>
            ))
          ) : (
            <Grid>
              <Typography>Please select an org to record your greetings.</Typography>
            </Grid>
          )}
        </Grid>
        <Box mt={2}>
          <LoaderButton
            isDisabled={isUploadingGreeting || !selectedOrgId || updatedGreetings.size === 0}
            isLoading={isUploadingGreeting}
            labelText="Save recordings"
            onClick={handleFormPosted}
            variant={VisualVariant.CONTAINED}
          />
        </Box>
      </Paper>
    </Container>
  );
};

export default RecordedGreetings;
