import { createContext } from 'react';

import { initialAuthState, AuthContextInterface } from './types';

export const stub = (): never => {
  throw new Error('You forgot to wrap your component in <AuthProvider>.');
};

const initialContext = {
  ...initialAuthState,
  signInWithGoogle: stub,
  signInWithCustomToken: stub,
  isActive: stub,
  hasAdminAccess: stub,
  hasUserAccess: stub,
  hasOrgOperatorAccess: stub,
  hasTaskReviewAuthorization: stub,
  getOrgNamesWithRoles: stub,
  getOrgInfo: stub,
  getOrgNameFromUuid: stub,
  getSelectiveReviewStatus: stub,
  signOut: stub,
  onSignOut: stub,
};

/**
 * The Auth Context
 */
const AuthContext = createContext<AuthContextInterface>(initialContext);

export default AuthContext;
