import { graphql } from '@infinitus/generated/frontend-common';

// Only used in OperatorPortal. FastTrack uses AuthProvider from frontend package.
// However unclear if there is indirect dependency.
export const CurrentUserDocument = graphql(/* GraphQL */ `
  query CurrentUserQuery {
    currentUser {
      id
      role
      status
      orgs {
        orgRole
        org {
          id
          name
          displayName
          imageUrl
          isInternal
          isLive
          enableTesting
        }
      }
      operatorInfo {
        canReview
        selectiveReviewEnabled
        selectiveReviewOrgEligibility
      }
    }
  }
`);
