import { infinitusai } from '@infinitus/proto/pbjs';
import { ActiveRequeueReason } from '@infinitus/utils/constants';
import { getTaskTypeDisplayName } from '@infinitus/utils/displayNames';
import { HumanIntroReason, TaskFailReason, TaskStateTransition } from 'generated/gql/graphql';
import {
  ApiFeature,
  AuthProvider,
  CallOutputFieldType,
  CallPhase,
  CustomerPortalFeature,
  DoNotCallReason,
  IssueStatus,
  OperatorOfflineReason,
  OperatorPortalFeature,
  OrgRole,
  PhoneNumberType,
  PolicyInfo_OtherInsuranceStatus,
  ProductInfo_DaysSupply,
  ProviderInfo_NetworkStatus,
  RequeueReason,
  TaskState,
  TaskType,
  UserInviteStatus,
  UserRole,
  UserStatus,
} from 'generated/gql/graphql';
import { UUID } from 'types';

export const GENERIC_FORM_VALIDATION_ERROR_MESSAGE = `To submit form, fix field errors`;

// DisplayNameMap enforces all TEnum keys are used as keys in the map.
// note: `extends string | number` is a hack because there is no generic enum type to extend
type DisplayNameMap<TEnum extends string | number> = Record<TEnum, string>;

// Used in QM dashboard chart
export const CallStateDisplayNames: DisplayNameMap<infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity> =
  {
    [infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity.CONTROL]: 'Controlling',
    [infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity.OBSERVE]: 'Observing',
    [infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity.REVIEW]: 'Reviewing',
    [infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity.SELF_REVIEW]: 'Self-reviewing',
    [infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity.UNKNOWN]: 'In',
    [infinitusai.be.CallPageClientHeartbeat.CallPageOperatorActivity.VISIT]: 'Visiting',
  };

// These are used in the modal
// Note: these are indexed by the GQL enum, any use of proto enums will need to be converted
export const OperatorOfflineReasonDisplayNames: DisplayNameMap<OperatorOfflineReason> = {
  [OperatorOfflineReason.BREAK]: 'Break',
  [OperatorOfflineReason.DONE_TODAY]: 'Done for today',
  [OperatorOfflineReason.INACTIVE]: 'Inactive',
  [OperatorOfflineReason.LUNCH]: 'Lunch',
  [OperatorOfflineReason.MANAGER_MEETING]: 'Manager 1:1',
  [OperatorOfflineReason.MEETING]: 'Group meeting/huddle',
  [OperatorOfflineReason.NULL]: '',
  [OperatorOfflineReason.OTHER]: 'Other',
  [OperatorOfflineReason.PROJECT]: 'Project work',
  [OperatorOfflineReason.TRAINING]: 'Training',
  [OperatorOfflineReason.UNKNOWN]: '',
};

// We have an enum of offline reasons in both proto and GQL, so we need to convert
export const protoOfflineReasonToGQLEnum = (reason: infinitusai.be.OperatorOfflineReason) => {
  switch (reason) {
    case infinitusai.be.OperatorOfflineReason.OPERATOR_OFFLINE_REASON_BREAK:
      return OperatorOfflineReason.BREAK;
    case infinitusai.be.OperatorOfflineReason.OPERATOR_OFFLINE_REASON_DONE_TODAY:
      return OperatorOfflineReason.DONE_TODAY;
    case infinitusai.be.OperatorOfflineReason.OPERATOR_OFFLINE_REASON_INACTIVE:
      return OperatorOfflineReason.INACTIVE;
    case infinitusai.be.OperatorOfflineReason.OPERATOR_OFFLINE_REASON_LUNCH:
      return OperatorOfflineReason.LUNCH;
    case infinitusai.be.OperatorOfflineReason.OPERATOR_OFFLINE_REASON_MANAGER_MEETING:
      return OperatorOfflineReason.MANAGER_MEETING;
    case infinitusai.be.OperatorOfflineReason.OPERATOR_OFFLINE_REASON_MEETING:
      return OperatorOfflineReason.MEETING;
    case infinitusai.be.OperatorOfflineReason.OPERATOR_OFFLINE_REASON_NULL:
      return OperatorOfflineReason.NULL;
    case infinitusai.be.OperatorOfflineReason.OPERATOR_OFFLINE_REASON_OTHER:
      return OperatorOfflineReason.OTHER;
    case infinitusai.be.OperatorOfflineReason.OPERATOR_OFFLINE_REASON_PROJECT:
      return OperatorOfflineReason.PROJECT;
    case infinitusai.be.OperatorOfflineReason.OPERATOR_OFFLINE_REASON_TRAINING:
      return OperatorOfflineReason.TRAINING;
    default:
      return OperatorOfflineReason.UNKNOWN;
  }
};

// Returns a human-readable string representation of a UUID
export function getUuidDisplayName(uuid: UUID) {
  if (!/-/.test(uuid)) return 'unknown id';
  return uuid.split('-')[0];
}

// Returns a human-readable string representation of a User ID
export function getUserIdDisplayName(uid: string) {
  if (!uid) return 'unknown id';
  return uid.slice(-6);
}

export function getOperatorTrainerScriptDisplayName(scriptId: string) {
  return scriptId.replaceAll(/_/gi, ' ');
}

export function getUserRoleDisplayName(role?: UserRole) {
  switch (role) {
    case UserRole.ADMIN:
      return 'Admin';
    case UserRole.USER:
      return 'User';
    default:
      return 'Unknown';
  }
}

export function getOrgRoleDisplayName(role: OrgRole) {
  switch (role) {
    case OrgRole.OWNER:
      return 'Owner';
    case OrgRole.SUPERVISOR:
      return 'Supervisor';
    case OrgRole.MEMBER:
      return 'Member';
    case OrgRole.OPERATOR:
      return 'Operator';
    default:
      return 'Unknown';
  }
}

export function getIssueStatusDisplayName(status?: IssueStatus) {
  switch (status) {
    case IssueStatus.PENDING:
      return 'Pending';
    case IssueStatus.RESOLVED:
      return 'Resolved';
    case IssueStatus.IN_PROGRESS:
      return 'In Progress';
    case IssueStatus.NEW:
      return 'New';
    default:
      return 'Unknown';
  }
}

export function getUserStatusDisplayName(status?: UserStatus) {
  switch (status) {
    case UserStatus.PENDING:
      return 'Pending';
    case UserStatus.ACTIVE:
      return 'Active';
    case UserStatus.INACTIVE:
      return 'Inactive';
    case UserStatus.DEACTIVATED:
      return 'Deactivated';
    default:
      return 'Unknown';
  }
}

export const ActivityDisplayNames = {
  ACTIVE_CALLS_PAGE: 'Viewing calls',
  ADMIN_PORTAL: 'In Admin portal',
  CALL_PAGE: 'On call page',
  READY_PAGE_AVAILABLE: 'Available on ready page',
  READY_PAGE_IDLE: 'Idle on ready page',
  TASK_LIST_PAGE: 'Viewing task lists',
  TASK_PAGE: 'Viewing a task',
  // Unknown is for any page that isn't tracked explicitly, so the user can be considered working even though we don't know where or on what
  UNKNOWN_PAGE: 'Unknown',
} as const;
export interface GetActivityDisplayNameArgs {
  availability?: 'Available' | 'Unavailable';
  availableOnReadyPage?: boolean;
  pageType: infinitusai.be.HeartbeatMessageFromClient.PageType | null | undefined;
}
export function getActivityDisplayName({
  pageType,
  availableOnReadyPage,
  availability,
}: GetActivityDisplayNameArgs) {
  if (availability === 'Unavailable') return 'Unavailable';
  if (!pageType) return ActivityDisplayNames.UNKNOWN_PAGE;

  switch (pageType) {
    case infinitusai.be.HeartbeatMessageFromClient.PageType.ACTIVE_CALLS_PAGE:
      return ActivityDisplayNames.ACTIVE_CALLS_PAGE;
    case infinitusai.be.HeartbeatMessageFromClient.PageType.ADMIN_PORTAL:
      return ActivityDisplayNames.ADMIN_PORTAL;
    case infinitusai.be.HeartbeatMessageFromClient.PageType.CALL_PAGE:
      return ActivityDisplayNames.CALL_PAGE;
    case infinitusai.be.HeartbeatMessageFromClient.PageType.READY_PAGE:
      return !!availableOnReadyPage
        ? ActivityDisplayNames.READY_PAGE_AVAILABLE
        : ActivityDisplayNames.READY_PAGE_IDLE;
    case infinitusai.be.HeartbeatMessageFromClient.PageType.TASK_LIST_PAGE:
      return ActivityDisplayNames.TASK_LIST_PAGE;
    case infinitusai.be.HeartbeatMessageFromClient.PageType.TASK_PAGE:
      return ActivityDisplayNames.TASK_PAGE;
    default:
      return ActivityDisplayNames.UNKNOWN_PAGE;
  }
}

export function getInviteStatusDisplayName(status?: UserInviteStatus) {
  switch (status) {
    case UserInviteStatus.PENDING:
      return 'Pending';
    case UserInviteStatus.ACCEPTED:
      return 'Accepted';
    case UserInviteStatus.DECLINED:
      return 'Declined';
    case UserInviteStatus.CANCELLED:
      return 'Cancelled';
    default:
      return 'Unknown';
  }
}

export function getAuthProviderDisplayName(provider?: AuthProvider) {
  switch (provider) {
    case AuthProvider.GOOGLE:
      return 'Google';
    case AuthProvider.MICROSOFT:
      return 'Microsoft';
    default:
      return 'Unknown';
  }
}

export function getAuthProviderProtoDisplayName(provider?: infinitusai.auth.AuthProvider) {
  switch (provider) {
    case infinitusai.auth.AuthProvider.AUTH_PROVIDER_GOOGLE:
      return 'Google';
    case infinitusai.auth.AuthProvider.AUTH_PROVIDER_MICROSOFT:
      return 'Microsoft';
    case infinitusai.auth.AuthProvider.AUTH_PROVIDER_SAML:
      return 'SAML';
    case infinitusai.auth.AuthProvider.AUTH_PROVIDER_EMAIL:
      return 'Email (Magic Link)';
    case infinitusai.auth.AuthProvider.AUTH_PROVIDER_CUSTOM:
      return 'Custom (EHR)';
    default:
      return 'Unknown';
  }
}

export function getApiFeatureLabels(feature?: ApiFeature) {
  switch (feature) {
    case ApiFeature.TASK_RECORDINGS_API:
      return {
        displayName: 'Task Recordings API',
      };
    case ApiFeature.CANCEL_TASKS_API:
      return {
        displayName: 'Cancel Tasks API',
      };
    case ApiFeature.ENABLE_VONAGE_SDK:
      return {
        displayName: 'Enable Vonage SDK',
      };
    default:
      return {
        displayName: 'Unknown',
        hint: '',
      };
  }
}

export function getCustomerPortalFeatureLabels(feature?: CustomerPortalFeature) {
  // TODO: Add hints for each feature
  switch (feature) {
    case CustomerPortalFeature.PAYER_INTELLIGENCE:
      return {
        displayName: 'Payer Intelligence',
      };
    case CustomerPortalFeature.REPORTS:
      return {
        displayName: 'Reports',
      };
    case CustomerPortalFeature.CREATE_TASK:
      return {
        displayName: 'Create Task',
      };
    case CustomerPortalFeature.BILLING:
      return {
        displayName: 'Billing',
      };
    case CustomerPortalFeature.EXPOSE_PHI:
      return {
        displayName: 'Expose PHI',
      };
    case CustomerPortalFeature.ALL_TASKS_REQUIRE_REVIEW:
      return {
        displayName: 'All Tasks Require Review',
      };
    case CustomerPortalFeature.IMPORT_TASKS_CSV:
      return {
        displayName: 'Import Tasks via CSV',
      };
    case CustomerPortalFeature.BILLING_NOTES:
      return {
        displayName: 'Billing Notes',
      };
    case CustomerPortalFeature.IVR_AND_HOLD:
      return {
        displayName: 'IVR and Hold',
      };
    case CustomerPortalFeature.TASK_TABLE_TASK_TYPES:
      return {
        displayName: 'Task Tables (Task Type filter)',
      };
    case CustomerPortalFeature.TASK_TABLE_PRODUCTS:
      return {
        displayName: 'Task Tables (Product filter)',
      };
    case CustomerPortalFeature.MARK_TASK_COMPLETE:
      return {
        displayName: 'Mark Task Complete',
        hint: 'Show button that automatically completes "integration_test" task and match sample task outputs',
      };
    case CustomerPortalFeature.RBAC:
      return {
        displayName: 'RBAC',
      };
    case CustomerPortalFeature.HUMAN_READABLE_CSV_REPORT:
      return {
        displayName: 'Human Readable CSV Report',
      };
    case CustomerPortalFeature.SELF_SERVE_TESTING:
      return {
        displayName: 'Self Serve Testing',
      };
    case CustomerPortalFeature.CSWII:
      return {
        displayName: 'CSWII',
      };
    case CustomerPortalFeature.SHOW_TIME_SAVINGS:
      return {
        displayName: 'Show Time Savings',
      };
    case CustomerPortalFeature.FASTTRACK_ACTIVE_CALLS:
      return {
        displayName: 'FastTrack Active Calls',
      };
    case CustomerPortalFeature.NEW_VOICE_ARCHITECTURE:
      return {
        displayName: 'New Voice Architecture',
      };
    case CustomerPortalFeature.DISABLE_FT_HOLD_TIMES:
      return {
        displayName: 'Disable FT Hold Times',
      };
    case CustomerPortalFeature.CALL_SUMMARIZATION:
      return {
        displayName: 'Call Summarization',
      };
    case CustomerPortalFeature.SHOW_HUMAN_INVOLVEMENT_FLAG:
      return {
        displayName: 'Show Human Involvement Flag',
      };
    case CustomerPortalFeature.FASTTRACK_MULTI_PLAYER_MODE_INTERNAL:
      return {
        displayName: 'FastTrack Multi-Player Mode (Internal)',
      };
    case CustomerPortalFeature.FASTTRACK_SINGLE_PLAYER_MODE_INTERNAL:
      return {
        displayName: 'FastTrack Single-Player Mode (Internal)',
      };
    default:
      return {
        displayName: 'Unknown',
        hint: '',
      };
  }
}

interface GetOperatorPortalFeatureLabelsReturn {
  deprecated: boolean;
  disabledForLiveOrg: boolean;
  displayName: string;
  hint: string;
}

export function getOperatorPortalFeatureLabels(
  feature: OperatorPortalFeature
): GetOperatorPortalFeatureLabelsReturn {
  switch (feature) {
    case OperatorPortalFeature.UNKNOWN:
      return {
        displayName: 'Unknown',
        hint: '',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.PROCESS_TASK:
      return {
        displayName: 'Process Task feature',
        hint: '(Deprecated) calls will be submitted for review through process call modal',
        disabledForLiveOrg: false,
        deprecated: true,
      };
    case OperatorPortalFeature.SKIP_REVIEW:
      return {
        displayName: 'Selectively Skip Review feature',
        hint: 'skip 80% of reviews for eligible calls processed by PRO operators',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.SKIP_REVIEW_IGNORE_OPERATOR_CRITERIA:
      return {
        displayName: 'Ignore Operator Criteria for Skip Review',
        hint: 'if skip review is enabled, ignore operator PRO status when skipping review.',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.FLEXIBLE_TASK_INPUTS:
      return {
        displayName: 'Flexible Task Inputs',
        hint: 'Use flexible task lists',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.MULTITASK_ENABLED:
      return {
        // Previously, this feature was referred to as "Multitask".
        // To differentiate from "MultiBenefits", which was added circa November 2023,
        // we now refer to this feature as "MultiPatient".
        displayName: 'Enable MultiPatient Calls',
        hint: 'Allow completion of multiple tasks per call for different patients with same payer, phone number, and task type',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.SUPPRESS_BEG_NOTIFICATIONS:
      return {
        displayName: 'Suppress Operator Beg Notifications',
        hint: "Don't send operator beg notifications to operators",
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.ADD_INPUTS_TO_OUTPUT_CSV:
      return {
        displayName: 'Add Inputs to Output CSV',
        hint: 'Enable to include task inputs to task outputs csv report',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.FLEXIBLE_TASK_OUTPUTS:
      return {
        displayName: 'Flexible Task Outputs',
        hint: 'Enable to use flexible task outputs',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.RBAC:
      return {
        displayName: 'RBAC',
        hint: 'Use Role-Based Access Control for Authorization',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.AUTO_POPULATE_FROM_STAT_PIE:
      return {
        displayName: 'Autofill from Stat PIE',
        hint: 'Support auto populating call outputs from Stat PIE fields',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.PARTIAL_REVIEW:
      return {
        displayName: 'Partial Review',
        hint: 'Support auto reviewing a subset of the call output fields',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.PRIORITIZE_BLIZZARD_OVER_PROD_TASKS:
      return {
        displayName: 'Prioritize Blizzard Over Prod Tasks',
        hint: 'Which tasks autodialer should prioritize when due dates are the same',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.UNIFIED_QUEUE:
      return {
        displayName: 'Use Unified Queue',
        hint: 'Hide org ready page, operators should join the org agnostic ready page',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.ML_IVR_TESTING:
      return {
        displayName: 'Enable ML IVR Testing',
        hint: 'Enable ML IVR Testing',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.INLINE_CALL_OUTPUT_VALIDATION:
      return {
        displayName: 'Inline Validation',
        hint: '(Deprecated) Show validations and suggestions inline, next to each relevant output field on the call page',
        disabledForLiveOrg: false,
        deprecated: true,
      };
    case OperatorPortalFeature.ALWAYS_AUTODIAL_FIRST_CALL:
      return {
        displayName: 'Always Autodial First Call',
        hint: 'Always autodial the first call for a task for unknown phone numbers',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.ALLOW_NEW_CALL_WITHOUT_PROCESSING:
      // Allow conversation designers to start a new call by requeueing the previous call in the background
      // We want to prevent mistakes allowing new calls without processing previous calls for orgs
      // in production. Therefore this feature flag can not be enabled for live orgs.
      return {
        displayName: 'Make a new call without having to process previous call manually',
        hint: 'A new call can be started on the task page without manual processing of previous call (only available for Non-Prod orgs)',
        disabledForLiveOrg: true,
        deprecated: false,
      };
    case OperatorPortalFeature.USE_CONVO_BUILDER_2:
      return {
        displayName: 'Use Convo Builder 2',
        hint: 'Use Convo Builder 2 features',
        disabledForLiveOrg: true,
        deprecated: false,
      };
    case OperatorPortalFeature.ENFORCE_HUMAN_CALL_REASON:
      return {
        displayName: 'Enforce specifying human call reasons',
        hint: 'Enforce specifying human call reasons when starting a human call',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.NEW_PROCESS_CALL_FLOW:
      return {
        displayName: 'Use New Process Call Flow',
        hint: '(Deprecated) Use the new streamlined flow for processing calls',
        disabledForLiveOrg: false,
        deprecated: true,
      };
    case OperatorPortalFeature.EARLY_FAIL_TPA_TASKS:
      return {
        displayName: 'Early fail TPA tasks',
        hint: 'Only allow one operator call to the TPA before auto failing',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.PLAN_NAME_XP:
      return {
        displayName: 'Plan Name XP',
        hint: '(Deprecated) Encourage operators to fill in plan name immediately',
        disabledForLiveOrg: false,
        deprecated: true,
      };
    case OperatorPortalFeature.GENERIC_EBV:
      return {
        displayName: 'Generic EBV UI',
        hint: 'Shows a generic UI for EBV data',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.MULTIBENEFITS_ENABLED:
      return {
        displayName: 'Enable MultiBenefits Calls',
        hint: 'Allow completion of multiple tasks per call for the same patient',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.REPORT_CONTROL_CALL_REASON:
      return {
        displayName: 'Report Control Call Reason',
        hint: 'before operators take control of a call, present them with a modal to report why they need to take control',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.AUTO_REDIRECT_IN_HOLD:
      return {
        displayName: 'Auto Redirect Operator in Hold',
        hint: 'When operator is unnecessarily sitting in the call during HOLD call phase, auto redirect to ready page',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.LOG_TRIGGERED_CUSTOMER_PIE:
      return {
        displayName: 'Log Triggered Customer PIE',
        hint: 'Log triggered customer PIE events',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.ROUTE_OPS_FROM_AUTODIALER:
      return {
        displayName: 'Route Ops into Manual IVR tasks from autodialer',
        hint: 'Route Ops into Manual IVR tasks from autodialer',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.HIDE_ROUTE_OPERATOR_BUTTON:
      return {
        displayName: 'Hide Route Operator Button',
        hint: 'Hides the Route Operator button in the Start Call panel',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.AUTO_REVIEW_ENABLED:
      return {
        displayName: 'Enable Auto-Review',
        hint: 'Enables auto-review for calls, where AI makes the first pass on whether a call is successful or if a human still needs to review',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.HIDE_FREE_TEXT_INPUT:
      return {
        displayName: 'Hide Free Text Input',
        hint: 'Hides the free text input (aka free texting) on the call page',
        disabledForLiveOrg: false,
        deprecated: false,
      };
    case OperatorPortalFeature.SHOW_TASKS_FOR_NON_ADMINS:
      return {
        displayName: 'Show Tasks Menu for Non-Admins',
        hint: 'Hides the "Tasks" menu on the left navigation for non-admin users. Note: non-admins can still see the task menu on pilot and test orgs.',
        disabledForLiveOrg: false,
        deprecated: false,
      };
  }
}

export function getDoNotCallReasonDisplayName(doNotCallReason?: DoNotCallReason) {
  switch (doNotCallReason) {
    case DoNotCallReason.OK_TO_CALL:
      return 'Ok to call';
    case DoNotCallReason.REFUSES_TO_TALK_WITH_EVA:
      return 'Refuses to talk with Eva';
    case DoNotCallReason.PHARMACY_BENEFIT_MANAGER:
      return 'Pharmacy Benefit Manager';
    case DoNotCallReason.OTHER:
      return 'Other';
    default:
      return 'Unknown';
  }
}

export function getPhoneNumberTypeDisplayName(type?: PhoneNumberType) {
  switch (type) {
    case PhoneNumberType.UNDEFINED:
      return 'Undefined';
    case PhoneNumberType.COMMERCIAL:
      return 'Commercial';
    case PhoneNumberType.FEDERAL:
      return 'Federal';
    case PhoneNumberType.MEDICARE_ADVANTAGE:
      return 'Medicare Advantage';
    default:
      return 'Unknown';
  }
}

export function getTaskStateDisplayName(taskState: TaskState) {
  switch (taskState) {
    case TaskState.TASK_STATE_UNKNOWN:
      return 'Unknown';
    case TaskState.TASK_STATE_CREATED:
      return 'Ready for call';
    case TaskState.TASK_STATE_CALL_CONNECTED:
      return 'Call connected';
    case TaskState.TASK_STATE_CALL_DISCONNECTED:
      return 'Call disconnected';
    case TaskState.TASK_STATE_FAILED:
      return 'Failed';
    case TaskState.TASK_STATE_REVIEWED:
      return 'Reviewed';
    case TaskState.TASK_STATE_IN_REVIEW:
      return 'In Review';
    case TaskState.TASK_STATE_READY_FOR_REVIEW:
      return 'Ready for review';
    case TaskState.TASK_STATE_READY_FOR_SELF_REVIEW:
      return 'Ready for self review';
    case TaskState.TASK_STATE_PENDING_AUTO_REQUEUE:
      return 'Pending auto requeue';
    case TaskState.TASK_STATE_PENDING_EXTERNAL_VENDOR_API_CALL:
      return 'Pending external vendor API call';
    case TaskState.TASK_STATE_AUTO_CLOSED:
      return 'Auto closed';
    default:
      return 'Unknown';
  }
}

export function getTaskStateDescription(taskState: TaskState) {
  switch (taskState) {
    case TaskState.TASK_STATE_UNKNOWN:
      return 'Unknown task state';
    case TaskState.TASK_STATE_CREATED:
      return 'Task is ready for a new call';
    case TaskState.TASK_STATE_CALL_CONNECTED:
      return 'Calls in progress for the task';
    case TaskState.TASK_STATE_CALL_DISCONNECTED:
      return 'Task being worked on after a call';
    case TaskState.TASK_STATE_FAILED:
      return 'Task has been marked as failed';
    case TaskState.TASK_STATE_REVIEWED:
      return 'Task has been completed successfully';
    case TaskState.TASK_STATE_READY_FOR_REVIEW:
      return 'Calls completed - task is ready for review';
    case TaskState.TASK_STATE_IN_REVIEW:
      return 'Task is being reviewed for accuracy';
    case TaskState.TASK_STATE_READY_FOR_SELF_REVIEW:
      return 'Task is ready for self review';
    case TaskState.TASK_STATE_PENDING_AUTO_REQUEUE:
      return 'Task is pending to be auto requeued';
    case TaskState.TASK_STATE_PENDING_EXTERNAL_VENDOR_API_CALL:
      return 'Task is pending to be filled from external vendor API call';
    case TaskState.TASK_STATE_AUTO_CLOSED:
      return 'Task is auto-closed after not being processed for a while';
    default:
      return 'Unknown';
  }
}

export const getProviderNetworkStatusDisplayName = (
  networkStatus: ProviderInfo_NetworkStatus
): string => {
  switch (networkStatus) {
    case ProviderInfo_NetworkStatus.NETWORK_STATUS_IN_NETWORK:
      return 'In Network';
    case ProviderInfo_NetworkStatus.NETWORK_STATUS_OUT_OF_NETWORK:
      return 'Out of Network';
    default:
      return 'Unknown';
  }
};

export const getProductDaysSupplyDisplayName = (daysSupply: ProductInfo_DaysSupply): string => {
  switch (daysSupply) {
    case ProductInfo_DaysSupply.DAYS_SUPPLY_30_DAYS:
      return '30 Days';
    case ProductInfo_DaysSupply.DAYS_SUPPLY_60_DAYS:
      return '60 Days';
    case ProductInfo_DaysSupply.DAYS_SUPPLY_90_DAYS:
      return '90 Days';
    default:
      return 'Unknown';
  }
};

export const getOtherInsuranceStatusDisplayName = (
  otherInsuranceStatus: PolicyInfo_OtherInsuranceStatus
): string => {
  switch (otherInsuranceStatus) {
    case PolicyInfo_OtherInsuranceStatus.OTHER_INSURANCE_STATUS_HAS_MEDICARE_PART_B:
      return 'Has Medicare Part B';
    case PolicyInfo_OtherInsuranceStatus.OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_PRIMARY:
      return 'Exists, Current Plan is Primary';
    case PolicyInfo_OtherInsuranceStatus.OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_SECONDARY:
      return 'Exists, Current Plan is Secondary';
    case PolicyInfo_OtherInsuranceStatus.OTHER_INSURANCE_STATUS_EXISTS_CURRENT_PLAN_TERTIARY:
      return 'Exists, Current Plan is Tertiary';
    default:
      return 'Unknown';
  }
};

export function getCallPhaseDisplayName(phase?: CallPhase) {
  switch (phase) {
    case CallPhase.IVR:
      return 'IVR';
    case CallPhase.HOLD:
      return 'Hold';
    case CallPhase.PERSON_AUTHENTICATE:
      return 'HIPAA';
    case CallPhase.PERSON_GET_INFORMATION:
      return 'Get Info';
    default:
      return 'unknown';
  }
}

export const getTTSVoiceDisplayName = (voiceType: infinitusai.be.TTSSetting.Voice): string => {
  switch (voiceType) {
    // GOOGLE_TTS
    case infinitusai.be.TTSSetting.Voice.en_US_Wavenet_A:
      return 'en-US-Wavenet-A (male)';
    case infinitusai.be.TTSSetting.Voice.en_US_Wavenet_B:
      return 'en-US-Wavenet-B (male)';
    case infinitusai.be.TTSSetting.Voice.en_US_Wavenet_C:
      return 'en-US-Wavenet-C (female)';
    case infinitusai.be.TTSSetting.Voice.en_US_Wavenet_D:
      return 'Eric (male)';
    case infinitusai.be.TTSSetting.Voice.en_US_Wavenet_E:
      return 'en-US-Wavenet-E (female)';
    case infinitusai.be.TTSSetting.Voice.en_US_Wavenet_F:
      return 'Eva (female)';
    case infinitusai.be.TTSSetting.Voice.en_US_Neural2_F:
      return 'en-US-Neural2-F';
    case infinitusai.be.TTSSetting.Voice.en_US_Neural2_G:
      return 'en-US-Neural2-G';
    case infinitusai.be.TTSSetting.Voice.en_US_Neural2_H:
      return 'en-US-Neural2-H';
    case infinitusai.be.TTSSetting.Voice.en_US_Studio_M:
      return 'en-US-Studio-M (male)';
    case infinitusai.be.TTSSetting.Voice.en_US_Studio_O:
      return 'en-US-Studio-O (female)';

    // ELEVEN_LABS_TTS
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Rahul:
      return 'Rahul';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Jack:
      return 'Jack';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Ira:
      return 'Ira';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Rebbecca:
      return 'Rebecca';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Sneha:
      return 'Sneha';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Tom:
      return 'Tom';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Alfred:
      return 'Alfred';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Adam:
      return 'Adam';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Sir_Paddington:
      return 'Sir Paddington';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Emma:
      return 'Emma';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Meera:
      return 'Meera';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Shauna:
      return 'Shauna';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Shyam:
      return 'Shyam';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Tamika:
      return 'Tamika';
    case infinitusai.be.TTSSetting.Voice.elevenLabs_Bria:
      return 'Bria';
    default:
      return 'Unknown';
  }
};

export const getTaskStateTransitionDisplayName = (
  taskStateTransition: infinitusai.be.TaskStateTransition | TaskStateTransition
): string => {
  switch (taskStateTransition) {
    case infinitusai.be.TaskStateTransition.TASK_STATE_TRANSITION_SEND_TO_REVIEW:
    case TaskStateTransition.TASK_STATE_TRANSITION_SEND_TO_REVIEW:
      return 'Submitted as a failed task for final review';
    case infinitusai.be.TaskStateTransition.TASK_STATE_TRANSITION_FAIL_TASK:
    case TaskStateTransition.TASK_STATE_TRANSITION_FAIL_TASK:
      return 'Submitted as a failed task';
    case infinitusai.be.TaskStateTransition.TASK_STATE_TRANSITION_LEAVE_TASK_DISCONNECTED:
    case TaskStateTransition.TASK_STATE_TRANSITION_LEAVE_TASK_DISCONNECTED:
      return 'Left Disconnected';
    case infinitusai.be.TaskStateTransition.TASK_STATE_TRANSITION_REQUEUE_TASK:
    case TaskStateTransition.TASK_STATE_TRANSITION_REQUEUE_TASK:
      return 'Requeued';
    case infinitusai.be.TaskStateTransition.TASK_STATE_TRANSITION_AUTO_REQUEUE_TASK:
    case TaskStateTransition.TASK_STATE_TRANSITION_AUTO_REQUEUE_TASK:
      return 'Pending to be Auto Requeued';
    case infinitusai.be.TaskStateTransition.TASK_STATE_TRANSITION_UNKNOWN:
    case TaskStateTransition.TASK_STATE_TRANSITION_UNKNOWN:
    default:
      return 'Unknown';
  }
};

export const getTaskFailureReasonDisplayName = (
  reason: infinitusai.be.TaskFailReason.Type | TaskFailReason
): string => {
  switch (reason) {
    case infinitusai.be.TaskFailReason.Type.REASON_BAD_DATA:
    case TaskFailReason.REASON_BAD_DATA:
      return 'Bad data was provided';
    case infinitusai.be.TaskFailReason.Type.REASON_MISSING_DATA:
    case TaskFailReason.REASON_MISSING_DATA:
      return 'Data was missing';
    case infinitusai.be.TaskFailReason.Type.REASON_INVALID_PLAN:
    case TaskFailReason.REASON_INVALID_PLAN:
      return 'The plan is not valid';
    case infinitusai.be.TaskFailReason.Type.REASON_PAYER_UNREACHABLE:
    case TaskFailReason.REASON_PAYER_UNREACHABLE:
      return 'Payer is not reachable';
    case infinitusai.be.TaskFailReason.Type.REASON_PRESCRIBER_UNREACHABLE:
    case TaskFailReason.REASON_PRESCRIBER_UNREACHABLE:
      return 'Prescriber is not reachable';
    case infinitusai.be.TaskFailReason.Type.REASON_IVR_NAVIGATION_FAILURE:
    case TaskFailReason.REASON_IVR_NAVIGATION_FAILURE:
      return 'IVR Navigation Failure';
    case infinitusai.be.TaskFailReason.Type.REASON_HOLD_NAVIGATION_FAILURE:
    case TaskFailReason.REASON_HOLD_NAVIGATION_FAILURE:
      return 'Hold Navigation Failure';
    case infinitusai.be.TaskFailReason.Type.REASON_AGENT_AUTOMATION_HANGUP:
    case TaskFailReason.REASON_AGENT_AUTOMATION_HANGUP:
      return 'Agent Automation Hangup';
    case infinitusai.be.TaskFailReason.Type.REASON_NO_OPERATOR_AVAILABLE:
    case TaskFailReason.REASON_NO_OPERATOR_AVAILABLE:
      return 'No Operator Available';
    case infinitusai.be.TaskFailReason.Type.REASON_PAYER_REFUSES_DA:
    case TaskFailReason.REASON_PAYER_REFUSES_DA:
      return 'Payer will not talk to digital assistant';
    case infinitusai.be.TaskFailReason.Type.REASON_PAYER_REFUSES_THIRD_PARTY:
    case TaskFailReason.REASON_PAYER_REFUSES_THIRD_PARTY:
      return 'Payer refused to provide benefit info to Third party';
    case infinitusai.be.TaskFailReason.Type.REASON_CUSTOMER_CANCELED:
    case TaskFailReason.REASON_CUSTOMER_CANCELED:
      return 'Customer canceled this request';
    case infinitusai.be.TaskFailReason.Type.REASON_PAST_DUE:
    case TaskFailReason.REASON_PAST_DUE:
      return 'Task is past due';
    case infinitusai.be.TaskFailReason.Type.REASON_TASK_EXPIRED:
    case TaskFailReason.REASON_TASK_EXPIRED:
      return 'Task is expired';
    case infinitusai.be.TaskFailReason.Type.REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD:
    case TaskFailReason.REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD:
      return 'Need to call the number on the back of the ID card';
    case infinitusai.be.TaskFailReason.Type.REASON_OTHER:
    case TaskFailReason.REASON_OTHER:
      return 'Other - please describe in task notes';
    case infinitusai.be.TaskFailReason.Type.REASON_PATIENT_DECEASED:
    case TaskFailReason.REASON_PATIENT_DECEASED:
      return '(Deprecated) Patient Deceased';
    case infinitusai.be.TaskFailReason.Type.REASON_PLAN_TERMINATED:
    case TaskFailReason.REASON_PLAN_TERMINATED:
      return '(Deprecated) Plan terminated';
    case infinitusai.be.TaskFailReason.Type.FASTTRACK_REASON_REDIAL:
    case TaskFailReason.FASTTRACK_REASON_REDIAL:
      return '(FastTrack only) Redial';
    default:
      return 'Unknown';
  }
};

export const getRequeueReasonDisplayName = (
  reason: infinitusai.be.RequeueReason.Type | RequeueReason
): string => {
  switch (reason) {
    case infinitusai.be.RequeueReason.Type.REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA:
    case RequeueReason.REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA:
      return 'Agent refused to speak to Eva';
    case infinitusai.be.RequeueReason.Type.REASON_AGENT_DID_NOT_PROVIDE_INFO:
    case RequeueReason.REASON_AGENT_DID_NOT_PROVIDE_INFO:
      return 'Agent did not provide needed info';
    case infinitusai.be.RequeueReason.Type.REASON_AGENT_PROVIDED_UNRELIABLE_DATA:
    case RequeueReason.REASON_AGENT_PROVIDED_UNRELIABLE_DATA:
      return 'Agent provided unreliable answer';
    case infinitusai.be.RequeueReason.Type.REASON_AGENT_REQUESTED_UNAVAILABLE_INFO:
    case RequeueReason.REASON_AGENT_REQUESTED_UNAVAILABLE_INFO:
      return 'Agent requested info we did not have';
    case infinitusai.be.RequeueReason.Type.REASON_AGENT_TOO_SLOW:
    case RequeueReason.REASON_AGENT_TOO_SLOW:
      return 'Agent responding too slow';
    case infinitusai.be.RequeueReason.Type.REASON_OPERATOR_ERROR:
    case RequeueReason.REASON_OPERATOR_ERROR:
      return 'Operator Error, specifics can be logged';
    case infinitusai.be.RequeueReason.Type.REASON_SYSTEM_ISSUE:
    case RequeueReason.REASON_SYSTEM_ISSUE:
      return 'Operator Portal Failure';
    case infinitusai.be.RequeueReason.Type.REASON_AGENT_CANT_FIND_MEMBER:
    case RequeueReason.REASON_AGENT_CANT_FIND_MEMBER:
      return 'Agent/IVR unable to locate member';
    case infinitusai.be.RequeueReason.Type.REASON_GATHERED_PIN_FOR_MEDICAL:
    case RequeueReason.REASON_GATHERED_PIN_FOR_MEDICAL:
      return 'Gathered PIN for MediCal';
    case infinitusai.be.RequeueReason.Type.REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT:
    case RequeueReason.REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT:
      return 'Agent transferred incorrectly';
    case infinitusai.be.RequeueReason.Type.REASON_PROVIDED_CORRECT_NUMBER_FOR_BV:
    case RequeueReason.REASON_PROVIDED_CORRECT_NUMBER_FOR_BV:
      return 'Need to call different phone number';
    case infinitusai.be.RequeueReason.Type.REASON_PAYER_SYSTEM_ISSUE:
    case RequeueReason.REASON_PAYER_SYSTEM_ISSUE:
      return 'Payer system issue';
    case infinitusai.be.RequeueReason.Type.REASON_PAYER_UNREACHABLE:
    case RequeueReason.REASON_PAYER_UNREACHABLE:
      return 'Payer not reachable';
    case infinitusai.be.RequeueReason.Type.REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY:
    case RequeueReason.REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY:
      return "Payer won't talk to a third party";
    case infinitusai.be.RequeueReason.Type.REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA:
    case RequeueReason.REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA:
      return "Third Party Administrator (TPA) won't talk to us";
    case infinitusai.be.RequeueReason.Type.REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD:
    case RequeueReason.REASON_CALL_THE_NUMBER_ON_THE_BACK_OF_THE_ID_CARD:
      return 'Call the number on the back of the ID card';
    case infinitusai.be.RequeueReason.Type.REASON_INCORRECT_INPUT_INFORMATION:
    case RequeueReason.REASON_INCORRECT_INPUT_INFORMATION:
      return 'Incorrect input information';
    case infinitusai.be.RequeueReason.Type.REASON_MISSING_INPUT_INFORMATION:
    case RequeueReason.REASON_MISSING_INPUT_INFORMATION:
      return 'Missing input information';
    case infinitusai.be.RequeueReason.Type.REASON_IVR_NAVIGATION_FAILURE:
    case RequeueReason.REASON_IVR_NAVIGATION_FAILURE:
      return 'IVR Navigation Failure';
    case infinitusai.be.RequeueReason.Type.REASON_IVR_TIMEOUT:
    case RequeueReason.REASON_IVR_TIMEOUT:
      return 'IVR Timeout';
    case infinitusai.be.RequeueReason.Type.REASON_AGENT_AUTOMATION_HANGUP:
    case RequeueReason.REASON_AGENT_AUTOMATION_HANGUP:
      return 'Agent Automation Hangup';
    case infinitusai.be.RequeueReason.Type.REASON_NO_OPERATOR_AVAILABLE:
    case RequeueReason.REASON_NO_OPERATOR_AVAILABLE:
      return 'No Operator Available';
    case infinitusai.be.RequeueReason.Type.REASON_UNEXPLAINED_OUTBOUND_HANGUP:
    case RequeueReason.REASON_UNEXPLAINED_OUTBOUND_HANGUP:
      return 'Payer hung up call unexpectedly';
    case infinitusai.be.RequeueReason.Type.REASON_PIE_ESCALATION_NEEDED:
    case RequeueReason.REASON_PIE_ESCALATION_NEEDED:
      return 'PIE Escalation Needed';
    case infinitusai.be.RequeueReason.Type.REASON_AGENT_CANT_FIND_MEMBER_PLAN:
    case RequeueReason.REASON_AGENT_CANT_FIND_MEMBER_PLAN:
      return 'Terminated or Invalid Plan';
    case infinitusai.be.RequeueReason.Type.REASON_OTHER:
    case RequeueReason.REASON_OTHER:
      return 'Other - specify in notes';
    case infinitusai.be.RequeueReason.Type.REASON_AGENT_PROVIDED_AMBIGUOUS_INFO_WITHOUT_PUSHBACK:
    case RequeueReason.REASON_AGENT_PROVIDED_AMBIGUOUS_INFO_WITHOUT_PUSHBACK:
      return 'Agent provided ambiguous info/no pushback from operator';
    case infinitusai.be.RequeueReason.Type.REASON_AGENT_PROVIDED_INCONSISTENT_ANSWERS:
    case RequeueReason.REASON_AGENT_PROVIDED_INCONSISTENT_ANSWERS:
      return 'Agent provided inconsistent answers';
    case infinitusai.be.RequeueReason.Type.REASON_INCOMPLETE_ANSWER_TO_QUESTIONS:
    case RequeueReason.REASON_INCOMPLETE_ANSWER_TO_QUESTIONS:
      return 'Incomplete answer(s) to question(s)';
    case infinitusai.be.RequeueReason.Type.REASON_INVALID_ANSWERS_TO_QUESTIONS:
    case RequeueReason.REASON_INVALID_ANSWERS_TO_QUESTIONS:
      return 'Invalid answer(s) to question(s)';
    case infinitusai.be.RequeueReason.Type.REASON_UNANSWERED_QUESTIONS_REMAINING:
    case RequeueReason.REASON_UNANSWERED_QUESTIONS_REMAINING:
      return 'Unanswered questions/Incorrect answers';
    case infinitusai.be.RequeueReason.Type.REASON_STANDALONE_BENEFITS_NOT_DOCUMENTED:
    case RequeueReason.REASON_STANDALONE_BENEFITS_NOT_DOCUMENTED:
      return 'Standalone benefits not documented';
    case infinitusai.be.RequeueReason.Type.REASON_BOTH_IN_AND_OON_NOT_COLLECTED:
    case RequeueReason.REASON_BOTH_IN_AND_OON_NOT_COLLECTED:
      return 'Both IN/OON not collected';
    case infinitusai.be.RequeueReason.Type
      .REASON_COLLECTED_WRONG_BENEFITS_FOR_PROVIDER_NETWORK_STATUS:
    case RequeueReason.REASON_COLLECTED_WRONG_BENEFITS_FOR_PROVIDER_NETWORK_STATUS:
      return 'Collected wrong type of benefits based on provider network status';
    case infinitusai.be.RequeueReason.Type.REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED:
    case RequeueReason.REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED:
      return "Current year's benefits are not yet loaded";
    case infinitusai.be.RequeueReason.Type.REASON_INCORRECT_PROMPT_SELECTED:
    case RequeueReason.REASON_INCORRECT_PROMPT_SELECTED:
      return 'Incorrect prompt selected';
    case infinitusai.be.RequeueReason.Type.REASON_QUALITY_CONCERNS:
    case RequeueReason.REASON_QUALITY_CONCERNS:
      return 'Quality and professionalism concern';
    case infinitusai.be.RequeueReason.Type.REASON_NLP_PLAN_TERMINATED:
    case RequeueReason.REASON_NLP_PLAN_TERMINATED:
      return 'Plan terminated';
    case infinitusai.be.RequeueReason.Type.REASON_NLP_PAYER_UNREACHABLE:
    case RequeueReason.REASON_NLP_PAYER_UNREACHABLE:
      return 'Payer not reachable';
    case infinitusai.be.RequeueReason.Type.REASON_NLP_ERROR:
    case RequeueReason.REASON_NLP_ERROR:
      return 'NLP error';
    case infinitusai.be.RequeueReason.Type.REASON_NLP_AGENT_REFUSED_TO_SPEAK_TO_EVA:
    case RequeueReason.REASON_NLP_AGENT_REFUSED_TO_SPEAK_TO_EVA:
      return 'Agent refused to speak to AI voice';
    case infinitusai.be.RequeueReason.Type.REASON_NLP_READY_FOR_REVIEW:
    case RequeueReason.REASON_NLP_READY_FOR_REVIEW:
      return 'Ready for review';
    case infinitusai.be.RequeueReason.Type.REASON_NLP_PAYER_SYSTEM_ISSUE:
    case RequeueReason.REASON_NLP_PAYER_SYSTEM_ISSUE:
      return 'Payer System Issue';
    case infinitusai.be.RequeueReason.Type.REASON_NLP_CALL_NEW_NUMBER:
    case RequeueReason.REASON_NLP_CALL_NEW_NUMBER:
      return 'Call new number';
    case infinitusai.be.RequeueReason.Type.REASON_NONE:
    case RequeueReason.REASON_NONE:
      return 'None';
    case infinitusai.be.RequeueReason.Type.REASON_PROVIDER_IS_INACTIVE:
    case RequeueReason.REASON_PROVIDER_IS_INACTIVE:
      return 'Provider is inactive';
    case infinitusai.be.RequeueReason.Type.REASON_NO_BENEFITS_UNDER_THIS_PLAN:
    case RequeueReason.REASON_NO_BENEFITS_UNDER_THIS_PLAN:
      return 'No benefits under this plan';
    case infinitusai.be.RequeueReason.Type.REASON_UNABLE_TO_REVERIFY_BENEFITS:
    case RequeueReason.REASON_UNABLE_TO_REVERIFY_BENEFITS:
      return 'Representative will not reverify benefits';
    case infinitusai.be.RequeueReason.Type.REASON_LONG_HOLD_TIME:
    case RequeueReason.REASON_LONG_HOLD_TIME:
      return 'Long hold time';
    case infinitusai.be.RequeueReason.Type.REASON_NLP_BAD_DATA:
    case RequeueReason.REASON_NLP_BAD_DATA:
      return 'Bad data was provided';
    case infinitusai.be.RequeueReason.Type.REASON_NLP_AGENT_CANT_FIND_MEMBER:
    case RequeueReason.REASON_NLP_AGENT_CANT_FIND_MEMBER:
      return 'Agent/IVR unable to locate member';
    case infinitusai.be.RequeueReason.Type.REASON_NLP_UNEXPLAINED_OUTBOUND_HANGUP:
    case RequeueReason.REASON_NLP_UNEXPLAINED_OUTBOUND_HANGUP:
      return 'Unexplained Outbound Hangup';
    case infinitusai.be.RequeueReason.Type.REASON_HOLD_NAVIGATION_FAILURE:
    case RequeueReason.REASON_HOLD_NAVIGATION_FAILURE:
      return 'Hold Navigation Failure';
    case infinitusai.be.RequeueReason.Type.REASON_NLP_INPUT_NOT_RECOGNIZED:
    case RequeueReason.REASON_NLP_INPUT_NOT_RECOGNIZED:
      return 'Input was not recognized';
    default:
      return 'Unknown';
  }
};

interface RequeueReasonExplanation {
  exclusions: string[];
  inclusions: string[];
}

// Inclusions and exclusions for verbiage taken from google sheet below
// https://docs.google.com/spreadsheets/d/1vzz7YzQsuCABxcFR4jTQPrnuGFmk5Q92yKNb_u4lc2I/edit#gid=517504318
export function lookupRequeueReasonExplanation(
  reason: ActiveRequeueReason
): RequeueReasonExplanation {
  switch (reason) {
    case RequeueReason.REASON_AGENT_CANT_FIND_MEMBER:
      return {
        inclusions: [
          'IVR/Representative could not find the patient in the system (even after pushback to look up based on member name and date of birth)',
          '(all plans except BCBS): IVR/Representative could not find patient in system and will not let you proceed to an agent',
          '(For BCBS plans, where an attempt has been made to the general Blue Card line 8006762583) IVR/Representative could not find patient in system and will not let you proceed to an agent',
        ],
        exclusions: [],
      };
    case RequeueReason.REASON_AGENT_CANT_FIND_MEMBER_PLAN:
      return {
        inclusions: [
          'Agent states that they have located the patient in the system, but they do not have access to the plan or information on how to reach the correct department',
        ],
        exclusions: [
          'Termed plans with no other ACTIVE plan on file (even if one with a future effective date exists) should be submitted as a COMPLETED call',
        ],
      };
    case RequeueReason.REASON_IVR_NAVIGATION_FAILURE:
      return {
        inclusions: [
          'Unable to navigate through the payer IVR system to get to an agent (IVR mapping issue)',
        ],
        exclusions: [
          'IVR could not find patient in system and will not let us proceed to an agent',
          '(for non-BCBS plans), use "Agent/IVR unable to locate member".',
          '(For BCBS plans, where an attempt HAS been made to the general Blue Card line 8006762583): use "Agent/IVR unable to locate member".',
          '(For BCBS plans, where an attempt HAS NOT been made to the general Blue Card line 8006762583): select "Need to call different phone number" and input the general Blue Card line 8006762583.',
          'Unexplained outbound hangups during IVR where there were no other issues (i.e., Eva did not have any problems navigating, member/provider/practice info is correct) --> Select "Unexplained Outbound Hangup" instead',
        ],
      };
    case RequeueReason.REASON_PROVIDED_CORRECT_NUMBER_FOR_BV:
      return {
        inclusions: [
          'Agent states that the plan is handled by a different department and we are unable to complete benefit verification',
          'For certain payers where if the agent is unable to locate an active member plan, SOP is to make another call to a different number first (e.g., general blue card line for BC plans) before failing.',
        ],
        exclusions: [],
      };
    case RequeueReason.REASON_UNEXPLAINED_OUTBOUND_HANGUP:
      return {
        inclusions: [
          'Unexplained hangups during IVR, hold, or by the agent (including extended silence or non-response on the other line)',
        ],
        exclusions: [
          'Agent refuses to speak to digital assistant (specifically states they are unable to talk to Eva or a hostile agent that immediately hangs up upon hearing Eva\'s voice) --> Use "Agent refused to speak to AI voice" instead',
        ],
      };
    case RequeueReason.REASON_AGENT_REFUSED_TO_GIVE_BENEFIT_INFO_TO_THIRD_PARTY:
      return {
        inclusions: [
          'Agent on original number says they can only provide benefits to the provider/patient',
          'Transfers internally within a payer to a different team or department that will only give benefits to the provider/patient',
        ],
        exclusions: [
          'Third Party Administrators (TPAs) that refuse to give benefits --> use "Third Party Administrator (TPA) won\'t talk to us" instead',
        ],
      };
    case RequeueReason.REASON_THIRD_PARTY_ADMIN_REFUSES_TO_SPEAK_TO_EVA:
      return {
        inclusions: [
          'New number provided and/or transferred to third party outside of the payer (different company or organization)',
        ],
        exclusions: [
          'Transfers internally within a payer to a different team or department that will only give benefits to the provider/patient --> use "Payer refused to provide benefit info to Third party" instead',
        ],
      };
    case RequeueReason.REASON_AGENT_REFUSED_TO_SPEAK_TO_EVA:
      return {
        inclusions: [
          "Agent refuses to speak to digital assistant (specifically states they are unable to talk to Eva or a hostile agent that immediately hangs up upon hearing Eva's voice)",
        ],
        exclusions: [
          'Unexplained hangups or silence on the other line without a refusal specifically stated --> use "Unexplained Outbound Hangup" instead',
        ],
      };
    case RequeueReason.REASON_MISSING_INPUT_INFORMATION:
      return {
        inclusions: [
          'Representative or IVR can not proceed with the call because we are missing information about the patient or provider',
        ],
        exclusions: [],
      };
    case RequeueReason.REASON_INCORRECT_INPUT_INFORMATION:
      return {
        inclusions: [
          'Representative can not proceed with the call because we have incorrect information about the member, practice or provider',
        ],
        exclusions: [],
      };
    case RequeueReason.REASON_CURRENT_YEAR_BENEFITS_NOT_YET_LOADED:
      return {
        inclusions: ['Agent states that the benefits for the current year are not yet loaded'],
        exclusions: [],
      };
    case RequeueReason.REASON_AGENT_PROVIDED_UNRELIABLE_DATA:
      return {
        inclusions: ['Agent Provided Unreliable Data'],
        exclusions: [],
      };
    case RequeueReason.REASON_TRANSFERRED_TO_INCORRECT_DEPARTMENT:
      return {
        inclusions: ['Problem with Transfer'],
        exclusions: [],
      };
    case RequeueReason.REASON_PAYER_SYSTEM_ISSUE:
      return {
        inclusions: ['Actual payer system problems (audio issues, system outages, etc.)'],
        exclusions: [],
      };
    case RequeueReason.REASON_PAYER_UNREACHABLE:
      return {
        inclusions: ['Long hold times, office closures, holidays, payer overflow scenarios'],
        exclusions: [],
      };
    case RequeueReason.REASON_OPERATOR_ERROR:
      return {
        inclusions: ['Operator error'],
        exclusions: [],
      };
    case RequeueReason.REASON_SYSTEM_ISSUE:
      return {
        inclusions: [
          'Portal system issues like audio/recording, buttons, delays/lag, platform failing to recognize and beg for operator when agent comes on)',
        ],
        exclusions: [],
      };
    case RequeueReason.REASON_PIE_ESCALATION_NEEDED:
      return {
        inclusions: ['PIE Escalation Needed (GENENTECH PROGRAMS ONLY)'],
        exclusions: [],
      };
    case RequeueReason.REASON_PROVIDER_IS_INACTIVE:
      return {
        inclusions: ['Provider is inactive'],
        exclusions: [],
      };
    case RequeueReason.REASON_NO_BENEFITS_UNDER_THIS_PLAN:
      return {
        inclusions: [
          'Representative confirmed that there are no medical/pharmacy benefits under this plan',
        ],
        exclusions: [],
      };
    case RequeueReason.REASON_UNABLE_TO_REVERIFY_BENEFITS:
      return {
        inclusions: ['Representative will not reverify benefits'],
        exclusions: [],
      };
    case RequeueReason.REASON_OTHER:
      return {
        inclusions: ['Testing', 'Call is to a non-supported payer', 'Other (please describe)'],
        exclusions: [],
      };
    default:
      return { inclusions: [], exclusions: [] };
  }
}

export const getValueTypeName = (
  callOutputFieldType: CallOutputFieldType | infinitusai.be.CallOutputFieldType
): string => {
  switch (callOutputFieldType) {
    case infinitusai.be.CallOutputFieldType.OUTPUT_TYPE_EMPTY:
      return '-';
    case CallOutputFieldType.BOOL:
    case infinitusai.be.CallOutputFieldType.OUTPUT_TYPE_BOOL:
      return 'True / False';
    case CallOutputFieldType.DATE:
    case infinitusai.be.CallOutputFieldType.OUTPUT_TYPE_DATE:
      return 'Date';
    case CallOutputFieldType.ENUM:
    case infinitusai.be.CallOutputFieldType.OUTPUT_TYPE_ENUM:
      return 'Single-select';
    case CallOutputFieldType.ENUMS:
    case infinitusai.be.CallOutputFieldType.OUTPUT_TYPE_ENUMS:
      return 'Multi-select';
    case CallOutputFieldType.INT:
    case infinitusai.be.CallOutputFieldType.OUTPUT_TYPE_INT:
      return 'Number';
    case CallOutputFieldType.MONEY:
    case infinitusai.be.CallOutputFieldType.OUTPUT_TYPE_MONEY:
      return 'Money';
    case CallOutputFieldType.STRING:
    case infinitusai.be.CallOutputFieldType.OUTPUT_TYPE_STRING:
      return 'Text';
    default:
      return 'Unknown';
  }
};

export const getSkillAttributeDisplayName = (
  attribute: infinitusai.be.SkillAttribute.Type
): string => {
  switch (attribute) {
    case infinitusai.be.SkillAttribute.Type.IS_ESCALATED:
      return 'Is task escalated';
    case infinitusai.be.SkillAttribute.Type.MULTI_TASK:
      return 'Is eligible for multi-task';
    case infinitusai.be.SkillAttribute.Type.MULTI_BENEFIT:
      return 'Is eligible for multi-benefit';
    case infinitusai.be.SkillAttribute.Type.NEEDS_HUMAN_INTRO:
      return 'Needs human intro';
    case infinitusai.be.SkillAttribute.Type.ORG_NAME:
      return 'Org name';
    case infinitusai.be.SkillAttribute.Type.PROGRAM_NAME:
      return 'Program name';
    case infinitusai.be.SkillAttribute.Type.TASK_STATE:
      return 'Task state';
    case infinitusai.be.SkillAttribute.Type.TASK_TYPE:
      return 'Task type';
    case infinitusai.be.SkillAttribute.Type.NEEDS_MANUAL_IVR:
      return 'Needs manual IVR';
    default:
      return 'Unknown';
  }
};

export const getSkillAttributeValueDisplayValue = (
  attribute: infinitusai.be.SkillAttribute.Type,
  value?: string
): string => {
  switch (attribute) {
    case infinitusai.be.SkillAttribute.Type.IS_ESCALATED:
    case infinitusai.be.SkillAttribute.Type.MULTI_TASK:
    case infinitusai.be.SkillAttribute.Type.MULTI_BENEFIT:
    case infinitusai.be.SkillAttribute.Type.NEEDS_HUMAN_INTRO:
    case infinitusai.be.SkillAttribute.Type.NEEDS_MANUAL_IVR:
      return value === 'yes' ? 'Yes' : 'No';
    case infinitusai.be.SkillAttribute.Type.TASK_STATE:
      return getTaskStateDisplayName(value as TaskState);
    case infinitusai.be.SkillAttribute.Type.TASK_TYPE:
      return getTaskTypeDisplayName(value as TaskType);
    case infinitusai.be.SkillAttribute.Type.ORG_NAME:
    case infinitusai.be.SkillAttribute.Type.PROGRAM_NAME:
    default:
      return value || 'Unknown';
  }
};

export const getHumanCallReasonDisplayName = (reason: HumanIntroReason): string => {
  switch (reason) {
    case HumanIntroReason.HUMAN_INTRO_REASON_VOICE_PAYER_REFUSAL:
      return 'Agent refused to speak with AI';
    case HumanIntroReason.HUMAN_INTRO_REASON_OFFICIAL_PAYER_SOP:
      return 'Payer requires voice calls';
    case HumanIntroReason.HUMAN_INTRO_REASON_ESCALATION_TO_SUPERVISOR:
      return 'Escalated to Supervisor';
    case HumanIntroReason.HUMAN_INTRO_REASON_ESCALATION_TO_PA_DEPARTMENT:
      return 'Escalated to Prior Auth Department';
    case HumanIntroReason.HUMAN_INTRO_REASON_ESCALATION_TO_TPA:
      return 'Escalated to Third Party Admin';
    case HumanIntroReason.HUMAN_INTRO_REASON_ESCALATION_OTHER:
      return 'Escalated to Other';
    case HumanIntroReason.HUMAN_INTRO_REASON_NON_ESCALATION_OTHER:
      return 'Other';
    default:
      return 'Unknown';
  }
};
