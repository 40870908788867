import { AppName } from '@infinitusai/api';
import { ThemeProvider } from '@mui/material';
import { QueryClientProvider } from '@tanstack/react-query';
import { CypressHistorySupport } from 'cypress-react-router';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider } from '@infinitus/auth';
import { CriticalReqLogger } from '@infinitus/components/CriticalReqLogger/CriticalReqLogger';
import { EventLoopDelayLogger } from '@infinitus/components/EventLoopDelayLogger';
import { NetworkLogger } from '@infinitus/components/NetworkLogger';
import { ApiContextProvider } from '@infinitus/hooks/useApi';
import { AppConfigContextProvider } from '@infinitus/hooks/useAppConfig';
import { logEventToBigQuery } from '@infinitus/hooks/useLogBuffer';
import { NexmoClientProvider } from '@infinitus/nexmo/NexmoClientProvider';
import CustomSnackbarProvider from '@infinitus/utils/CustomSnackbarProvider';
import { InternalApolloProvider } from 'apollo/InternalApolloProvider';
import NavLogger from 'components/NavLogger';
import PerformanceLogger from 'components/PerformanceLogger';
import OperatorOnlineStatusProvider from 'hooks/useOperatorOnlineStatus/OperatorOnlineStatusProvider';
import MuiV5Theme from 'styles/MuiV5Theme';
import { OpenReplayTracker } from 'utils/OpenReplayTracker';
import { initFirebase } from 'utils/firebase';
import createQueryClient from 'utils/queryClient';

import PortalOperatorPresenceContextProvider from './PortalOperatorPresenceContextProvider';

interface Props {
  children: React.ReactNode;
}

initFirebase();

// Initialize react query
const queryClient = createQueryClient();

// Note: there is an issue with React.StrictMode (in development only?) and
// the apollo client, where `useSubscription` creates two subscriptions.
// Follow the github issue here:
// https://github.com/apollographql/apollo-client/issues/6037
function AppProviders({ children }: Props) {
  return (
    <InternalApolloProvider>
      <AppConfigContextProvider appName={AppName.OPERATOR}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <OpenReplayTracker />
            <ApiContextProvider>
              <ThemeProvider theme={MuiV5Theme}>
                <SnackbarProvider
                  anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'top',
                  }}
                  autoHideDuration={3000}
                  maxSnack={2}
                  preventDuplicate={true}
                  style={{ pointerEvents: 'all' }}
                >
                  <CustomSnackbarProvider>
                    <NexmoClientProvider
                      appName={AppName.OPERATOR}
                      logEvent={async (event) => {
                        return await logEventToBigQuery(event);
                      }}
                    >
                      <BrowserRouter>
                        <CypressHistorySupport />
                        <NavLogger />
                        <NetworkLogger />
                        <PerformanceLogger />
                        <CriticalReqLogger />
                        <EventLoopDelayLogger />
                        <PortalOperatorPresenceContextProvider>
                          <OperatorOnlineStatusProvider>{children}</OperatorOnlineStatusProvider>
                        </PortalOperatorPresenceContextProvider>
                      </BrowserRouter>
                    </NexmoClientProvider>
                  </CustomSnackbarProvider>
                </SnackbarProvider>
              </ThemeProvider>
            </ApiContextProvider>
          </AuthProvider>
        </QueryClientProvider>
      </AppConfigContextProvider>
    </InternalApolloProvider>
  );
}

export default AppProviders;
