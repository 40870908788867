import { AppName } from '@infinitusai/api';
import { assign, isFunction } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';
import { matchRoutes, useLocation } from 'react-router-dom';
import winston from 'winston';
import TransportStream from 'winston-transport';

import { useAuth } from '@infinitus/auth';
import { logBufferVar } from '@infinitus/hooks/useLogBuffer';
import {
  BACKEND_PORT,
  BACKEND_SERVER_HOST,
  FRONTEND_VERSION,
  IS_SHARED_CODESPACE,
} from '@infinitus/utils/constants';
import { maybeExtractCorrelatedRequestId } from '@infinitus/utils/logCorrelator';
import { browserSessionUuid } from 'app';

require('setimmediate');

const prod = process.env.NODE_ENV === 'production';
const path_prefix = prod && process.env.REACT_APP_BUILD_ENV !== 'development' ? '' : '/api';

const realConsole = {
  log: window.console.log,
  warn: window.console.warn,
  error: window.console.error,
};

// winston gets cranky if we try to send log messages without a transport installed,
// so we'll keep this black hole installed for the cases where an HTTP transport
// isn't initialized (e.g. before orgUuid is determined).
class DevNull extends TransportStream {
  log(info: any, callback: () => void) {
    setImmediate(() => {
      this.emit('logged', info);
    });
    callback();
  }
}

let transport: winston.transports.HttpTransportInstance | null;

// RouteParams captures url params associated with the route patterns
type RouteParams = { callUuid?: string; orgName?: string; orgUuid?: string; taskUuid?: string };
const routesWithParams = [
  '/operator/:orgName/tasks/:taskUuid/calls/:callUuid/*',
  '/operator/:orgName/tasks/:taskUuid/*',
  '/operator/:orgName/*',
  '/admin/orgs/:orgUuid/*',
  '/*',
];

const LogForwarding = () => {
  const auth = useAuth();
  const location = useLocation();
  const params: RouteParams = useMemo(() => {
    const matches = matchRoutes(
      routesWithParams.map((route) => ({ path: route })),
      location
    );
    return !matches || matches.length === 0 ? {} : (matches[0].params as RouteParams);
  }, [location]);

  let orgUuid = '';
  if (params.orgUuid) {
    orgUuid = params.orgUuid;
  } else if (params.orgName) {
    orgUuid = auth.getOrgInfo(params.orgName).id;
  }

  const logger = useRef(
    winston.createLogger({
      format: winston.format.json(),
      transports: [new DevNull()],
    })
  );

  useEffect(() => {
    async function setupLogger() {
      // If we don't remove the last transport, then they will stack up and we'll start sending the same log message
      // on multiple channels.
      if (transport) {
        logger.current.remove(transport);
        transport = null;
      }
      // We can only forward logs when we have an orgUuid and the user has a JWT from firebase.
      if (auth.user && (auth.hasAdminAccess() || orgUuid)) {
        transport = new winston.transports.Http({
          ssl: (prod && process.env.REACT_APP_BUILD_ENV !== 'development') || IS_SHARED_CODESPACE,
          port: BACKEND_PORT,
          host: BACKEND_SERVER_HOST,
          path: `${path_prefix}/logFrontendMessage`,
          headers: {
            Authorization: await auth.user.getIdToken(),
            'X-INF-ORG-UUID': orgUuid,
            'X-INF-FRONTEND-VERSION': FRONTEND_VERSION,
            'X-INF-APP-NAME': AppName.OPERATOR,
          },
        });
        logger.current.add(transport);
      } else {
        console.log(`Skipping setting up Winston log transport as we don't have an OrgUuid`);
      }
    }

    setupLogger();

    // Cleanup
    return () => {
      if (transport && isFunction(logger.current?.remove)) {
        logger.current.remove(transport); // eslint-disable-line
        transport = null;
      }
    };
  }, [auth, orgUuid]);

  useEffect(() => {
    const log = (level: 'log' | 'warn' | 'error', currentUrl: string, messagesArray: any[]) => {
      const logBuffer = logBufferVar();
      const requestId = maybeExtractCorrelatedRequestId(messagesArray[0]);
      if (requestId) {
        messagesArray.shift();
      }
      const message = messagesArray.join(' ');
      // TODO: we probably  want to add a x-inf-request-id header to the log call
      // as well since it is technically a correlated log
      logger.current.log({
        level: level === 'log' ? 'info' : level,
        message,
        client_timestamp: Date.now(),
        frontend_version: FRONTEND_VERSION,
        current_url: currentUrl,
        log_buffer: level === 'error' ? logBuffer : undefined,
        browser_session_uuid: browserSessionUuid,
        request_id: requestId,
        ...params,
      });
      if (!requestId) {
        realConsole[level](...messagesArray);
      }
    };

    // We must provide Firebase with the ability to refresh the JWT.
    const refreshToken = async () => {
      if (!transport?.headers) {
        realConsole.warn('Attempting to log before a transport was established');
        return;
      }
      if (!auth.user) {
        realConsole.warn('LogForwarding: Unable to refresh token as auth.user is not available.');
        return;
      }
      transport.headers.Authorization = await auth.user.getIdToken();
    };

    window.console.log = (...messagesArray: any) => {
      refreshToken()
        .then(() => log('log', window.location.href, messagesArray))
        .catch((e) => {
          realConsole.error(`Failed to refresh token and log.log: ${e}`);
        });
    };
    window.console.warn = (...messagesArray: any) => {
      refreshToken()
        .then(() => log('warn', window.location.href, messagesArray))
        .catch((e) => {
          realConsole.error(`Failed to refresh token and log.warn: ${e}`);
        });
    };
    window.console.error = (...messagesArray: any) => {
      refreshToken()
        .then(() => log('error', window.location.href, messagesArray))
        .catch((e) => {
          realConsole.error(`Failed to refresh token and log.error: ${e}`);
        });
    };

    // I'm not even sure restoring these functions is necessary, but better safe than sorry.
    // We create a local variable for the closure to suppress a warning about orig's referent
    // possibly changing in the interim.
    return () => {
      assign(window.console, realConsole);
    };
  }, [auth.user, params]);

  return null;
};

export default LogForwarding;
