import { useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import useLocalStorage from '@rehooks/local-storage';
import sortBy from 'lodash/sortBy';
import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@infinitus/auth';
import { OrgMembership } from '@infinitus/auth/types';
import { IconButton } from '@infinitus/components/Button';
import { IconNames } from '@infinitus/components/Icon';
import { useSnackbar } from '@infinitus/hooks/useCustomSnackbar';
import useGetIdsFromUrl from '@infinitus/hooks/useGetIdsFromUrl';
import { UUID } from '@infinitus/types';
import ShirtSizes from '@infinitus/types/shirt-sizes';
import AutocompleteV5 from 'components/AutocompleteV5';
import { OrgRole } from 'generated/gql/graphql';
import { getOrgReadyPageUrl } from 'utils';
import { DEFAULT_ORG_NAME, DEFAULT_ORG_ID } from 'utils/localStorage';

function orgGroupingRank(orgMembership: OrgMembership) {
  const inProduction = orgMembership.isLive;
  const customerFacing = !orgMembership.isInternal;

  if (inProduction) {
    if (customerFacing) {
      return { rank: 1, label: 'Prod and Customer-facing' };
    }
    return { rank: 2, label: 'Prod but not Customer-facing' };
  }

  if (customerFacing) {
    return { rank: 3, label: 'Customer-facing but not Prod' };
  }
  return { rank: 4, label: 'Neither Prod nor Customer-facing' };
}

export function OrgSwitcher() {
  const { permissions, getOrgNamesWithRoles, getOrgInfo } = useAuth();
  var navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { orgName: orgNameFromParam, orgUuid: orgUuidFromParam } = useGetIdsFromUrl();
  const { hasAdminAccess } = useAuth();
  const isAdmin = hasAdminAccess();

  // Prefer to get orgName from params, if it's not there get it from localStorage
  const [lastOrgName, setLastOrgName] = useLocalStorage<string>('lastOrgName', DEFAULT_ORG_NAME);
  const [lastOrgId, setLastOrgId] = useLocalStorage<UUID>('lastOrgId', DEFAULT_ORG_ID);

  // Prefer to use orgInfo from params. Then look up orgInfo from localStorage.
  const orgName = orgNameFromParam || lastOrgName;
  const orgUuid = orgUuidFromParam || lastOrgId;

  const availableOrgs = getOrgNamesWithRoles([OrgRole.OPERATOR]);
  const allOrgs = permissions?.orgs || {};
  const sortedAvailableOrgs = sortBy(availableOrgs, [
    (availableOrg) => orgGroupingRank(allOrgs[availableOrg]).rank,
    (availableOrg) => allOrgs[availableOrg].displayName || '',
  ]);
  const choices = sortedAvailableOrgs.map((sortedOrg) => {
    return {
      label: (
        <div>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: 16,
            }}
            variant="subtitle1"
          >
            {allOrgs[sortedOrg].displayName}
          </Typography>
          <Typography
            sx={{
              color: theme.palette.text.secondary,
              fontSize: theme.spacing(1.5),
              marginBottom: '.25em',
              marginTop: '-.125em',
            }}
            variant="body2"
          >
            {sortedOrg}
          </Typography>
        </div>
      ),
      value: sortedOrg,
    };
  });

  function handleChange(selectedOrgName: string | null) {
    if (!selectedOrgName) {
      return;
    }

    if (selectedOrgName !== lastOrgName) {
      const orgInfo = getOrgInfo(selectedOrgName);
      const orgId = orgInfo.id;
      setLastOrgName(selectedOrgName);
      setLastOrgId(orgId);
      navigate(getOrgReadyPageUrl(selectedOrgName));
    }
  }

  const Autocomplete = (
    <AutocompleteV5
      blurOnSelect={true}
      choices={choices}
      disableClearable={true}
      getOptionLabel={(option) => {
        const orgName = typeof option === 'string' ? option : option.value;
        return allOrgs[orgName]?.displayName || orgName;
      }}
      groupBy={(choice) => orgGroupingRank(allOrgs[choice.value]).label}
      onChange={handleChange}
      size={ShirtSizes.SM}
      sx={{ minWidth: 256 }}
      value={orgName}
      variant="outlined"
    />
  );

  if (!isAdmin) return Autocomplete;

  // Render copy to clipboard button for admins
  return (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      {Autocomplete}
      <IconButton
        iconName={IconNames.CONTENT_COPY}
        iconSize={ShirtSizes.XS}
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          navigator.clipboard.writeText(orgUuid);
          enqueueSnackbar('Copied org UUID', {
            variant: 'success',
          });
          event.stopPropagation();
        }}
        size="small"
        title="Copy org UUID to clipboard"
      />
    </Stack>
  );
}
