import { useApolloClient } from '@apollo/client';
import Container from '@mui/material/Container';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useAuth } from '@infinitus/auth';
import Logo from 'components/Logo';
import { getLoginPageUrl } from 'utils';

const useClasses = () => {
  return {
    container: {
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      position: 'absolute',
    },
    grid: {
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    icon: {
      width: '100px',
      height: 'auto',
    },
  };
};

function SignOutPage() {
  const classes = useClasses();
  const navigate = useNavigate();
  const client = useApolloClient();
  const { signOut, isAuthenticated } = useAuth();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const maybeSignOut = async () => {
      if (isAuthenticated) {
        try {
          await signOut();
          await client.clearStore();
          enqueueSnackbar('Successfully signed out', { variant: 'success' });
        } catch (error) {
          const errMsg = 'Error while attempting to sign out';
          console.error(errMsg, error);
          enqueueSnackbar(errMsg, { variant: 'error' });
        }
      } else {
        navigate(getLoginPageUrl());
      }
    };
    void maybeSignOut();
  }, [isAuthenticated, navigate, client, signOut, enqueueSnackbar]);

  return (
    <Container maxWidth="sm" sx={classes.container}>
      <Grid2 container direction="column" spacing={4} sx={classes.grid}>
        <Grid2>
          <Logo style={classes.icon} />
        </Grid2>
      </Grid2>
    </Container>
  );
}

export default SignOutPage;
