import Tracker from '@openreplay/tracker';
import { useEffect } from 'react';

import { useAuth } from '@infinitus/auth';
import {
  OPEN_REPLAY_ENABLED_COHORT_KEY,
  openReplayParticipant,
  useExperiment,
} from '@infinitus/hooks/useExperiment';
import { isLocalhost } from '@infinitus/utils/isLocalhost';

const createTracker = () => {
  const ingestPoint = process.env.REACT_APP_OPENREPLAY_INGEST_POINT;
  const projectKey = process.env.REACT_APP_OPENREPLAY_PROJECT_KEY;
  // Set __DISABLE_SECURE_MODE to allow OpenReplay to work from localhost https://docs.openreplay.com/en/v1.7.0/sdk/constructor/#security
  const disableSecureMode = isLocalhost;
  if (!projectKey) {
    console.info(
      'REACT_APP_OPENREPLAY_PROJECT_KEY is not set. OpenReplay will not track this session.'
    );
    return undefined;
  }
  if (!ingestPoint) {
    console.info(
      'REACT_APP_OPENREPLAY_INGEST_POINT is not set. OpenReplay will not track this session.'
    );
    return undefined;
  }
  return new Tracker({
    projectKey,
    ingestPoint,
    __DISABLE_SECURE_MODE: disableSecureMode,
  });
};

// Global instance since there only needs to be one
const tracker = createTracker();

const useOpenReplayTracker = () => {
  const { user } = useAuth();
  const { isInCohort } = useExperiment({
    experiment: openReplayParticipant(user?.email || ''),
  });

  useEffect(() => {
    if (isInCohort(OPEN_REPLAY_ENABLED_COHORT_KEY) && tracker) {
      tracker.start();
      return () => {
        tracker.stop();
      };
    }
  }, [isInCohort]);

  useEffect(() => {
    if (tracker && user?.email) {
      tracker.setUserID(user.email);
    }
  }, [user?.email]);
};

export const OpenReplayTracker = () => {
  useOpenReplayTracker();

  return null;
};
