import { gql } from '@apollo/client';

export const AddressFieldsFragment = gql`
  fragment AddressFields on AddressInfo {
    state
    city
    street
    zip
  }
`;

export const INFAddressFieldsFragment = gql`
  fragment INFAddressFields on INFAddressInfo {
    state
    city
    streetAddress
    streetAddressLine2
    zip
  }
`;

export const DateFields = gql`
  fragment DateFields on Date {
    day
    month
    year
  }
`;

export const FieldMetadataFragment = gql`
  fragment Metadata on FieldMetadata {
    source
    sourceRecordId
    startTimeMillis
    endTimeMillis
  }
`;

export const AllCallInputsFragment = gql`
  ${AddressFieldsFragment}
  ${INFAddressFieldsFragment}
  ${DateFields}
  ${FieldMetadataFragment}
  fragment AllCallInputs on BVCallInputs {
    callerInfo {
      name
      entityName
      callbackPhoneNumber
      introSentence
    }
    patientInfo {
      firstName
      lastName
      birthday {
        ...DateFields
      }
      addressInfo {
        ...AddressFields
      }
      phoneNumber
    }
    productInfos {
      productName
      productCode
      productDescription
      productPronunciation
      productSpeciality
      productGenericName
      productType
      productSpecialty
      productCodes
      productAdditionalDescription
      productDaysSupply
      productNumberOfUnits
      productParentCode
      productOffLabel
    }
    adminCode
    practiceInfo {
      taxId
      name
      npi
      addressInfo {
        ...AddressFields
      }
    }
    providerInfo {
      name
      npi
      address
      addressInfo {
        ...AddressFields
      }
      taxId
      firstName
      lastName
      networkStatus
      ptan
    }
    policyInfo {
      memberId
      planName {
        metadata {
          ...Metadata
        }
        value
      }
      groupNumber {
        metadata {
          ...Metadata
        }
        value
      }
      otherInsuranceStatus
    }
    payerInfo {
      name
      infinitusId
      providedPhoneNumber
      resolvedPhoneNumber
      phoneNumber
      association
      resolvedChcTradingPartnerServiceId
    }
    treatmentInfo {
      treatmentCode
      secondaryTreatmentCode
      tertiaryTreatmentCode
      facilityType {
        type
      }
      anticipatedDateOfTreatment {
        ...DateFields
      }
      skipSpecialtyPharmacyInfo
    }
    adminCodes
    annualReenrollment
    sitesOfCare {
      address {
        ...INFAddressFields
      }
      alternateNames
      name
      npi
      taxId
    }
  }
`;

export const PayerIdFragment = gql`
  fragment PayerIdFragment on Task {
    bvInputs {
      payerInfo {
        infinitusId
      }
    }
    flexibleInputs {
      ...FlexibleInputFragment
    }
  }
`;
