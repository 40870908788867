import { QueryClient, QueryKey } from '@tanstack/react-query';
import { getAuth } from 'firebase/auth';

import { fetchApi } from 'api';

// Used for react query calls (e.g. useQuery(['admin', '123', 'c', 'getOrg']))
function createQueryApi() {
  return async function queryApi({ queryKey }: { queryKey: QueryKey }) {
    let [app, orgUuid, apiRoot, apiPath, apiBody] = queryKey;
    const url = `/${apiRoot}/${apiPath}`;
    const body = apiBody || {};
    return fetchApi({
      app: app as string,
      orgUuid: orgUuid as string,
      user: getAuth().currentUser,
      url,
      body,
    });
  };
}

const createQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 1000 * 60 * 5, // 5 min
        queryFn: createQueryApi(),
      },
    },
  });

export default createQueryClient;
