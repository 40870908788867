import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import throttle from 'lodash/throttle';
import * as React from 'react';

import { useAudioDevice } from './useAudioDevice';

const Keyframes = styled('div')({
  '@keyframes wave': {
    '0%': {
      height: '4px',
    },
    '100%': {
      height: '15px',
    },
  },
  display: 'flex',
  width: '26px',
  height: '26px',
  padding: '3px',
  borderRadius: '100px',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: '#4285f4',
});

interface Props {
  enabled: boolean;
  onAudioDetected?: () => void;
}

export function AudioDetection({ enabled, onAudioDetected }: Props) {
  const [error, setError] = React.useState(false);
  const [audioDetected, setAudioDetected] = React.useState(false);
  const { deviceId, devicesHash } = useAudioDevice();
  const audioContextRef = React.useRef<AudioContext | null>(null);
  const currentStreamRef = React.useRef<MediaStream | null>(null);

  // Throttle the callback to avoid calling it too often
  const throttledOnAudioDetected = React.useMemo(
    () =>
      throttle(() => {
        if (onAudioDetected) onAudioDetected();
      }, 3 * 1000),
    [onAudioDetected]
  );

  React.useEffect(() => {
    if (audioDetected) {
      throttledOnAudioDetected();
    }
  }, [audioDetected, throttledOnAudioDetected]);

  // Detect audio
  React.useEffect(() => {
    let intervalCallback: NodeJS.Timer | null = null;

    // Check if audio is detected
    function checkAudio(analyser: AnalyserNode) {
      const bufferLength = analyser.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);
      analyser.getByteFrequencyData(dataArray);

      // Compute volume as the average of frequency values
      let volume = dataArray.reduce((a, b) => a + b) / bufferLength;

      // Only detect audio if the volume is above a threshold
      const isAudioDetected = volume > 10;
      setAudioDetected(isAudioDetected);
    }

    // Get the audio stream
    if (navigator.mediaDevices && enabled) {
      navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: deviceId ? { exact: deviceId.id } : undefined,
            autoGainControl: false,
            echoCancellation: true,
            noiseSuppression: true,
          },
        })
        .then((newStream) => {
          audioContextRef.current?.close();
          audioContextRef.current = new AudioContext();
          currentStreamRef.current?.getTracks().forEach((track) => track.stop());
          currentStreamRef.current = newStream;
          const source = audioContextRef.current.createMediaStreamSource(newStream);
          const analyser = audioContextRef.current.createAnalyser();
          source.connect(analyser);
          intervalCallback = setInterval(() => checkAudio(analyser), 150);
          setError(false);
        })
        .catch(() => {
          // If the user doesn't give permission to access the microphone, we can't detect audio
          setError(true);
        });
    }

    return () => {
      audioContextRef.current?.close();
      audioContextRef.current = null;
      currentStreamRef.current?.getTracks().forEach((track) => track.stop());
      currentStreamRef.current = null;
      if (intervalCallback) clearInterval(intervalCallback);
    };
  }, [deviceId, devicesHash, enabled]);

  const barStyles = {
    width: '4px',
    height: '4px',
    margin: '0 1.5px',
    borderRadius: '20px',
    backgroundColor: '#f0f0f0',
    animation: audioDetected ? 'wave 0.3s infinite alternate' : 'none',
  };

  if (!enabled || error) return null;

  return (
    <Keyframes>
      <Box sx={{ ...barStyles, animationDelay: '0.2s' }} />
      <Box sx={{ ...barStyles, animationDelay: '0.4s' }} />
      <Box sx={{ ...barStyles, animationDelay: '0.6s' }} />
    </Keyframes>
  );
}
