import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Zoom from '@mui/material/Zoom';
import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useAuth } from '@infinitus/auth';
import { useSnackbar } from '@infinitus/hooks/useCustomSnackbar';
import { Link } from 'components/Link';
import Logo from 'components/Logo';
import { Page } from 'components/Page';
import { UserStatus, UserInviteStatus } from 'generated/gql/graphql';
import { getSignOutPageUrl } from 'utils';

import { useUnauthorizedPageQuery, useAcceptUserInviteMutation } from './graphql';

const ZOOM_DELAY = 3000;

const useClasses = () => {
  return {
    cardRoot: {
      padding: 24,
      marginTop: 48,
      minWidth: 275,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    cardTitle: {
      marginTop: 16,
      fontWeight: 'bold',
      textAlign: 'center',
    },
    cardSubTitle: {
      fontSize: 16,
      marginTop: 20,
      textAlign: 'center',
    },
    cardAction: {
      marginRight: 4,
      marginLeft: 4,
    },
    icon: {
      width: '4rem',
      height: 'auto',
    },
    grid: {
      height: '100%',
      alignItems: 'center',
      justifyContent: 'center',
    },
    loadingIcon: {
      width: '100px',
      height: 'auto',
    },
    text: {
      textAlign: 'center',
    },
    signOutButton: {
      marginTop: '1em',
      color: 'black',
    },
  };
};

function UnauthorizedPage() {
  const classes = useClasses();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, permissions, isActive, hasUserAccess } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const { data: pageData, loading: pageLoading } = useUnauthorizedPageQuery();
  const [acceptUserInvite, { loading: acceptUserInviteLoading }] = useAcceptUserInviteMutation();
  const userEmail = user?.email || '';
  const userStatus = permissions?.status || UserStatus.UNKNOWN;

  useEffect(() => {
    const handleAcceptUserInvite = async () => {
      try {
        await acceptUserInvite();
        setSearchParams(searchParams.get('from') || '/');
      } catch (e: any) {
        enqueueSnackbar(`Failed to accept invite: ${e?.response?.data || e.message}.`, {
          variant: 'error',
        });
      }
    };
    if (pageData?.userInvite?.status === UserInviteStatus.PENDING) {
      handleAcceptUserInvite();
    }
  }, [acceptUserInvite, pageData, enqueueSnackbar, searchParams, setSearchParams]);

  const signOutButton = (
    <Box alignItems="center" display="flex" flexDirection="column" justifyContent="center" mt={4}>
      <Typography>Signed in as {userEmail}</Typography>
      <Button
        color="inherit"
        onClick={() => {
          navigate(getSignOutPageUrl());
        }}
        sx={classes.signOutButton}
        variant="contained"
      >
        Sign Out
      </Button>
    </Box>
  );

  if (isActive() && hasUserAccess()) {
    navigate(searchParams.get('from') || '/');
    return null;
  }

  let content;
  if (pageLoading) content = <div />;
  else if (acceptUserInviteLoading || pageData?.userInvite?.status === UserInviteStatus.PENDING) {
    content = (
      <Box display="flex" justifyContent="center" mt={12}>
        <Zoom in={true} timeout={{ enter: ZOOM_DELAY }}>
          <Logo style={classes.loadingIcon} />
        </Zoom>
      </Box>
    );
  } else if (userStatus === UserStatus.PENDING) {
    content = (
      <>
        <Box display="flex" justifyContent="center" mt={12}>
          <Link to="/">
            <Logo style={classes.icon} variant="lockup" />
          </Link>
        </Box>
        <Box display="flex" justifyContent="center" mt={4}>
          <Typography variant="h3">Access Request Pending</Typography>
        </Box>
        <Box display="flex" justifyContent="center" mt={4}>
          <Typography sx={classes.text} variant="body1">
            Your request for access has been received and is pending review.
          </Typography>
        </Box>
        {signOutButton}
      </>
    );
  } else {
    content = (
      <>
        <Box display="flex" justifyContent="center" mt={12}>
          <Link to="/">
            <Logo style={classes.icon} variant="lockup" />
          </Link>
        </Box>
        <Box display="flex" justifyContent="center" mt={4}>
          <Typography variant="h3">Unauthorized</Typography>
        </Box>
        <Box
          alignItems="center"
          display="flex"
          flexDirection="column"
          justifyContent="center"
          mt={4}
        >
          <Typography sx={classes.text} variant="body1">
            You are not authorized to access this page.
          </Typography>
        </Box>
        {!user ? null : signOutButton}
      </>
    );
  }

  return (
    <Page metaTitle={userStatus === UserStatus.PENDING ? 'Access request pending' : 'Unauthorized'}>
      {content}
    </Page>
  );
}

export default UnauthorizedPage;
