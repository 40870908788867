import { ProviderContext } from 'notistack';
import { $enum } from 'ts-enum-util';

import { HeartbeatMessageType } from '@infinitus/hooks/useOperatorPresence/types';
import { infinitusai } from '@infinitus/proto/pbjs';
import { lowercase } from '@infinitus/utils';
import { OPERATOR_PORTAL_URL, getOperatorPortalUrl } from '@infinitus/utils/constants';
import { getOrgReadyPageUrl } from 'utils';

type HandleOnOperatorShouldLeaveCall = {
  data: infinitusai.be.OperatorShouldLeaveCallMessage;
  enqueueSnackbar: ProviderContext['enqueueSnackbar'];
  getHeartbeatMessageType: () => HeartbeatMessageType;
  getOrgNameFromUuid: (orgUuid: string) => string;
  navigate: (url: string) => void;
  userEmail: string;
};
export function handleOnOperatorShouldLeaveCall({
  data,
  enqueueSnackbar,
  getHeartbeatMessageType,
  getOrgNameFromUuid,
  navigate,
  userEmail,
}: HandleOnOperatorShouldLeaveCall) {
  console.log(
    `Received an OperatorShouldLeaveCallMessage with payload ${JSON.stringify(
      data
    )} via the websocket connection...`
  );
  // Check if user is on call the call page
  if (getHeartbeatMessageType() !== HeartbeatMessageType.CALL_PAGE) {
    console.log('Ignoring OperatorShouldLeaveCallMessage b/c the user is not on the ready page');
    return;
  }
  // Check if the recipient
  if (data.operatorEmail !== userEmail) {
    console.error(
      `Received a server websocket message to leave call='${data.callUuid}' for operator '${data.operatorEmail}', but we are signed in as '${userEmail}'`
    );
    return;
  }
  // Check if the recipient has access to the org
  const orgName = getOrgNameFromUuid(data.orgUuid);
  if (!orgName) {
    const errorMsg = `Attempted to redirect operator ${userEmail} to ready page in the org ${data.orgUuid}, which they are not authorized in.`;
    console.error(errorMsg);
    enqueueSnackbar(errorMsg, {
      variant: 'error',
    });
    return;
  }

  console.log('Processing the OperatorShouldLeaveCallMessage');

  // Convert to lowercase key to match REACT_APP_BUILD_ENV format
  const targetEnvironmentKey = $enum(infinitusai.be.OperatorPortalEnvironment).getKeyOrDefault(
    data.targetEnvironment,
    'ANY'
  );
  const currentPortalUrl = OPERATOR_PORTAL_URL;
  const targetPortalUrl =
    targetEnvironmentKey === 'ANY'
      ? currentPortalUrl
      : getOperatorPortalUrl(lowercase(targetEnvironmentKey));

  console.log(
    `Redirecting operator to ready page for orgUuid=${data.orgUuid} in response to a server websocket message`
  );

  // Note that there is a logic in Operator Portal that redirects the user to unified queue ready page
  // upon landing on org specific ready page if the unified queue is enable for that org
  const orgSpecificReadyPageUrl = getOrgReadyPageUrl(orgName);
  const willChangeEnvironment = targetPortalUrl !== currentPortalUrl;
  if (willChangeEnvironment) {
    console.log(
      `Leave call message is targeting ${targetPortalUrl}, currently on ${currentPortalUrl}`
    );
    enqueueSnackbar(
      `Redirecting to ${targetEnvironmentKey.toLowerCase()} before redirecting to ready page...`,
      {
        variant: 'info',
      }
    );
    window.location.href = `${targetPortalUrl}${orgSpecificReadyPageUrl}`;
  } else {
    navigate(orgSpecificReadyPageUrl);
  }
}
