import { User, UserCredential } from 'firebase/auth';

import { UserRole, OrgRole, UserStatus } from '@infinitus/generated/frontend-common';

/**
 * Operator Info type.
 */
export interface OperatorInfo {
  canReview: boolean;
  selectiveReviewEnabled: boolean;
  selectiveReviewOrgEligibility: string[];
}

/**
 * Org membership type.
 */
export interface OrgMembership {
  displayName: string;
  enableTesting: boolean;
  id: string;
  imageUrl: string;
  // Org Settings
  isInternal: boolean;
  isLive: boolean;
  name: string;
  role: OrgRole;
}

/**
 * Auth permissions interface.
 */
export interface AuthPermissions {
  operatorInfo?: OperatorInfo;
  orgs: { [name: string]: OrgMembership };
  role: UserRole;
  status: UserStatus;
}

/**
 * The initial auth state.
 */
export const initialAuthState: AuthState = {
  isAuthenticated: false,
  isLoading: true,
  signOutHandlers: new Set<() => Promise<void>>(),
};

/**
 * The auth state which, when combined with the auth methods, make up the return object of the `useAuth` hook.
 */
export default interface AuthState<TUser extends User = User> {
  error?: Error;
  isAuthenticated: boolean;
  isLoading: boolean;
  permissions?: AuthPermissions;
  signOutHandlers: Set<() => Promise<void>>;
  user?: TUser;
}

/**
 * Contains the authenticated state and authentication methods provided by the `useAuth` hook.
 */
export interface AuthContextInterface<TUser extends User = User> extends AuthState<TUser> {
  /**
   * Gets org info for given org name.
   */
  getOrgInfo: (orgName: string) => OrgMembership;

  /**
   * Gets org name for given org uuid.
   */
  getOrgNameFromUuid: (orgUuid: string) => string;

  /**
   * Gets all org names where a user has one of a given list of roles.
   */
  getOrgNamesWithRoles: (orgRoles: OrgRole[]) => string[];

  /**
   * Determines if a user has access to skip review.
   */
  getSelectiveReviewStatus: (orgName: string) => { eligible: boolean; enabled: boolean };

  /**
   * Determines if a user has admin access.
   */
  hasAdminAccess: () => boolean;

  /**
   * Determines if a user has org operator access.
   */
  hasOrgOperatorAccess: (orgName: string) => boolean;

  /**
   * Determines if a user has access to review tasks.
   */
  hasTaskReviewAuthorization: () => boolean;

  /**
   * Determines if a user has user access.
   */
  hasUserAccess: () => boolean;

  /**
   * Determines if a user is active.
   */
  isActive: () => boolean;

  /**
   * Registers callback which will be called when the user signs out.
   */
  onSignOut: (signOutHandler: () => Promise<void>) => void;

  /**
   * Asynchronously signs in using a custom token.
   */
  signInWithCustomToken: (token: string) => Promise<UserCredential>;

  /**
   * Signs the user in with the google provider.
   */
  signInWithGoogle: () => void;

  /**
   * Signs out the current user.
   */
  signOut: () => Promise<void>;
}
