import { useAuth } from '@infinitus/auth';
import useCallParams from '@infinitus/hooks/useCallParams';

function useGetIdsFromUrl() {
  const { getOrgInfo } = useAuth();
  const { orgName, taskUuid, callUuid } = useCallParams();
  const orgInfo = getOrgInfo(orgName);
  if (orgName && Object.keys(orgInfo).length === 0) {
    console.error(
      `useGetIdsFromUrl attempted to retrieve orgInfo for org '${orgName}' but no data was available`
    );
  }

  return {
    callUuid,
    displayName: orgInfo.displayName,
    orgName,
    orgUuid: orgInfo.id,
    taskUuid,
  };
}

export default useGetIdsFromUrl;
