import { ThemeProvider } from '@mui/material';
import CssBaseline from '@mui/material/CssBaseline';
import { useState } from 'react';
import { useMatch } from 'react-router-dom';

import MuiV5Theme from 'styles/MuiV5Theme';

import { AppHeader } from './AppHeader';
import { Drawer } from './Drawer';

interface Props {
  children: React.ReactNode;
}

function Layout({ children }: Props) {
  const loginRoute = useMatch('login');
  const logoutRoute = useMatch('logout');
  const route404 = useMatch('404');
  const route403 = useMatch('403');

  const hideAppWrapper = Boolean(loginRoute ?? logoutRoute ?? route404 ?? route403);

  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <CssBaseline />
      <div
        style={{
          backgroundColor: MuiV5Theme.palette.background.default,
          display: 'flex',
          height: '100vh',
        }}
      >
        {!hideAppWrapper && (
          <ThemeProvider theme={MuiV5Theme}>
            <AppHeader onMenuButtonClick={() => setOpenDrawer(!openDrawer)} />
            <Drawer isOpen={openDrawer} onMouseLeave={() => setOpenDrawer(false)} />
          </ThemeProvider>
        )}
        <main
          style={{
            color: MuiV5Theme.palette.text.primary,
            flexGrow: 1,
            marginBlockStart: MuiV5Theme.spacing(6),
            marginInlineStart: !hideAppWrapper ? MuiV5Theme.spacing(7) : undefined,
            overflowX: 'auto',
            padding: MuiV5Theme.spacing(0, 3),
            width: `calc(100vw - ${MuiV5Theme.spacing(6)})`,
            zIndex: MuiV5Theme.zIndex.appBar - 2,
          }}
        >
          {children}
        </main>
      </div>
    </>
  );
}

export default Layout;
