import { gql } from '@apollo/client';

import { OutputValueFragment } from '@infinitus/fragments/outputs';

export const OutputsFragment = gql`
  fragment OutputsFragment on Output {
    name
    value {
      ...OutputValueFragment
    }
    agentCouldNotAnswer
    didTriggerRequeue
    source
    sourceRecordId
    additionalSources {
      value {
        ...OutputValueFragment
      }
      source
      sourceRecordId
    }
    isRelevant
    refreshConversation
  }
  ${OutputValueFragment}
`;

export const CallOutputsConfigFragment = gql`
  fragment CallOutputsConfigFragment on OutputConfig {
    id
    category
    hints
    hintsOverrides {
      orgUUID
      newHints
    }
    label
    labelOverrides {
      orgUUID
      newLabel
    }
    name
    params
    taskType
    type
    values
    valuesOverrides {
      orgUUID
      newValues
    }
    visibleToCustomer
    listOfOrgUuids
    autoPopulateFromStatPie
    reviewOptional
    disableIdv
    requiredForCallCompletion
  }
`;
