import { ReactNode } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

import { AuthContextInterface } from './types';

interface Props {
  auth: AuthContextInterface;
  children?: ReactNode;
}

function AuthRoute({ auth, children }: Props) {
  const isAuthorized = auth.isActive() && auth.hasUserAccess();
  const { pathname, search } = useLocation();

  if (!auth.isAuthenticated) {
    return <Navigate replace to={`/login?from=${pathname}${search}`} />;
  }
  if (!isAuthorized) {
    return <Navigate replace to={`/403?from=${pathname}`} />;
  }
  return <Outlet />;
}

export default AuthRoute;
