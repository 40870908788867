import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Logo from 'components/Logo';
import { Page } from 'components/Page';

function NotFoundPage() {
  return (
    <Page metaTitle="Page Not Found">
      <Box display="flex" justifyContent="center" mt={12}>
        <Logo style={{ width: '16rem' }} variant="lockup" />
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
        <Typography variant="h3">Page Not Found</Typography>
      </Box>
      <Box display="flex" justifyContent="center" mt={4}>
        <Typography variant="body1">
          The page you are looking for does not exist, try a different page.
        </Typography>
      </Box>
    </Page>
  );
}

export default NotFoundPage;
