import { Button, ButtonProps, CircularProgress, Stack, Typography, styled } from '@mui/material';
import getBlobDuration from 'get-blob-duration';
import numeral from 'numeral';
// @ts-ignore
import Recorder from 'opus-recorder';
// @ts-ignore
import encoderPath from 'opus-recorder/dist/waveWorker.min.js';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Button as InfButton } from '@infinitus/components/Button';
import { GreetingsChip } from '@infinitus/components/GreetingsChip';
import { Icon, IconNames } from '@infinitus/components/Icon';
import { RecordedGreetingApprovalStatus } from '@infinitus/generated/frontend-common';
import VisualVariant from '@infinitus/types/visual-variant-types';

export interface Greeting {
  approvalStatus?: RecordedGreetingApprovalStatus | null;
  gcsSignedURL: string | null;
  greetingName: string;
  recordedText: string;
}

interface GreetingProps {
  disableShowIndex?: boolean; // optional: shows greeting index (starts from 1...)
  existingAudioDataBlob: Blob | undefined;
  greeting: Greeting;
  hasUnsavedChanges?: boolean;
  // Used to display a greeting number for humans to read
  index: number;
  // optional: shows GreetingName
  isLoading?: boolean;
  // To be called when the user completes recording a wave
  onWaveRecordingAvailable: (greetingName: string, blob: Blob | null) => void;
  showGreetingName?: boolean;
}

// Time in ms between refreshes of the recording elapsed time
const ELAPSED_TIME_REFRESH_INTERVAL = 100;

const PREFIX = 'GreetingItem';
const classes = {
  root: `${PREFIX}-root`,
  recordingNumber: `${PREFIX}-recordingNumber`,
  recordAgain: `${PREFIX}-recordAgain`,
  quote: `${PREFIX}-quote`,
  greetingText: `${PREFIX}-greetingText`,
  greetingName: `${PREFIX}-greetingName`,
};
const GreetingItemStyled = styled(Stack)(({ theme }) => ({
  [`&.${classes.root}`]: {},
  [`& .${classes.recordingNumber}`]: {
    fontSize: '1.5em',
    fontWeight: 'bold',
    color: theme.palette.text.secondary,
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(1),
  },
  [`& .${classes.recordAgain}`]: {
    textTransform: 'lowercase',
    paddingTop: 0,
    paddingBottom: 0,
  },
  [`& .${classes.quote}`]: {
    fontSize: '1.5em',
    color: theme.palette.text.secondary,
  },
  [`& .${classes.greetingText}`]: {
    fontSize: '1.1em',
  },
  [`& .${classes.greetingName}`]: {
    fontSize: '1.2em',
    fontWeight: 'bold',
    color: theme.palette.text.primary,
  },
}));

const RecordingControlButton = (props: ButtonProps) => (
  <Button
    size="small"
    sx={{
      minWidth: 0,
    }}
    variant="outlined"
    {...props}
  />
);

export const GreetingItem: React.FC<GreetingProps> = ({
  index,
  hasUnsavedChanges = false,
  greeting,
  onWaveRecordingAvailable,
  existingAudioDataBlob,
  showGreetingName = false,
  disableShowIndex = false,
  isLoading = false,
}) => {
  const [isRecording, setRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [recordingDurationMillis, setRecordingDurationMillis] = useState<number>(0);
  const [elapsedTimerId, setElapsedTimerId] = useState<number>(0);
  const [audioDataBlob, setAudioDataBlob] = useState<Blob>();

  const audioRef = useRef<HTMLAudioElement | null>(null);

  useEffect(() => {
    (async () => {
      if (audioDataBlob) {
        let blobDuration = await getBlobDuration(audioDataBlob);
        setRecordingDurationMillis(blobDuration * 1000);
      }
    })();
  }, [audioDataBlob]);

  useEffect(() => {
    if (existingAudioDataBlob) {
      setAudioDataBlob(existingAudioDataBlob);
    }
  }, [existingAudioDataBlob]);

  const getRecorder = () => {
    // Note that wav resampling is not supported, so it's going to be sent at 44Khz and will need
    // to be resampled on the backend.
    const r = new Recorder({
      encoderPath,
      wavBitDepth: 16,
      numberOfChannels: 1,
    });

    r.onstart = () => {
      setRecording(true);
      const recordingStartMillis = Date.now();

      const timerId = window.setInterval(() => {
        setRecordingDurationMillis(Date.now() - recordingStartMillis);
      }, ELAPSED_TIME_REFRESH_INTERVAL);

      setElapsedTimerId(timerId);
    };

    r.onstreamerror = (e: Error) => {
      console.error(`A stream error occurred: ${e}`);
    };

    r.ondataavailable = (typedArray: any) => {
      const audioBlob = new Blob([typedArray], { type: 'audio/wav' });
      setAudioDataBlob(audioBlob);
      onWaveRecordingAvailable(greeting.greetingName, audioBlob);
    };

    return r;
  };

  const recorder = useMemo(getRecorder, [greeting.greetingName, onWaveRecordingAvailable]);

  const handleRecordClicked = () => {
    recorder.start().catch((e: Error) => {
      console.error(`Failed to start recording: ${e}`);
    });
  };

  const handleRecordAgainClicked = () => {
    setAudioDataBlob(undefined);
    onWaveRecordingAvailable(greeting.greetingName, null);
  };

  const handleStopClicked = () => {
    if (isRecording) {
      window.clearInterval(elapsedTimerId);
      recorder.stop();
      setRecording(false);
      setElapsedTimerId(0);
    } else if (isPlaying) {
      setIsPlaying(false);
      audioRef?.current?.pause();
    } else {
      console.error(
        `invalid state for handleStopClicked isRecording: ${isRecording} isPlaying: ${isPlaying}`
      );
    }
  };

  const handlePlayClicked = () => {
    if (!audioDataBlob) {
      console.warn('Play clicked, but no audio buffer available.');
      return;
    }
    if (!audioRef?.current) {
      console.warn('Missing ref to audio tag. Cannot playback audio recording.');
      return;
    }

    const url = window.URL.createObjectURL(audioDataBlob);
    audioRef.current.src = url;
    audioRef.current.play();
    setIsPlaying(true);
  };

  const handleMediaPlaybackEnded = () => {
    setIsPlaying(false);
  };

  const getRecordingDuration = (): string => {
    return `${numeral(recordingDurationMillis / 1000).format('0.00')} seconds`;
  };

  return (
    <GreetingItemStyled className={classes.root}>
      <Stack direction="row" flexWrap="nowrap">
        {disableShowIndex ? null : <div className={classes.recordingNumber}>{index + 1}.</div>}
        <Stack>
          {showGreetingName && (
            <Typography sx={{ fontSize: '0.9em', textTransform: 'capitalize' }} variant="h6">
              <span className={classes.greetingName}>
                {greeting.greetingName.replace(/_/g, ' ')}
              </span>
            </Typography>
          )}
          <Typography sx={{ mb: 1 }}>
            <span className={classes.quote}>&ldquo;</span>
            <span className={classes.greetingText}>{greeting.recordedText}</span>
            <span className={classes.quote}>&rdquo;</span>
          </Typography>
          <Stack alignItems="center" direction="row" spacing={1}>
            {isRecording ? (
              <>
                <RecordingControlButton onClick={handleStopClicked}>
                  <Icon name={IconNames.STOP} />
                </RecordingControlButton>

                <div>{getRecordingDuration()} (recording)</div>
              </>
            ) : isPlaying ? (
              <>
                <RecordingControlButton onClick={handleStopClicked}>
                  <Icon name={IconNames.STOP} />
                </RecordingControlButton>
                <div>{getRecordingDuration()} (playing)</div>
              </>
            ) : (
              <>
                {isLoading ? (
                  <CircularProgress />
                ) : existingAudioDataBlob ? (
                  <>
                    <RecordingControlButton onClick={handlePlayClicked}>
                      <Icon name={IconNames.PLAY_CIRCLE} />
                    </RecordingControlButton>
                    <div>{getRecordingDuration()}</div>
                    <InfButton
                      className={classes.recordAgain}
                      onClick={handleRecordAgainClicked}
                      text="record again"
                      variant={VisualVariant.OUTLINED}
                    />
                    {greeting.approvalStatus && !hasUnsavedChanges && (
                      <GreetingsChip
                        status={
                          greeting.approvalStatus ===
                          RecordedGreetingApprovalStatus.GREETING_APPROVED
                            ? 'APPROVED'
                            : 'PENDING'
                        }
                      />
                    )}
                    {hasUnsavedChanges && (
                      <GreetingsChip label="Unsaved Changes" status="MISSING" />
                    )}
                  </>
                ) : (
                  <>
                    <RecordingControlButton onClick={handleRecordClicked}>
                      <Icon name={IconNames.RADIO_BUTTON_CHECKED} />
                    </RecordingControlButton>
                    <div>ready to record</div>
                  </>
                )}
              </>
            )}
            <audio controls={false} onEnded={handleMediaPlaybackEnded} ref={audioRef} />
          </Stack>
        </Stack>
      </Stack>
    </GreetingItemStyled>
  );
};
