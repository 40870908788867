import { gql } from '@apollo/client';

export const OutputValueFragment = gql`
  fragment OutputValueFragment on OutputValue {
    ... on StringType {
      string
    }
    ... on IntType {
      int
    }
    ... on BooleanType {
      bool
    }
    ... on MoneyType {
      amountInCents
    }
    ... on EnumType {
      enum
    }
    ... on EnumsType {
      enums
    }
    ... on DateType {
      year
      month
      day
    }
    ... on AgentCouldNotAnswerType {
      agentCouldNotAnswer
    }
  }
`;

export const CallOutputsFragment = gql`
  fragment CallOutputsFragment on CallOutputs {
    outputs {
      name
      value {
        ...OutputValueFragment
      }
      agentCouldNotAnswer
      didTriggerRequeue
      source
      sourceRecordId
      additionalSources {
        value {
          ...OutputValueFragment
        }
        source
        sourceRecordId
      }
      isRelevant
      refreshConversation
    }
  }
  ${OutputValueFragment}
`;
