import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import Helmet from 'react-helmet';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '@infinitus/auth';
import { FRONTEND_VERSION } from '@infinitus/utils/constants';
import Logo from 'components/Logo';

import { ReactComponent as GoogleLogo } from '../../assets/google.svg';

function SignInPage() {
  const location = useLocation();
  const { isAuthenticated, signInWithGoogle, signInWithCustomToken } = useAuth();
  const searchParams = new URLSearchParams(location.search);

  if (isAuthenticated) {
    return <Navigate replace to={searchParams.get('from') || '/'} />;
  } else if (
    (FRONTEND_VERSION === 'development' || process.env.REACT_APP_BUILD_ENV === 'unstable-dev') &&
    searchParams.get('token')
  ) {
    console.log(`Development mode: will attempt to login with token: ${searchParams.get('token')}`);
    signInWithCustomToken(searchParams.get('token') ?? '');
  }

  return (
    <div style={{ marginTop: '8em', paddingBottom: 0, padding: '1em' }}>
      <Helmet title="Sign In" />
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Box
          alignItems="center"
          border="1px solid #D9D9D9"
          borderRadius="10px"
          display="flex"
          flexDirection="column"
          height="250px"
          justifyContent="center"
          width="500px"
        >
          <Logo
            style={{
              width: 'auto',
              height: '75px',
              padding: '16px',
              paddingBottom: 0,
              marginTop: '-100px',
              backgroundColor: '#303030',
            }}
          />
          <Typography sx={{ marginTop: '40px', fontSize: '1.7rem' }}>
            Sign in to the Infinitus Portal
          </Typography>
          <Box alignItems="center" mb={6} mt={4}>
            <Button
              className="signInButton"
              onClick={() => {
                signInWithGoogle();
              }}
              sx={{
                color: 'white',
                width: '270px',
                height: '35px',
                fontWeight: 'bold',
                borderRadius: '3px',
                textTransform: 'unset',
                display: 'inline-flex',
                alignItems: 'center',
                alignContent: 'center',
                bgcolor: '#4285f4',
                '&:hover': {
                  bgcolor: '#4285f4',
                },
                border: '1px solid #4285f4',
              }}
              variant="contained"
            >
              <Grid2
                alignContent="center"
                alignItems="center"
                bgcolor="white"
                borderRadius="3px 0 0 3px"
                display="flex"
                height="30px"
                justifyContent="center"
                ml="-15px"
                width="30px"
              >
                <GoogleLogo height="22px" width="22px" />
              </Grid2>
              <Grid2 sx={{ flexGrow: 1 }}>Sign in with Google</Grid2>
            </Button>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default SignInPage;
