import { SxProps } from '@mui/material';

import MuiV5Theme from 'styles/MuiV5Theme';

import { expandTransitionDuration, expandedSize, sizeDiff } from '../LayoutConstants';

export const baseDrawerSx: SxProps<typeof MuiV5Theme> = {
  backgroundColor: 'grey.800',
  boxShadow: 19,
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingBlockStart: 7,
  position: 'fixed',
  insetBlockEnd: 0,
  insetBlockStart: 0,
  insetInlineStart: `-${sizeDiff}px`,
  scrollbarGutter: 'stable',
  transform: `translate3d(0,0,0)`,
  transitionDuration: expandTransitionDuration,
  width: `${expandedSize}px`,
  zIndex: MuiV5Theme.zIndex.appBar - 1,
  '&::-webkit-scrollbar': {
    width: 8,
  },
};

export const drawerOpenSx: SxProps<typeof MuiV5Theme> = {
  transform: `translate3d(${sizeDiff}px,0,0)`,
};
