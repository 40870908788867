import { initializeApp } from 'firebase/app';

const gcpConfig = {
  projectId: process.env.REACT_APP_GCP_PROJECT_ID,
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: gcpConfig.projectId + '.firebaseapp.com',
  databaseURL: 'https://' + gcpConfig.projectId + '.firebaseio.com',
  projectId: gcpConfig.projectId,
  storageBucket: gcpConfig.projectId + '.appspot.com',
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

export function initFirebase() {
  initializeApp(firebaseConfig);
}
