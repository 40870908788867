import { ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import { useState } from 'react';
import KeyboardEventHandler from 'react-keyboard-event-handler';

import useConditionalSx from '@infinitus/hooks/useConditionalSx';
import MuiV5Theme from 'styles/MuiV5Theme';

import AppNav from '../AppNav';
import { baseDrawerSx, drawerOpenSx } from './DrawerStyles';

interface DrawerProps {
  isOpen: boolean;
  onMouseLeave: () => void;
}

export function Drawer({ onMouseLeave, isOpen }: DrawerProps) {
  const [isHovered, setHovered] = useState(false);
  const drawerSx = useConditionalSx(baseDrawerSx, [isOpen, drawerOpenSx]);
  const handleMouseLeave = () => {
    if (isHovered) onMouseLeave();
  };

  return (
    <ThemeProvider theme={MuiV5Theme}>
      <Box onMouseEnter={() => setHovered(true)} onMouseLeave={handleMouseLeave} sx={drawerSx}>
        <KeyboardEventHandler
          handleEventType="keydown"
          handleFocusableElements={true}
          handleKeys={['esc']}
          onKeyEvent={handleMouseLeave}
        />
        <AppNav isExpanded={isOpen} />
      </Box>
    </ThemeProvider>
  );
}
