import { gql, useQuery } from '@apollo/client';

import useAuth from '@infinitus/auth/useAuth';
import { EngAccessFragment, EngAccessQuery } from '@infinitus/generated/frontend-common';

// @TODO Add RBAC support for a UI test engineer role that as visual permissions to engineer
// features. For now we use this list.
const TEST_USER_EMAILS = ['dashboard.pi@infinitus.ai'];

export const engAccessFragment = gql`
  fragment EngAccess on Query {
    currentUser {
      isEngineer
    }
  }
`;

// Only used in OperatorPortal
export const engAccessQuery = gql`
  query EngAccess {
    ...EngAccess
  }
  ${engAccessFragment}
`;

export type UseEngAccess = {
  hasEngAccess?: boolean;
  loading: boolean;
};

/**
 * Determining isEngineer status is slow, so we want to avoid asking for
 * it on every page load when only a few components need it.
 */
export const useEngAccess = (): UseEngAccess => {
  const { data, loading } = useQuery<EngAccessQuery>(engAccessQuery);
  const { user } = useAuth();
  const email = user?.email ?? undefined;
  return {
    hasEngAccess: hasEngAccess(data, email),
    loading,
  };
};

/**
 * Use this when you can embed the EngAccessFragment in your query.
 * @param queryResult Query with EngAccessFragment embedded in it
 */
export const hasEngAccess = (queryResult?: EngAccessFragment, userEmail?: string): boolean => {
  if (userEmail && TEST_USER_EMAILS.includes(userEmail)) {
    return true;
  }
  return !!queryResult?.currentUser?.isEngineer;
};
