import { gql } from '@apollo/client';

import { EqualValueFragment, InequalityValueFragment } from '@infinitus/fragments/rules';

export const ConversationConfigFragment = gql`
  ${EqualValueFragment}
  ${InequalityValueFragment}
  fragment ConversationConfigFragment on ConversationConfig {
    categoryDefs {
      name
      ... on FlatCategoryDef {
        suggestions
      }
      ... on DeepCategoryDef {
        subcategories {
          name
          ... on FlatCategoryDef {
            suggestions
          }
        }
      }
    }

    suggestionDefs {
      name
      utterance
      params
      unpromptable
      isFollowup
      stopRecording
      ... on PrimarySuggestionDef {
        outputFields
        followupSuggestions
      }
    }

    outputDefs {
      name
      label
      type
      hideByDefault
      reviewOptional
      params
      values
      hints
      outputRules {
        when
        then {
          value {
            ...EqualValueFragment
          }
        }
        thenType
      }
      visibilityRule {
        when
        then
      }
    }

    conditionDefs {
      name
      params
      outputName
      predicate {
        ... on EqualPredicate {
          value {
            ...EqualValueFragment
          }
        }
        ... on NotEqualPredicate {
          value {
            ...EqualValueFragment
          }
        }
        ... on GTEPredicate {
          value {
            ...InequalityValueFragment
          }
        }
        ... on GTPredicate {
          value {
            ...InequalityValueFragment
          }
        }
        ... on LTEPredicate {
          value {
            ...InequalityValueFragment
          }
        }
        ... on LTPredicate {
          value {
            ...InequalityValueFragment
          }
        }
      }
      pluralPredicate {
        ... on CountGTPredicate {
          num
        }
      }
    }

    commonSuggestions

    createTimeMillis
  }
`;
