import { gql, useQuery, useMutation, QueryHookOptions, MutationHookOptions } from '@apollo/client';

import { UnauthorizedPageQuery, AcceptUserInviteMutation } from 'types/gqlMapping';

// UnauthorizedPage
const UnauthorizedPageDocument = gql`
  query UnauthorizedPageQuery {
    userInvite {
      id
      role
      status
      invitedById
      invitedByName
      invitedOnMillis
    }
  }
`;

export function useUnauthorizedPageQuery(options?: QueryHookOptions<UnauthorizedPageQuery>) {
  return useQuery<UnauthorizedPageQuery>(UnauthorizedPageDocument, options);
}

// AcceptUserInvite
const AcceptUserInviteDocument = gql`
  mutation AcceptUserInviteMutation {
    acceptUserInvite {
      id
      status
    }
  }
`;

export function useAcceptUserInviteMutation(
  options?: MutationHookOptions<AcceptUserInviteMutation>
) {
  return useMutation<AcceptUserInviteMutation>(AcceptUserInviteDocument, options);
}
