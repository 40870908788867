import { AppName } from '@infinitusai/api';
import { useAuth as useFasttrackAuth } from '@infinitusai/auth';
import { ReactNode, useCallback, useEffect, useMemo, useRef, useReducer } from 'react';

import { useAuth } from '@infinitus/auth';
import { stub } from '@infinitus/auth/AuthContext';
import { useAudioDevice } from '@infinitus/components/AudioDevice';
import { ClientEventType } from '@infinitus/generated/frontend-common';
import useSnackbar from '@infinitus/hooks/useCustomSnackbar';

import NexmoClientService, {
  defaultLogEventHandler,
  EarMuffState,
  Events,
  LogEventFunction,
  MuteState,
} from './NexmoClientService';
import NexmoContext, { NexmoClientWrapper } from './NexmoContext';
import { webRtcIssueDetector } from './WebRtcIssueDetector';

export interface NexmoClientProviderProps {
  appName: AppName;
  children?: ReactNode;
  logEvent?: LogEventFunction;
}

export enum ConnectionState {
  UNKNOWN = 'Unknown',
  DISCONNECTED = 'Disconnected',
  CONNECTING = 'Audio connecting...',
  CONNECTED = 'Audio connected',
}

type NexmoState = Pick<NexmoClientWrapper, 'muteState' | 'earmuffState' | 'connectionState'>;

const initialState = {
  muteState: MuteState.UNKNOWN,
  earmuffState: EarMuffState.UNKNOWN,
  connectionState: ConnectionState.DISCONNECTED,
};

const reducer = (state: NexmoState, action: { payload: any; type: Events }): NexmoState => {
  switch (action.type) {
    case Events.DISCONNECT_DETECTED:
    case Events.LOGIN_FAILED:
    case Events.LEFT_CONVERSATION:
      return initialState;
    case Events.EARMUFF_STATE_CHANGE:
      return {
        ...state,
        earmuffState: action.payload,
      };
    case Events.JOINING_CONVERSATION:
      return {
        ...state,
        connectionState: ConnectionState.CONNECTING,
      };
    case Events.MUTE_STATE_CHANGE:
      return {
        ...state,
        muteState: action.payload,
      };
    case Events.JOINED_CONVERSATION:
      return {
        ...state,
        connectionState: ConnectionState.CONNECTED,
      };
    default:
      return state;
  }
};

// start watching peer connections. For the time being we will not stop watching the connections
webRtcIssueDetector.watchNewPeerConnections();

export function NexmoClientProvider({
  appName,
  children,
  logEvent = defaultLogEventHandler,
}: NexmoClientProviderProps) {
  const { enqueueSnackbar } = useSnackbar();
  //auth for non-FT users, since the OP does not support RBAC yet, this is currently being worked on
  const { user: nonFastTrackUser, onSignOut } = useAuth();
  //FT uses RBAC, so we use a different auth hook
  const { user: fasttrackUser } = useFasttrackAuth();

  const [state, dispatch] = useReducer(reducer, initialState);
  const { deviceId, devicesHash } = useAudioDevice();

  const logEventRef = useRef<LogEventFunction>();
  logEventRef.current = logEvent;

  // nexmoClient will be instantiated just once.
  const nexmoClient = useMemo(
    () =>
      new NexmoClientService(undefined, {
        appName,
        logEvent: async (event) => {
          if (logEventRef.current) logEventRef.current(event);
          console.log(JSON.stringify(event));
        },
      }),
    [appName]
  );

  // Whenever the selected device changes, we need to update the nexmo client
  useEffect(() => {
    if (deviceId)
      nexmoClient.updateAudioConstraints({
        deviceId: { exact: deviceId.id },
        autoGainControl: false,
        echoCancellation: true,
        noiseSuppression: true,
      });
  }, [nexmoClient, deviceId, devicesHash]);

  useEffect(() => {
    // For all events emitted from nexmo client, let's add a listener to dispatch events.
    Object.keys(Events).forEach((e) => {
      nexmoClient.on(e, (arg: any) => {
        dispatch({ type: e as Events, payload: arg });

        // Display user feedback when we automatically attempt to recover from a disconnect
        if (e === Events.ATTEMPTING_AUTO_RECONNECT) {
          enqueueSnackbar('Call audio issue, attempting to recover...', {
            variant: 'warning',
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          });
        }
      });
    });

    return () => {
      nexmoClient.removeAllListeners();
    };
  }, [enqueueSnackbar, nexmoClient]);

  useEffect(() => {
    const initializeNexmoService = async () => {
      // Assert that we're signed in before attempting to query the backend
      if (nonFastTrackUser || fasttrackUser) {
        try {
          await nexmoClient.initialize();
        } catch (e) {
          console.error(`[Nexmo] Failed to initialize nexmo service: ${e}`);
        }
      }
    };

    initializeNexmoService();
  }, [nonFastTrackUser, nexmoClient, fasttrackUser]);

  // When the user signs out, we should log out of the Nexmo client
  useEffect(() => {
    // FT doesn't provide auth in context, so onSignOut will remain a stub
    if (onSignOut === stub) return;
    return onSignOut(async () => {
      await nexmoClient.logout();
    });
  }, [nexmoClient, onSignOut]);

  // Forward the attach request directly to the Nexmo client, but intercept it
  // to add timing measurements
  const attach = useCallback(
    async (...args: Parameters<NexmoClientService['attach']>) => {
      console.log(`[Nexmo] NexmoClientProvider: Attaching to conversation`);
      try {
        await nexmoClient.attach(...args);
      } catch (e: any) {
        enqueueSnackbar(`Failed to connect audio, please leave the call, refresh and join again`, {
          variant: 'error',
        });
        console.error(`[Nexmo] Nexmo failed to connect audio: ${JSON.stringify(e.message)}`);
        throw e;
      }
    },
    [enqueueSnackbar, nexmoClient]
  );

  const handleSetEarmuffed = (earmuffed: boolean) => {
    nexmoClient.setEarmuffed(earmuffed);
    logEvent({
      message: `NexmoClientProvider: Operator set earmuffed to ${earmuffed}`,
      clientEventType: ClientEventType.CALL_ACTIONS,
      meta: {
        earmuffed,
      },
    });
    console.log(`[Nexmo] NexmoClientProvider: Operator set earmuffed to ${earmuffed}`);
  };

  const handleSetMuted = useCallback(
    (muted: boolean) => {
      nexmoClient.setMuted(muted);
      logEvent({
        message: `NexmoClientProvider: Operator set muted to ${muted}`,
        clientEventType: ClientEventType.CALL_ACTIONS,
        meta: {
          muted,
        },
      });
      console.log(`[Nexmo] NexmoClientProvider: Operator set muted to ${muted}`);
    },
    [logEvent, nexmoClient]
  );

  return (
    <NexmoContext.Provider
      value={{
        ...state,
        attach,
        maybeLeaveConversation: nexmoClient.maybeLeaveConversation,
        setEarmuffed: handleSetEarmuffed,
        setMuted: handleSetMuted,
        startNewSession: nexmoClient.startNewSession,
        deleteSession: () => nexmoClient.logout(),
        changeInputAudioSource: (microphoneDeviceId: string) =>
          nexmoClient.updateAudioConstraints({ deviceId: { exact: microphoneDeviceId } }),
      }}
    >
      {children}
    </NexmoContext.Provider>
  );
}
