import { User } from 'firebase/auth';
import { useContext } from 'react';

import AuthContext from './AuthContext';
import { AuthContextInterface } from './types';

/**
 * ```js
 * const {
 *   // Auth state:
 *   error,
 *   isAuthenticated,
 *   isLoading,
 *   permissions,
 *   user,
 *   // Auth methods:
 *   isActive,
 *   hasAdminAccess,
 *   hasUserAccess,
 *   hasEngAccess,
 *   hasOrgOperatorAccess,
 *   getOrgNamesWithRoles,
 *   getOrgInfo,
 *   signOut,
 * } = useAuth<TUser>();
 * ```
 *
 * Use the `useAuth` hook in components to access the auth state and methods.
 *
 * TUser is an optional type param to provide a type to the `user` field.
 */
const useAuth = <TUser extends User = User>(): AuthContextInterface<TUser> =>
  useContext(AuthContext) as AuthContextInterface<TUser>;

export default useAuth;
