import { ThemeProvider } from '@mui/material';
import useLocalStorage from '@rehooks/local-storage';
import { Suspense, lazy, useState } from 'react';
import Helmet from 'react-helmet';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useAuth, AuthRoute } from '@infinitus/auth';
import { useClientEventScope } from '@infinitus/hooks/useLogBuffer';
import {
  BACKEND_SERVER_URL,
  PRESENCE_WEBSOCKET_URL,
  IS_SHARED_CODESPACE,
} from '@infinitus/utils/constants';
import { ConfirmProvider } from 'components/Confirm';
import DevelopmentPanel from 'components/DevelopmentPanel';
import Loader from 'components/Loader';
import useCheckVersion from 'hooks/useCheckVersion';
import Layout from 'layout';
import AccountPage from 'pages/account/AccountPage';
import SignInPage from 'pages/account/SignInPage';
import SignOutPage from 'pages/account/SignOutPage';
import UnauthorizedPage from 'pages/error/403';
import NotFoundPage from 'pages/error/404';
import SpeedTestPage from 'pages/speedTest';
// Prefixing the @mui/material styles per experimental API:
// https://mui.com/material-ui/experimental-api/classname-generator/
import 'styles/MuiV5ClassNameSetup';
import MuiV5Theme from 'styles/MuiV5Theme';

import './App.css';
import ErrorBoundary from './Error';
import LogForwarding from './LogForwarding';

// Use this UUID as a way to uniquely identify this browser tab session
export const browserSessionUuid = crypto.randomUUID();

const DEVELOPMENT_PANEL_SUPPORTED =
  process.env.NODE_ENV === 'development' && window.performance && window.performance.memory;

// Dynamically import Admin/Operator routes
const AdminPage = lazy(() => import('pages/admin'));
const OperatorPage = lazy(() => import('pages/operator'));
const UnifiedQueuePage = lazy(() => import('pages/queue')); // Lazy load to avoid affecting styling for another comments

process.env.REACT_APP_BUILD_VERSION &&
  console.log(
    `Loading Infinitus Portal with session id of '${browserSessionUuid}', version '${process.env.REACT_APP_BUILD_VERSION}' for user-agent '${window.navigator.userAgent}'`
  );
if (IS_SHARED_CODESPACE) {
  console.log(`Running in Shared Codespace mode - remember to share your port.`);
}
console.log(
  `Using backend URL '${BACKEND_SERVER_URL}', websocket URL '${PRESENCE_WEBSOCKET_URL}', gcloud project ${process.env.REACT_APP_GCP_PROJECT_ID}`
);

function App() {
  const auth = useAuth();
  useClientEventScope();
  useCheckVersion();
  const [showDevelopmentPanel, setShowDevelopmentPanel] = useState(false);

  const [lastOrgName] = useLocalStorage('lastOrgName', 'infinitus');

  if (auth.isLoading) return <Loader message="Authenticating" />;

  if (auth.error && auth.error instanceof Error) {
    console.error(auth.error);
  }

  return (
    <ErrorBoundary>
      <Helmet defaultTitle="Infinitus Portal" titleTemplate="%s | Infinitus Portal" />
      <Layout>
        <Suspense fallback={null}>
          <LogForwarding />
          <ThemeProvider theme={MuiV5Theme}>
            <ConfirmProvider>
              <Routes>
                <Route element={<Navigate replace to={`/operator/${lastOrgName}`} />} index />
                <Route element={<AuthRoute auth={auth} />}>
                  <Route element={<AdminPage />} path="admin/*" />
                  <Route path="operator/*">
                    <Route element={<OperatorPage />} index />
                    <Route element={<OperatorPage />} path=":orgName/*" />
                  </Route>

                  {/* No user-based paths go below here */}
                  <Route element={<UnifiedQueuePage />} path="queue" />
                  {/* TODO: Make this the same as the profile page */}
                  <Route element={<AccountPage />} path="account" />
                  <Route element={<SpeedTestPage />} path="speedTest" />
                </Route>

                {/* Auth pages */}
                <Route element={<SignInPage />} path="login" />
                <Route element={<SignOutPage />} path="signout" />

                {/* Error handling */}
                <Route element={<UnauthorizedPage />} path="403" />
                <Route element={<NotFoundPage />} path="*" />
              </Routes>
            </ConfirmProvider>
          </ThemeProvider>
        </Suspense>
      </Layout>
      {DEVELOPMENT_PANEL_SUPPORTED && showDevelopmentPanel && (
        <DevelopmentPanel onClick={() => setShowDevelopmentPanel(!showDevelopmentPanel)} />
      )}
    </ErrorBoundary>
  );
}

export default App;
