import { graphql } from '@infinitus/generated/frontend-common';

// Only used in OperatorPortal
export const SubscribeToTaskDocument = graphql(/* GraphQL */ `
  subscription SubscribeToTask($orgUUID: ID!, $taskUUID: ID!) {
    subscribeToTask(orgUUID: $orgUUID, taskUUID: $taskUUID) {
      error
      task {
        ...TaskFragment
      }
    }
  }
`);

// Only used in OperatorPortal
export const SubscribeToTaskForCallPageDocument = graphql(/* GraphQL */ `
  subscription SubscribeToTaskForCallPage($orgUUID: ID!, $taskUUID: ID!, $isForTaskPage: Boolean) {
    subscribeToTask(orgUUID: $orgUUID, taskUUID: $taskUUID, isForTaskPage: $isForTaskPage) {
      error
      task {
        ...TaskForCallPageFragment
      }
    }
  }
`);

// Used in both OperatorPortal and FastTrack via useCallSubscription
export const SubscribeToCallDocument = graphql(/* GraphQL */ `
  subscription SubscribeToCall($orgUUID: ID!, $taskUUID: ID!, $callUUID: ID!) {
    subscribeToCall(orgUUID: $orgUUID, taskUUID: $taskUUID, callUUID: $callUUID) {
      ...CallFragment
    }
  }
`);

// Only used in OperatorPortal
export const TaskPageSubscribeToCallDocument = graphql(/* GraphQL */ `
  subscription TaskPageSubscribeToCall($orgUUID: ID!, $taskUUID: ID!, $callUUID: ID!) {
    subscribeToCall(orgUUID: $orgUUID, taskUUID: $taskUUID, callUUID: $callUUID) {
      ...CallFragment
    }
  }
`);

// Only used in OperatorPortal
export const GetTaskDocument = graphql(/* GraphQL */ `
  query GetTask($orgUuid: ID!, $taskUuid: ID!) {
    task(orgUuid: $orgUuid, taskUuid: $taskUuid) {
      ...TaskFragment
    }
  }
`);

// Only used in OperatorPortal
export const LogCallEventDocument = graphql(/* GraphQL */ `
  mutation LogCallEvent(
    $orgUuid: ID!
    $taskUuid: ID!
    $callUuid: ID!
    $callEventType: CallEventType!
    $eventValue: String
  ) {
    logCallEvent(
      orgUUID: $orgUuid
      taskUUID: $taskUuid
      callUUID: $callUuid
      callEventType: $callEventType
      eventValue: $eventValue
    )
  }
`);

// Used in both OperatorPortal and FastTrack via EvenLoggingService
export const LogClientEventsDocument = graphql(/* GraphQL */ `
  mutation LogClientEvents($clientEvents: [ClientEvent!]!) {
    logClientEvents(clientEvents: $clientEvents)
  }
`);
