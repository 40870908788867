import { CallSearchParams, toURLSearchParams } from '@infinitus/hooks/useCallSearchParams';
import { UUID } from '@infinitus/types';

export const getAdminUserPageUrl = (userUID: string) => `/admin/users/${userUID}`;

export const getAdminLibraryConditionsPageUrl = (
  orgName?: string,
  taskType?: string,
  actionNameWithoutMacros?: string
) => {
  let url = `/admin/library/conditions`;
  if (orgName) {
    url += `/${orgName}`;
  }
  if (taskType) {
    url += `/${taskType}`;
  }
  if (actionNameWithoutMacros) {
    url += `/${actionNameWithoutMacros}`;
  }
  return url;
};

export const getAdminLibraryOutputsPageUrl = (taskType?: string, outputName?: string) => {
  let url = `/admin/library/outputs`;
  if (taskType) {
    url += `/${taskType}`;
  }
  if (outputName) {
    url += `/${outputName}`;
  }
  return url;
};

export const getExperimentsPageUrl = (experimentName?: string) => {
  let url = '/admin/experiments';
  if (experimentName) {
    url += `/${experimentName}`;
  }
  return url;
};

export const getIssuesPageUrl = () => '/admin/issues';

export const getPayersPageUrl = (payerUuid?: UUID) => {
  let url = '/admin/payers';
  if (payerUuid) {
    url += `/${payerUuid}`;
  }
  return url;
};
export const getPayerRestrictedOrgsPageUrl = (payerUuid: UUID) => {
  return `/admin/payers/${payerUuid}/restrictedOrgs`;
};

export const getPhoneNumbersPageUrl = (phoneNumber?: string) => {
  let url = '/admin/phoneNumbers';
  if (phoneNumber) {
    url += `/${phoneNumber}`;
  }
  return url;
};

export const getUsersPageUrl = () => '/admin/users';

export const getAdminOrgPageUrl = (orgUuid?: string, tabName?: string) => {
  let url = '/admin/orgs';
  if (orgUuid) {
    url += `/${orgUuid}`;
  }
  if (tabName) {
    url += `/${tabName}`;
  }
  return url;
};

export const getAdminSkillDefinitionsPageUrl = () => '/admin/skillDefinitions';

export const getAdminUnifiedQueueManagerPageUrl = (queueUuid?: string) => {
  let url = '/admin/unifiedQueueManager';
  if (queueUuid) {
    url += `/${queueUuid}`;
  }
  return url;
};

export const getAdminConvoBuilderPageUrl = (orgName?: string, taskType?: string) => {
  let url = '/admin/convo';
  if (orgName) {
    url += `/${orgName}`;
  }
  if (taskType) {
    url += `/${taskType}`;
  }
  return url;
};

export const getLoginPageUrl = () => '/login';

export const getSignOutPageUrl = () => '/signout';

export const getAccountPageUrl = () => '/account';

export const getUnifiedReadyPageUrl = () => '/queue';

export const getOrgPageUrl = (orgName: string) => `/operator/${orgName}`;

export const getOrgReadyPageUrl = (orgName: string) => `${getOrgPageUrl(orgName)}/ready`;

export const getTaskPageUrl = (orgName: string, taskUUID: string) =>
  `${getOrgPageUrl(orgName)}/tasks/${taskUUID}`;

export const getCallPageUrl = (
  orgName: string,
  taskUUID: string,
  callUUID: string,
  searchParams?: CallSearchParams
) => {
  const params = searchParams ? `?${toURLSearchParams(searchParams).toString()}` : '';
  return `${getTaskPageUrl(orgName, taskUUID)}/calls/${callUUID}${params}`;
};
