import { useCallback, useEffect, useState } from 'react';

import useEventListeners from '@infinitus/hooks/useEventListeners';
import useInterval from '@infinitus/hooks/useInterval';
import { logEventToBigQuery } from '@infinitus/hooks/useLogBuffer';
import usePresenceMessage, {
  lastKnownActivityMillis,
} from '@infinitus/hooks/useOperatorPresence/usePresenceMessage';
import { ClientEventType } from 'generated/gql/graphql';
import { OperatorOfflineReason, OperatorOnlineStatus } from 'generated/gql/graphql';

import useOperatorOnlineStatus from './useOperatorOnlineStatus';

const SEC_CHECK_INTERVAL = 1;
// TODO: reduce total time to ~1 minute when we confirm this doesn't negatively affect users or metrics
const SEC_UNTIL_OFFLINE = 120;

const ONLINE_TRIGGERS = new Set(['click', 'mousedown', 'keydown', 'scroll', 'wheel']);

export function InactivityDetector() {
  const { markOperatorOffline, markOperatorOnline, operatorOnlineStatus } =
    useOperatorOnlineStatus();
  const { getHeartbeatMessage } = usePresenceMessage();

  const [lastEvent, setLastEvent] = useState<string | null>(null);

  // If the tab isn't visible/focused, do nothing
  // document.visibilityState alone isn't reliable here
  const isTabActive = useCallback(() => {
    if (document.hasFocus() && document.visibilityState === 'visible') {
      return true;
    }
    return false;
  }, []);

  useEventListeners({
    eventHandler: (evt) => {
      if (!isTabActive()) return;

      lastKnownActivityMillis(Date.now());
      if (ONLINE_TRIGGERS.has(evt.type) && operatorOnlineStatus === OperatorOnlineStatus.OFFLINE) {
        if (lastEvent) return;
        logEventToBigQuery({
          clientEventType: ClientEventType.INACTIVITY_DETECTOR,
          message: 'Inactivity Detector: go online',
          meta: {
            eventType: evt.type,
          },
        });
        markOperatorOnline();
        // Used to avoid spamming logs
        setLastEvent(evt.type);
      }
    },
  });

  // When the tab becomes active, count it as activity
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        lastKnownActivityMillis(Date.now());
        if (operatorOnlineStatus === OperatorOnlineStatus.OFFLINE) {
          markOperatorOnline();
        }
      }
    };
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => document.removeEventListener('visibilitychange', handleVisibilityChange);
  }, [markOperatorOnline, operatorOnlineStatus]);

  // When operator goes online, allow logging again
  useEffect(() => {
    if (operatorOnlineStatus === OperatorOnlineStatus.ONLINE) {
      setLastEvent(null);
    }
  }, [operatorOnlineStatus]);

  // Check at an interval
  const checkForActivity = useCallback(() => {
    if (!isTabActive()) return;

    // Check early and break if you're
    // - on active call
    // - ready/queue page with ready switch on
    const hb = getHeartbeatMessage();
    if (hb.callPageData?.isCallInProgress) {
      return;
    } else if (hb.readyPageData?.isOperatorAvailable) {
      return;
    }

    const secIdle = (Date.now() - lastKnownActivityMillis()) / 1000;
    if (secIdle < SEC_UNTIL_OFFLINE) return;
    if (operatorOnlineStatus === OperatorOnlineStatus.ONLINE) {
      logEventToBigQuery({
        message: 'Inactivity Detector: go offline',
        clientEventType: ClientEventType.INACTIVITY_DETECTOR,
      });
      markOperatorOffline(OperatorOfflineReason.INACTIVE);
    }
  }, [getHeartbeatMessage, isTabActive, markOperatorOffline, operatorOnlineStatus]);

  // How often to check
  useInterval(() => {
    checkForActivity();
  }, SEC_CHECK_INTERVAL * 1000);

  return null;
}
