import { useMatch } from 'react-router-dom';

export type CallParams = {
  callUuid: string;
  orgName: string;
  taskUuid: string;
};

const KNOWN_PORTAL_ROUTES = ['account', 'admin', 'operator', 'ready', 'queue', 'speedTest'];

// As of Jan 2024, it appears that FastTrack is no longer is using useCallParams.
// Check for indirect dependency and clean this up.

export default function useCallParams(): CallParams {
  // We used useParams before, but it causes lots of rerenders
  // and it doesn't seem like the react-router team is going to fix it
  // https://github.com/remix-run/react-router/issues/7318

  // Attempt to read the params from a supplied route
  // (e.g. if the consumer of this hook isn't nested within a Route)
  let params: Partial<CallParams> = {};
  // We can't use regexps here, ref: https://reactrouter.com/en/main/start/faq#what-happened-to-regexp-routes-paths
  const portalBaseRoute = useMatch('/operator/:orgName/*');
  const fastTrackBaseRoute = useMatch('/:orgName/*');

  const portalTaskPageRoute = useMatch('/operator/:orgName/tasks/:taskUuid/*');
  const fastTrackTaskPageRoute = useMatch('/:orgName/tasks/:taskUuid/*');

  const portalCallPageRoute = useMatch('/operator/:orgName/tasks/:taskUuid/calls/:callUuid/*');
  const fastTrackCallPageRoute = useMatch('/:orgName/tasks/:taskUuid/calls/:callUuid/*');

  const isKnownPortalRoute = KNOWN_PORTAL_ROUTES.includes(
    fastTrackBaseRoute?.params?.orgName || ''
  );
  params.orgName = portalBaseRoute?.params.orgName;
  // If we didn't match, and it's not a portal route, then assume fasttrack
  if (!params.orgName && !isKnownPortalRoute) {
    params.orgName = fastTrackBaseRoute?.params.orgName ?? '';
  }
  params.taskUuid =
    portalTaskPageRoute?.params.taskUuid ?? fastTrackTaskPageRoute?.params.taskUuid ?? '';
  params.callUuid =
    portalCallPageRoute?.params.callUuid ?? fastTrackCallPageRoute?.params.callUuid ?? '';

  return {
    orgName: '',
    taskUuid: '',
    callUuid: '',
    ...params,
  };
}
