import { useApolloClient } from '@apollo/client';
import {
  getAuth,
  GoogleAuthProvider,
  signInWithCustomToken,
  signInWithRedirect,
} from 'firebase/auth';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';

import { useCriticalReqLogger } from '@infinitus/components/CriticalReqLogger/useCriticalReqLogger';
import { UserRole, OrgRole, UserStatus } from '@infinitus/generated/frontend-common';

import AuthContext from './AuthContext';
import { CurrentUserDocument } from './graphql';
import { AuthPermissions, OrgMembership, initialAuthState, OperatorInfo } from './types';

interface Props {}

/**
 * Provides the AuthContext to its child components.
 */
function AuthProvider({ children }: PropsWithChildren<Props>): JSX.Element {
  const client = useApolloClient();
  const [authState, setAuthState] = useState(initialAuthState);
  const [user, userLoading, userError] = useAuthState(getAuth());
  const { wrapAsyncRequest } = useCriticalReqLogger('CurrentUserQuery');

  // Initialize user auth data
  useEffect(() => {
    (async (): Promise<void> => {
      if (userLoading) setAuthState(initialAuthState);
      else if (userError)
        setAuthState({
          error: userError,
          isAuthenticated: false,
          isLoading: false,
          signOutHandlers: new Set(),
        });
      else if (user) {
        try {
          const wrappedQuery = wrapAsyncRequest(async () =>
            client.query({
              query: CurrentUserDocument,
            })
          );
          const { error, data } = await wrappedQuery();
          const userRole = data?.currentUser?.role || UserRole.UNKNOWN;
          const userStatus = data?.currentUser?.status || UserStatus.UNKNOWN;
          const userOrgs = data?.currentUser?.orgs || [];
          const userOperatorInfo = (data.currentUser?.operatorInfo as OperatorInfo) || undefined;
          const permissions: AuthPermissions = {
            role: userRole,
            status: userStatus,
            orgs: userOrgs.reduce((previousValue, currentValue) => {
              const orgId = currentValue.org.id;
              const orgRole = currentValue.orgRole;
              const orgName = currentValue.org.name;
              const orgDisplayName = currentValue.org.displayName;
              const orgImageUrl = currentValue.org.imageUrl;
              const orgIsInternal = currentValue.org.isInternal;
              const orgIsLive = currentValue.org.isLive;
              const orgEnableTesting = currentValue.org.enableTesting;
              previousValue[orgName] = {
                id: orgId,
                role: orgRole,
                name: orgName,
                displayName: orgDisplayName,
                imageUrl: orgImageUrl,
                isInternal: orgIsInternal,
                isLive: orgIsLive,
                enableTesting: orgEnableTesting,
              };

              return previousValue;
            }, {} as { [name: string]: OrgMembership }),
            operatorInfo: userOperatorInfo,
          };
          setAuthState({
            error,
            isAuthenticated: true,
            isLoading: false,
            permissions,
            user,
            signOutHandlers: new Set(),
          });
        } catch (e: any) {
          const permissions: AuthPermissions = {
            role: UserRole.UNKNOWN,
            status: UserStatus.UNKNOWN,
            orgs: {},
          };
          setAuthState({
            error: e,
            isAuthenticated: true,
            isLoading: false,
            permissions,
            user,
            signOutHandlers: new Set(),
          });
        }
      } else {
        setAuthState({
          isAuthenticated: false,
          isLoading: false,
          signOutHandlers: new Set(),
        });
      }
    })();
  }, [client, user, userLoading, userError, wrapAsyncRequest]);

  const signInWithGoogle = useCallback(() => {
    let provider = new GoogleAuthProvider();
    signInWithRedirect(getAuth(), provider);
  }, []);

  const signInWithCustomTokenImpl = useCallback((token: string) => {
    return signInWithCustomToken(getAuth(), token);
  }, []);

  const isActive = useCallback(() => {
    const userStatus = authState.permissions?.status || UserStatus.UNKNOWN;

    return userStatus === UserStatus.ACTIVE;
  }, [authState]);

  const hasAdminAccess = useCallback(() => {
    const userRole = authState.permissions?.role || UserRole.UNKNOWN;

    return userRole === UserRole.ADMIN;
  }, [authState]);

  const hasUserAccess = useCallback(() => {
    const userRole = authState.permissions?.role || UserRole.UNKNOWN;

    return [UserRole.ADMIN, UserRole.USER].includes(userRole);
  }, [authState]);

  const hasOrgOperatorAccess = useCallback(
    (orgName: string) => {
      const userRole = authState.permissions?.role || UserRole.UNKNOWN;
      const userOrgs = authState.permissions?.orgs;
      const org = userOrgs?.[orgName];
      const orgRole = org?.role || OrgRole.UNKNOWN;

      return (
        userRole === UserRole.ADMIN || (userRole === UserRole.USER && orgRole === OrgRole.OPERATOR)
      );
    },
    [authState]
  );

  const hasTaskReviewAuthorization = useCallback(() => {
    const operatorInfo = authState.permissions?.operatorInfo;
    const canReview = operatorInfo?.canReview || false;

    return canReview;
  }, [authState]);

  const getOrgNamesWithRoles = useCallback(
    (orgRoles: OrgRole[]) => {
      const userRole = authState.permissions?.role || UserRole.UNKNOWN;
      const userOrgs = authState.permissions?.orgs || {};
      const orgNamesWithRole = Object.keys(userOrgs).filter(
        (key) => userRole === UserRole.ADMIN || orgRoles.includes(userOrgs[key].role)
      );

      return orgNamesWithRole;
    },
    [authState]
  );

  const getOrgInfo = useCallback(
    (orgName: string) => {
      const userOrgs = authState.permissions?.orgs || {};
      const defaultMembership: OrgMembership = {
        role: OrgRole.UNKNOWN,
        id: '',
        name: '',
        displayName: '',
        imageUrl: '',
        isInternal: false,
        isLive: false,
        enableTesting: false,
      };
      const org = userOrgs?.[orgName] || defaultMembership;

      return org;
    },
    [authState]
  );

  const getOrgNameFromUuid = useCallback(
    (orgUuid: string) => {
      const userOrgs = authState.permissions?.orgs || {};
      const orgMembership = Object.values(userOrgs).find(
        (orgMembership) => orgMembership.id === orgUuid
      );
      return orgMembership?.name ?? '';
    },
    [authState]
  );

  const getSelectiveReviewStatus = useCallback(
    (orgName: string) => {
      const operatorInfo = authState.permissions?.operatorInfo;
      const selectiveReviewEnabled = operatorInfo?.selectiveReviewEnabled || false;
      const selectiveReviewOrgEligibility = operatorInfo?.selectiveReviewOrgEligibility || [];
      const orgInfo = getOrgInfo(orgName);

      return {
        enabled: selectiveReviewEnabled,
        eligible: selectiveReviewOrgEligibility.includes(orgInfo.id),
      };
    },
    [authState, getOrgInfo]
  );

  /**
   * Registers a callback to be called when the user signs out.
   * Returns a function that will unregister the callback.
   */
  const onSignOut = useCallback(
    (signOutHandler: () => Promise<void>) => {
      authState.signOutHandlers.add(signOutHandler);
      return () => {
        authState.signOutHandlers.delete(signOutHandler);
      };
    },
    [authState.signOutHandlers]
  );

  const signOut = useCallback(async () => {
    const handlersArray = Array.from(authState.signOutHandlers.values());
    const promises = handlersArray.map((handler) => handler());
    await Promise.all(promises);
    return getAuth().signOut();
  }, [authState.signOutHandlers]);

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        signInWithGoogle,
        signInWithCustomToken: signInWithCustomTokenImpl,
        isActive,
        hasAdminAccess,
        hasUserAccess,
        hasOrgOperatorAccess,
        hasTaskReviewAuthorization,
        getOrgNamesWithRoles,
        getOrgInfo,
        getOrgNameFromUuid,
        getSelectiveReviewStatus,
        signOut,
        onSignOut,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
