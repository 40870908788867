import { useLazyQuery, useMutation } from '@apollo/client';
import { useMemo, useState, useEffect } from 'react';

import { useAuth } from '@infinitus/auth';
import {
  MakeOptional,
  ListGreetingTemplatesQuery,
  ListGreetingsQuery,
  UploadGreetingMutationVariables,
} from '@infinitus/generated/frontend-common';
import { LIST_GREETINGS, LIST_GREETING_TEMPLATES, UPLOAD_GREETING } from '@infinitus/graphql';

import { readAsDataURL, convertToBase64 } from './blobFileReader';

type GreetingTemplates = ListGreetingTemplatesQuery['listGreetingTemplates'];
type Greetings = ListGreetingsQuery['listGreetings'];
type RecordedGreeting = MakeOptional<Greetings[number], 'approvalStatus'>;

const fetchGreetingsToRecord = async (
  firstName: string,
  greetingTemplates: GreetingTemplates,
  loadedGreetings: Greetings = []
): Promise<RecordedGreeting[]> => {
  return greetingTemplates.map((greetingConfig) => {
    const loadedGreeting = loadedGreetings.find(
      (greeting) => greeting.greetingName === greetingConfig.greetingName
    );
    const greeting = {
      ...greetingConfig,
      __typename: 'RecordedGreeting' as 'RecordedGreeting',
      gcsSignedURL: loadedGreeting?.gcsSignedURL || '',
      approvalStatus: loadedGreeting?.approvalStatus,
    };
    greeting.recordedText = greetingConfig.recordedText.replace('#{FIRST_NAME}', firstName);
    return greeting;
  });
};

export const fetchGreetingBlobs = async (greetings: Greetings) => {
  const myGreetingRecordings: { blob: Blob; name: string }[] = [];
  let errorMsgs: string[] | null = [];

  if (greetings && greetings.length > 0) {
    for (const greeting of greetings) {
      let { greetingName, gcsSignedURL } = greeting;
      try {
        let response = await fetch(gcsSignedURL);
        let blob = new Blob([await response.blob()], { type: 'audio/wav' });
        myGreetingRecordings.push({ name: greetingName, blob });
      } catch (e: any) {
        errorMsgs.push(`Failed to retrieve and decode recording: ${e}`);
      }
    }
  }

  if (errorMsgs.length === 0) {
    errorMsgs = null;
  }

  return { myGreetingRecordings, errorMsgs };
};

export default function useMyRecordedGreetings() {
  const { user } = useAuth();
  const [recordedGreetings, setRecordedGreetings] = useState<RecordedGreeting[]>([]);

  const firstName = user?.displayName?.split(' ')[0] ?? '';

  const [listGreetingTemplates, listGreetingTemplatesResponse] = useLazyQuery(
    LIST_GREETING_TEMPLATES,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [getGreetings, listGreetingsQueryResponse] = useLazyQuery(LIST_GREETINGS, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      if (greetingTemplates.length > 0) {
        fetchGreetingsToRecord(firstName, greetingTemplates, data.listGreetings).then(
          setRecordedGreetings
        );
      }
    },
  });

  const greetingTemplates = useMemo(
    () => listGreetingTemplatesResponse.data?.listGreetingTemplates ?? [],
    [listGreetingTemplatesResponse.data?.listGreetingTemplates]
  );

  const listedGreetings = useMemo(
    () => listGreetingsQueryResponse.data?.listGreetings ?? [],
    [listGreetingsQueryResponse.data?.listGreetings]
  );

  useEffect(() => {
    fetchGreetingsToRecord(firstName, greetingTemplates, listedGreetings).then(
      setRecordedGreetings
    );
  }, [firstName, greetingTemplates, listedGreetings]);

  const [uploadGreetingMutation, uploadingGreetingResponse] = useMutation(UPLOAD_GREETING);

  const uploadGreeting = async (
    orgUuid: UploadGreetingMutationVariables['orgUUID'],
    greetingName: UploadGreetingMutationVariables['greetingName'],
    blob: Blob
  ) => {
    const response: {
      data?: Awaited<ReturnType<typeof uploadGreetingMutation>>;
      errorMsg?: string;
    } = {};
    const greetingConfig = greetingTemplates.find(
      (defaultGreeting) => defaultGreeting.greetingName === greetingName
    );
    if (!greetingConfig) {
      response.errorMsg = `Unable to load greeting config for greeting with name '${greetingName}'`;
      return response;
    }
    const recordedText = greetingConfig.recordedText.replace('#{FIRST_NAME}', firstName);
    try {
      // Base64 encode the raw wave blob for transmission
      const base64Data = await readAsDataURL(blob, convertToBase64);

      response.data = await uploadGreetingMutation({
        variables: {
          orgUUID: orgUuid,
          greetingName,
          recordedText,
          base64AudioBytes: base64Data,
        },
      });
    } catch (e: any) {
      response.errorMsg = `Failed to upload recording '${greetingName}': ${e}`;
    }
    return response;
  };

  return {
    listGreetingTemplates,
    listGreetingTemplatesResponse,
    getGreetings,
    listGreetingsQueryResponse,
    uploadGreeting,
    uploadingGreetingResponse,
    recordedGreetings,
  };
}
