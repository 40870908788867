import { useQuery } from '@apollo/client';
import { useCallback, useMemo } from 'react';

import useGetIdsFromUrl from '@infinitus/hooks/useGetIdsFromUrl';
import { OperatorPortalFeature } from 'generated/gql/graphql';
import { GetOrgOperatorFeatures, GetOrgOperatorFeaturesVariables } from 'types/gqlMapping';
import { GET_ORG_OPERATOR_FEATURES } from 'utils/graphql';

const useEnabledFeatures = (overrideOrgUuid?: string) => {
  const { orgUuid } = useGetIdsFromUrl();

  const { data: orgFeatures, loading } = useQuery<
    GetOrgOperatorFeatures,
    GetOrgOperatorFeaturesVariables
  >(GET_ORG_OPERATOR_FEATURES, {
    variables: {
      orgUuid: overrideOrgUuid || orgUuid,
    },
    skip: !(overrideOrgUuid || orgUuid),
  });

  const enabledFeatures: OperatorPortalFeature[] = useMemo(
    () => orgFeatures?.enabledOperatorPortalFeatures.enabledFeatures || [],
    [orgFeatures?.enabledOperatorPortalFeatures.enabledFeatures]
  );

  const isFeatureEnabled = useCallback(
    (feature: OperatorPortalFeature): boolean => enabledFeatures.includes(feature),
    [enabledFeatures]
  );

  return {
    loading,
    enabledFeatures,
    isFeatureEnabled,
  };
};

export default useEnabledFeatures;
