import { gql } from '@apollo/client';

import {
  AddressFieldsFragment,
  AllCallInputsFragment,
  DateFields,
} from '@infinitus/fragments/callInputs';
import { OutputFieldFragment } from '@infinitus/fragments/conversation';
import { ConversationConfigFragment } from '@infinitus/fragments/conversationConfig';
import { CallOutputsFragment } from '@infinitus/fragments/outputs';
import { RulesFragment } from '@infinitus/fragments/rules';
import { FlexibleInputFragment } from '@infinitus/fragments/taskInputs';
import { OutputsFragment } from 'fragments/outputs';
import { graphql } from 'generated/gql';

export const CREATE_ORG = graphql(/* GraphQL */ `
  mutation CreateOrg($input: CreateOrgInput!) {
    createOrg(input: $input) {
      id
    }
  }
`);

export const GET_ORGS = gql`
  query GetOrgs {
    orgs {
      id
      name
      displayName
      isInternal
      isLive
    }
  }
`;

export const GET_ORG_OPERATOR_FEATURES = gql`
  query GetOrgOperatorFeatures($orgUuid: ID!) {
    enabledOperatorPortalFeatures(orgUUID: $orgUuid) {
      enabledFeatures
    }
  }
`;

export const GET_ORG_PROGRAMS = gql`
  query GetOrgPrograms($orgUuid: ID!) {
    orgPrograms(orgUUID: $orgUuid) {
      name
      displayName
    }
  }
`;

export const CURRENT_USER = gql`
  query GetCurrentUserData {
    currentUser {
      id
      role
      email
      status
      imageUrl
      fullName
    }
  }
`;

export const RULES_QUERY = gql`
  query RulesQuery {
    rules @client {
      ...RulesFragment
    }
  }
  ${RulesFragment}
`;

export const OUTPUT_FIELDS_QUERY = gql`
  query OutputFieldsQuery {
    outputFields @client {
      ...OutputFieldFragment
    }
  }
  ${OutputFieldFragment}
`;

export const GET_CALL_INPUTS = gql`
  ${AddressFieldsFragment}
  ${DateFields}
  ${AllCallInputsFragment}
  query GetCallInputs($input: GetCallInputsInput!) {
    getCallInputs(input: $input) {
      customerAssignedId
      programName
      callInputs {
        ...AllCallInputs
      }
    }
  }
`;

export const CALL_OUTPUTS_QUERY = gql`
  query CallOutputsQuery {
    callOutputs @client {
      ...CallOutputsFragment
    }
  }
  ${CallOutputsFragment}
`;

export const FETCH_TASK_STATUS = gql`
  ${FlexibleInputFragment}
  query GetTaskStatus($orgUuid: ID!, $taskUuid: ID!) {
    task(orgUuid: $orgUuid, taskUuid: $taskUuid) {
      state
      bvInputs {
        patientInfo {
          firstName
          lastName
        }
      }
      flexibleInputs {
        ...FlexibleInputFragment
      }
    }
  }
`;

export const FETCH_TASK_NOTES_AUTOCOMPLETE_OPTIONS = gql`
  query FetchTaskNotesAutocompleteOptions {
    taskNotesAutocompleteOptions
  }
`;

export const LIST_ALL_AUTOCOMPLETE_OPTIONS = gql`
  query ListAllAutocompleteOptions {
    listAllAutocompleteOptions {
      id
      values
    }
  }
`;

export const UPDATE_AUTOCOMPLETE_OPTION = gql`
  mutation UpdateAutocompleteOption($id: AutoKey!, $newValues: [String!]!, $oldValues: [String!]!) {
    updateAutocompleteOption(id: $id, newValues: $newValues, oldValues: $oldValues) {
      id
      values
    }
  }
`;

export const TASK_STATE_AND_REVIEW_CLAIM_USER = graphql(/* GraphQL */ `
  query TaskReviewClaimUserAndState {
    readTaskState @client {
      state
      reviewClaimUser {
        userEmail
      }
    }
  }
`);

export const VALIDATE_OUTPUTS_QUERY_V2 = gql`
  query ValidateOutputsV2($orgUUID: ID!, $taskUUID: ID!, $callUUID: ID!) {
    validateOutputsV2(orgUUID: $orgUUID, taskUUID: $taskUUID, callUUID: $callUUID) {
      warningMessage
      outputValidations {
        type
        message
        output {
          ...OutputsFragment
        }
        origin
      }
    }
  }
  ${OutputsFragment}
`;

export const CALL_START_END_QUERY = gql`
  query QueryCallStartAndEndMillis {
    readCallState @client {
      audioStartMillis
      audioEndMillis
    }
  }
`;

export const ORG_CONVOS = gql`
  query OrgConvos($orgId: ID!) {
    conversationTaskTypes(orgUUID: $orgId)
  }
`;

export const BASE_CONVERSATION_CONFIG = gql`
  ${ConversationConfigFragment}
  query BaseConversationConfig($taskType: TaskType!, $orgUUID: ID) {
    baseConversationConfig(taskType: $taskType, orgUUID: $orgUUID) {
      ...ConversationConfigFragment
    }
  }
`;

export const COPY_BASE_CONVERSATION_CONFIG = gql`
  mutation CopyBaseConversationConfig($taskType: TaskType!, $sourceOrgUUID: ID, $destOrgUUID: ID!) {
    copyBaseConversationConfig(
      taskType: $taskType
      sourceOrgUUID: $sourceOrgUUID
      destOrgUUID: $destOrgUUID
    )
  }
`;

export const UPDATE_BASE_CONVERSATION_CONFIG = gql`
  ${ConversationConfigFragment}
  mutation UpdateBaseConversationConfig(
    $taskType: TaskType!
    $orgUUID: ID
    $in: UpdateBaseConversationConfigIn!
  ) {
    updateBaseConversationConfig(taskType: $taskType, orgUUID: $orgUUID, in: $in) {
      ...ConversationConfigFragment
    }
  }
`;

export const UPDATE_ORG_ROLE = gql`
  mutation UpdateOrgRole($orgUUID: ID!, $userID: ID!, $role: OrgRole!) {
    updateOrgRole(orgUUID: $orgUUID, userId: $userID, orgRole: $role) {
      id
      role
      email
      displayName
    }
  }
`;

export const REMOVE_USER_FROM_ORG = gql`
  mutation RemoveUserFromOrg($orgUUID: ID!, $userID: ID!) {
    removeUserFromOrg(orgUUID: $orgUUID, userId: $userID) {
      id
      role
      email
      displayName
    }
  }
`;

export const SAVE_TASK_NOTES_INTERNAL = gql`
  mutation SaveTaskNotesInternal(
    $orgUUID: ID!
    $taskUUID: ID!
    $callUUID: ID!
    $taskNotesInternal: String!
  ) {
    saveTaskNotesInternal(
      orgUUID: $orgUUID
      taskUUID: $taskUUID
      callUUID: $callUUID
      taskNotesInternal: $taskNotesInternal
    ) {
      id
      taskNotesInternal
    }
  }
`;

export const LIST_ORG_GREETINGS = gql`
  query ListOrgGreetings($orgUUID: ID!) {
    listOrgGreetings(orgUUID: $orgUUID) {
      orgId
      userEmail
      id
      createTime
      greetingName
      recordedText
      gcsPath
      gcsSignedURL
      approvalStatus
      approvedBy
    }
  }
`;

export const GET_ORG_USERS = gql`
  query GetOrgUsers($orgUUID: ID!, $roles: [String!]!) {
    orgUsers(orgUUID: $orgUUID, roles: $roles) {
      id
      role
      email
      displayName
    }
  }
`;

export const GET_CUSTOMERS = gql`
  query Customers {
    customers {
      id
      displayName
      orgUuids
      leads
    }
  }
`;

export const TASK_INPUTS = gql`
  ${FlexibleInputFragment}
  query GetTaskInputs($orgUuid: ID!, $taskUuid: ID!) {
    taskInputs(orgUUID: $orgUuid, taskUUID: $taskUuid) {
      customerAssignedId
      programName
      customerSegment
      inputs {
        ...FlexibleInputFragment
      }
    }
  }
`;

export const GET_ORG_CUSTOMER = gql`
  query GetOrgCustomer($orgId: ID!) {
    customerForOrg(orgUUID: $orgId) {
      id
      displayName
      orgUuids
      leads
    }
  }
`;

export const MULTIPLE_ORG_CUSTOMER_TASK_COUNTS = gql`
  query MultipleOrgCustomerTaskCounts($orgUuidList: [ID!]!) {
    multipleOrgCustomerTaskCounts(orgUuidList: $orgUuidList) {
      tasksCompletedToday
    }
  }
`;

export const LOG_CALL_VALIDATION_EVENT_V2 = gql`
  mutation LogCallValidationEventV2(
    $orgUuid: ID!
    $taskUuid: ID!
    $callUuid: ID!
    $callValidationEvents: [CallValidationEventV2!]!
  ) {
    logCallValidationEventV2(
      orgUUID: $orgUuid
      taskUUID: $taskUuid
      callUUID: $callUuid
      callValidationEvents: $callValidationEvents
    )
  }
`;

export const GET_OUTPUTS = gql`
  ${OutputsFragment}
  query FetchMultiBenefitCallOutputs($orgUuid: ID!, $taskUuid: ID!, $callUuid: ID!) {
    fetchMultiBenefitCallOutputs(orgUUID: $orgUuid, taskUUID: $taskUuid, callUUID: $callUuid) {
      taskUUID
      outputs {
        ...OutputsFragment
      }
    }
  }
`;

export const QueryChatLogDocument = graphql(/* GraphQL */ `
  query QueryChatLog($intent: String!, $sourceTaskUuid: String!) {
    queryDigitalAssistantChatLogByIntent(intent: $intent, sourceTaskUuid: $sourceTaskUuid) @client {
      ...ChatLogFragment
    }
  }
`);

export const QueryChatLogsDocument = graphql(/* GraphQL */ `
  query QueryChatLogs($intents: [String!]!, $sourceTaskUuid: String!) {
    queryDigitalAssistantChatLogsByIntents(intents: $intents, sourceTaskUuid: $sourceTaskUuid)
      @client {
      ...ChatLogFragment
    }
  }
`);

export const PAYERS = gql`
  query GetPayers {
    payers {
      chcPayerId
      concurrentCallLimit
      doNotCall
      enableAutoRetries
      id
      name
      needsHumanIntro
      isFastTrackEnabled
      restrictedToOrgUuids
      elevenLabsConfig {
        useElevenLabs
        useForAllOrgs
        orgUUIDs
      }
      payorDown {
        is_down
        payor_down_reason
        payor_next_attempt_time
      }
      updatedMillis
    }
  }
`;
