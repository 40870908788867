import { User } from 'firebase/auth';
import { useEffect, useState, useCallback } from 'react';

import useMyRecordedGreetings, {
  useMyRecordingBlobs,
  fetchGreetingBlobs,
} from '@infinitus/hooks/useRecordedGreetingHooks';

interface HandleFormPostedResponse {
  errorMsg?: string;
  failedUploads: string[];
  // the following return the greeting names for greeting upload attempts
  successfulUploads: string[];
}
interface Props {
  orgUuid: string;
  user: User | undefined | null;
}

export default function useMyRecordedGreetingsEditor({ orgUuid, user }: Props) {
  const [updatedGreetings, setUpdatedGreetings] = useState<Map<string, boolean>>(new Map());

  const {
    listGreetingTemplates,
    getGreetings,
    listGreetingsQueryResponse,
    uploadGreeting,
    uploadingGreetingResponse,
    recordedGreetings,
  } = useMyRecordedGreetings();
  const { greetingRecordings, addGreetingBlobs, deleteGreetingBlob } = useMyRecordingBlobs();
  const [isFetchingBlobs, setIsFetchingBlobs] = useState(false);

  const loadedGreetings = listGreetingsQueryResponse.data?.listGreetings;
  const isUploadingGreeting = uploadingGreetingResponse.loading;

  useEffect(() => {
    const fetchingGreetingsRecordings = async () => {
      setIsFetchingBlobs(true);
      const { myGreetingRecordings, errorMsgs } = await fetchGreetingBlobs(loadedGreetings || []);
      setIsFetchingBlobs(false);
      return { myGreetingRecordings, errorMsgs };
    };

    (async () => {
      const { myGreetingRecordings, errorMsgs } = await fetchingGreetingsRecordings();
      errorMsgs?.forEach((msg) => console.error(msg));
      addGreetingBlobs(myGreetingRecordings, { clearExistingEntries: true });
    })();
  }, [addGreetingBlobs, loadedGreetings]);

  useEffect(() => {
    if (orgUuid) {
      getGreetings({ variables: { orgUUID: orgUuid } });
      listGreetingTemplates({ variables: { orgUUID: orgUuid } });
    }
  }, [getGreetings, listGreetingTemplates, orgUuid]);

  const handleBlobAvailable = useCallback(
    (name: string, blob: Blob | null) => {
      if (blob) {
        setUpdatedGreetings(new Map(updatedGreetings.set(name, true).entries()));
        addGreetingBlobs([{ name, blob }]);
      } else {
        updatedGreetings.delete(name);
        deleteGreetingBlob(name);
        setUpdatedGreetings(new Map(updatedGreetings.entries()));
      }
    },
    [addGreetingBlobs, deleteGreetingBlob, updatedGreetings]
  );

  const handleUploadRecordings = async (): Promise<HandleFormPostedResponse> => {
    const response: HandleFormPostedResponse = {
      successfulUploads: [],
      failedUploads: [],
    };
    if (!orgUuid) {
      response.errorMsg = 'Select an organization to upload recordings';
      return response;
    }
    const userEmail = user?.email;
    if (!userEmail) {
      response.errorMsg = 'Unable to post recordings - missing user email';
      return response;
    }

    const failedToUploadGreetings = new Map(updatedGreetings.entries());
    const uploadGreetingPromises = Array.from(updatedGreetings).map(async ([greetingName, _]) => {
      const blob = greetingRecordings.get(greetingName);
      if (!blob) {
        console.error(`Unable to find recording for greeting with name '${greetingName}'`);
        return;
      }

      try {
        const { errorMsg } = await uploadGreeting(orgUuid, greetingName, blob);
        if (errorMsg) {
          // This will be caught and handled below in the catch clause
          throw new Error(errorMsg);
        }
        failedToUploadGreetings.delete(greetingName);
        response.successfulUploads.push(greetingName);
      } catch (e: any) {
        const msg = `Failed to upload recording(s) for '${greetingName}'`;
        console.error(msg);
        response.failedUploads.push(greetingName);
      }
    });

    // Wait for all greetings to be uploaded
    await Promise.all(uploadGreetingPromises);

    setUpdatedGreetings(failedToUploadGreetings);

    if (orgUuid) {
      getGreetings({ variables: { orgUUID: orgUuid } });
      listGreetingTemplates({ variables: { orgUUID: orgUuid } });
    }
    return response;
  };

  return {
    recordedGreetings,
    greetingRecordings,
    updatedGreetings,
    isUploadingGreeting,
    handleBlobAvailable,
    handleUploadRecordings,
    isFetchingRecordingLinks: listGreetingsQueryResponse.loading,
    isFetchingBlobs,
  };
}
