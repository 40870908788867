import { gql, useQuery } from '@apollo/client';
import { useTheme } from '@mui/material';
import MUIAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import LinearProgress from '@mui/material/LinearProgress';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';

import { useAuth } from '@infinitus/auth';
import { IconButton } from '@infinitus/components/Button';
import { IconNames } from '@infinitus/components/Icon';
import useCallParams from '@infinitus/hooks/useCallParams';
import { Link } from 'components/Link';
import Logo from 'components/Logo';
import { GetAppData } from 'types/gqlMapping';

import AccountButton from './subcomponents/AccountButton';
import Notifications from './subcomponents/Notifications';
import { OfflineBackdrop } from './subcomponents/OfflineBackdrop';
import OrgLivenessFlags from './subcomponents/OrgLivenessFlags';
import { OrgSwitcher } from './subcomponents/OrgSwitcher';
import ReportIncident from './subcomponents/ReportIncident';

const APP_DATA = gql`
  query GetAppData {
    loading @client
  }
`;

interface AppHeaderProps {
  onMenuButtonClick: () => void;
}

export function AppHeader({ onMenuButtonClick }: AppHeaderProps) {
  const theme = useTheme();
  const { hasUserAccess, isAuthenticated, isActive, user } = useAuth();
  const isAuthorized = isActive() && hasUserAccess();
  const { data } = useQuery<GetAppData>(APP_DATA);
  const { orgName } = useCallParams();

  if (!isAuthenticated || !isAuthorized) return null;

  return (
    <MUIAppBar position="fixed">
      <Toolbar
        disableGutters={true}
        sx={{
          height: theme.spacing(6),
          bgcolor: theme.palette.primary.main,
        }}
        variant="dense"
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{ paddingInlineStart: 1, width: '100%' }}
        >
          <Stack
            alignItems="center"
            direction="row"
            paddingRight={{
              xs: 1,
              xl: 0,
            }}
            spacing={1}
          >
            <IconButton
              hideTooltip={true}
              iconName={IconNames.MENU}
              onClick={onMenuButtonClick}
              title="Menu"
            />
            <Link
              sx={{
                display: 'block',
                marginRight: 1,
              }}
              to="/queue"
            >
              <Logo
                style={{
                  display: 'block',
                  height: theme.spacing(3),
                  width: theme.spacing(3),
                }}
              />
            </Link>
          </Stack>
          <Container
            maxWidth={false}
            sx={{
              maxWidth: 2000,
              paddingInlineStart: 1,
              // TODO: Remove hack when layouts are standard
              '@media (min-width: 2200px)': {
                paddingInlineStart: 0,
              },
            }}
          >
            <Stack
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              paddingLeft={0}
              spacing={1}
              sx={{
                // TODO: Remove hack when layouts are standard
                '@media (min-width: 2200px)': {
                  marginInlineStart: -2,
                },
              }}
            >
              <OrgSwitcher />
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexFlow: 'row wrap',
                  justifyContent: 'center',
                }}
              >
                <OrgLivenessFlags orgName={orgName} />
              </Box>
              <Stack alignItems="center" direction="row" sx={{ justifySelf: 'flex-end' }}>
                <OfflineBackdrop />
                <Notifications />
                <ReportIncident />
                <AccountButton fullName={user?.displayName || ''} imageUrl={user?.photoURL || ''} />
              </Stack>
            </Stack>
          </Container>
        </Stack>
      </Toolbar>
      {data?.loading && <LinearProgress color="secondary" />}
    </MUIAppBar>
  );
}
